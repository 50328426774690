/* ------ Module imports ------ */
import React, { useContext, useState } from 'react';

/* ------ Context ------ */
import ConversationContext from 'common/conversation/context';

/* ------ Common imports ------ */
import Icon from 'common/icon';

/* ------ Local components ------ */
import Dropdown from './dropdown';

function Details() {
  const { conversation } = useContext(ConversationContext);

  const [expanded, setExpanded] = useState(false);

  let finalCategory = null;
  if (conversation.categories.length > 0) {
    finalCategory = conversation.categories[conversation.categories.length - 1];
  }

  return (
    <>
      <div className="shadow-md border border-gray-300 bg-white rounded-lg">
        {conversation.type === 'web' && (
          <div className="px-3 py-1 flex items-center border-b border-gray-300">
            <img alt="" className="w-4 mr-2" src="/assets/icons/email.svg" />
            <p className={`${conversation.email ? 'text-gray-700' : 'text-gray-600'} text-xs font-medium`}>{conversation.email || 'No email given'}</p>
          </div>
        )}

        <div className="flex justify-between">
          <div className="px-3 py-1 flex items-center">
            <img alt="" className="w-4 mr-2" src="/assets/icons/tags.svg" />
            <div className="hidden sm:flex items-center">
              {conversation.categories.map((category, i) => (
                <React.Fragment key={category.id}>
                  {i > 0 && <Icon className="mx-2 text-xs text-gray-600" icon="right-chevron" />}
                  <p className="text-gray-700 text-xs font-medium">{category.name}</p>
                </React.Fragment>
              ))}
              {conversation.categories.length === 0 && (
                <p className="text-gray-700 text-xs font-medium">No category</p>
              )}
            </div>
            <div className="flex items-center sm:hidden">
              {conversation.categories.length > 1 && (
                <>
                  <p className="text-gray-700 text-xs font-medium">&hellip;</p>
                  <Icon className="mx-2 text-xs text-gray-600" icon="right-chevron" />
                </>
              )}
              <p className="text-gray-700 text-xs font-medium">
                {finalCategory ? finalCategory.name : 'No category'}
              </p>
            </div>
          </div>

          <div className="border-l border-gray-300">
            <button
              className="focus:outline-none h-full flex items-center px-2 hover:bg-gray-100 rounded-r-lg"
              onClick={() => setExpanded(!expanded)}
              type="button"
            >
              <Icon className="text-xs text-gray-600" icon="down-chevron" />
            </button>
          </div>
        </div>
      </div>

      {expanded && (
        <div className="relative">
          <Dropdown
            onClose={() => setExpanded(false)}
          />
        </div>
      )}
    </>
  );
}

export default Details;
