/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';

function Success() {
  function onGoToBusiness() {
    window.location = '/settings';
  }

  return (
    <div className="text-center">
      <img
        alt="Successfully signed up"
        className="w-24 inline-block mb-4"
        src="/assets/img/success.svg"
      />

      <h1 className="font-heading text-2xl font-medium text-gray-800 mb-2">You&apos;re all set!</h1>
      <p className="mb-10 text-gray-800">Thanks for signing up! We&apos;re excited to see how you use Ralli.</p>

      <Button onClick={onGoToBusiness}>Click here to go back to your business</Button>
    </div>
  );
}

export default Success;
