/* ------ Module imports ------ */
import React, { useState } from 'react';

function Attachment(props) {
  const { attachment } = props;

  const [videoLoaded, setVideoLoaded] = useState(false);

  function onClick() {
    if (attachment.type === 'video' && !videoLoaded) {
      setVideoLoaded(true);
    } else {
      window.open(attachment.url);
    }
  }

  if (videoLoaded) {
    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video className="max-w-32 max-h-32 mt-1 rounded" controls>
        <source src={attachment.url} />
      </video>
    );
  }

  return (
    <button
      className="focus:outline-none border-0 max-w-full"
      type="button"
      onClick={onClick}
    >
      {attachment.type === 'image' && (
        <img
          alt=""
          className="h-32 mt-1 rounded"
          src={attachment.url}
        />
      )}

      {attachment.type === 'video' && (
        <>
          <img
            alt=""
            className="max-w-18 max-h-18 mt-1 rounded"
            src="/assets/img/video-placeholder.png"
          />
        </>
      )}

      {(attachment.type !== 'image' && attachment.type !== 'video') && (
        <div className="flex items-center mt-1 p-2 border border-gray-300 rounded">
          <img
            alt=""
            className="h-6 mr-2"
            src="/assets/icons/file.svg"
          />
          <p className="text-sm truncate text-gray-800">{attachment.filename}</p>
        </div>
      )}
    </button>
  );
}

export default Attachment;
