/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import PlainButton from 'common/plain-button';

function Expired(props) {
  const {
    onTryAgain,
    onUpdatePaymentDetails,
    tryingAgain,
  } = props;

  return (
    <div className="border-t border-gray-300 p-6">
      <p className="mb-6 text-sm text-orange-500 font-semibold">
        Your subscription has expired. To continue using Ralli, either resubscribe with
        your existing card, or update your payment details to set up your subscription with
        a new card.
      </p>

      <div className="flex items-center">
        <Button
          loading={tryingAgain}
          onClick={onTryAgain}
        >
          Resubscribe with existing card
        </Button>

        <PlainButton
          className="ml-4"
          disabled={tryingAgain}
          onClick={onUpdatePaymentDetails}
        >
          Update payment details
        </PlainButton>
      </div>
    </div>
  );
}

export default Expired;
