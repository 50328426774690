/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Icon from 'common/icon';

function Category(props) {
  const {
    category,
    onSelect,
  } = props;

  return (
    <button
      className="w-full text-left text-sm text-gray-800 hover:bg-gray-200 px-4 py-1 focus:outline-none"
      key={category.id}
      onClick={onSelect}
      type="button"
    >
      {category.name}

      {(category.children && category.children.length > 0) && (
        <Icon
          className="text-gray-600 text-xs ml-2"
          icon="right-chevron"
        />
      )}
    </button>
  );
}

export default Category;
