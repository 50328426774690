/* ------ Module imports ------ */
import React from 'react';
import { useHistory } from 'react-router-dom';

/* ------ Common imports ------ */
import Button from 'common/button';

function BillingError(props) {
  const { error } = props;

  const history = useHistory();

  function onSetupSubscription() {
    history.push('/subscribe');
  }

  let title = 'Your subscription has expired';
  if (error === 'trial_expired') {
    title = 'Your free trial has expired';
  }

  return (
    <div className="p-10 mt-10 bg-orange-300 shadow-lg rounded-lg">
      <p className="font-medium text-orange-900 mb-2 text-xl">{title}</p>
      <p className="text-orange-700 font-semibold text-sm mb-10">To continue using all the great features of Ralli, please set up a subscription.</p>

      <Button onClick={onSetupSubscription}>Set up subscription</Button>
    </div>
  );
}

export default BillingError;
