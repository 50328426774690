/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Loading from 'common/loading';

function ConnectAccount(props) {
  const {
    business,
    loading,
  } = props;

  if (loading) {
    return <Loading />;
  }

  const name = business
    ? business.connect_account.name
    : 'another business';

  return (
    <p className="px-6 py-4 text-sm text-gray-800">{`You account is managed by ${name}. You will be billed by them.`}</p>
  );
}

export default ConnectAccount;
