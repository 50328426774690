/* ------ Module imports ------ */
import React from 'react';
import { useHistory } from 'react-router-dom';

/* ------ Helpers ------ */
import usePathname from 'helpers/hooks/use-pathname';

/* ------ Common imports ------ */
import PlainButton from 'common/plain-button';

function PastDue() {
  const history = useHistory();
  const pathname = usePathname();

  return (
    <div className="bg-orange-300 px-6 py-4">
      <div className="flex items-center justify-between">
        <div>
          <p className="font-semibold text-sm text-orange-900">Your subscription payment has failed</p>
          <p className="font-medium text-orange-600 text-xs">Update your payment details to ensure your Ralli usage isn&apos;t interrupted.</p>
        </div>

        {(pathname !== '/settings') && (
          <div>
            <PlainButton
              color="orange"
              onClick={() => history.push('/settings')}
            >
              Update payment details
            </PlainButton>
          </div>
        )}
      </div>
    </div>
  );
}

export default PastDue;
