/* ------ Module imports ------*/
import React from 'react';
import ReactMinimalPieChart from 'react-minimal-pie-chart';

/* ------ Common imports ------ */
import Dots from 'common/dots';
import Icon from 'common/icon';

/* ------ Local components ------ */
import Onboarding from './onboarding';
import RecentConversations from './recent-conversations';

function Dashboard(props) {
  const {
    categories,
    metrics,
    onboardingSteps,
    onSkipOnboarding,
    recentConversations,
    view,
  } = props;

  function renderPieChart() {
    return (
      <div className="mt-10 bg-white shadow-lg rounded-lg">
        <p className="border-b px-6 py-4 font-medium text-sm text-gray-800">Conversations by category</p>

        <div className="p-6 flex items-center">
          <ReactMinimalPieChart
            cx={50}
            cy={50}
            data={categories}
            lengthAngle={360}
            lineWidth={30}
            radius={50}
            startAngle={270}
            style={{ height: '8rem', width: '8rem' }}
            viewBoxSize={[100, 100]}
          />

          <div className="ml-10">
            {categories.map(category => (
              <div className="flex items-center py-1" key={category.key}>
                <div className="h-4 w-4 mr-2 rounded shadow" style={{ backgroundColor: category.color }} />
                <p className="text-sm text-gray-800">{category.title}</p>
                <div className="h-3 bg-gray-500 mx-2 my-auto w-px" />
                <p className="text-sm text-gray-800">{category.value}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  function renderMainView() {
    return (
      <>
        {onboardingSteps && (
          <Onboarding
            onSkip={onSkipOnboarding}
            steps={onboardingSteps}
          />
        )}
        <div className="sm:flex justify-between mt-10 relative">
          <div className="p-6 sm:w-1/2 sm:mr-2 bg-white shadow-lg rounded-lg relative z-10">
            <p className="font-medium text-sm text-gray-800">Active conversations</p>
            <p className="text-5xl text-green-500 font-bold leading-none mt-2">{metrics.active_count}</p>
          </div>
          {!onboardingSteps && <Dots />}
          <div className="p-6 mt-4 sm:mt-0 sm:w-1/2 sm:ml-2 bg-white shadow-lg rounded-lg relative z-10">
            <p className="font-medium text-sm text-gray-800">Total conversations</p>
            <p className="text-5xl text-green-500 font-bold leading-none mt-2">{metrics.total_count}</p>
          </div>
        </div>

        {categories.length > 0 && renderPieChart()}

        <p className="mt-10 text-sm uppercase font-semibold text-gray-800">Conversations with recent activity</p>
        {recentConversations.length > 0 && (
          <RecentConversations conversations={recentConversations} />
        )}
        {recentConversations.length === 0 && (
          <div className="p-4 mt-4 bg-white shadow-lg rounded-lg text-center text-gray-600 text-sm">
            You don&apos;t have any active conversations
          </div>
        )}
      </>
    );
  }

  function renderErrorView() {
    return (
      <div className="p-10 bg-white shadow-lg rounded-lg">
        <div className="bg-orange-300 text-orange-500 flex justify-center items-center rounded-full w-10 h-10">
          <Icon icon="warning" />
        </div>
        <p className="mt-6 font-medium text-gray-800 mb-2 text-xl">Could not load your data</p>
        <p className="text-gray-800 text-sm">Something went wrong loading your data. Please try again or contact us if you keep having this problem.</p>
      </div>
    );
  }

  if (view === 'error') {
    return renderErrorView();
  }

  return renderMainView();
}

export default Dashboard;
