/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import Edit from './edit.view';

function EditContainer(props) {
  const {
    chatPlugin,
    onCancel,
    onChatPluginUpdated,
  } = props;

  const [name, setName] = useState(chatPlugin.name);
  const [greeting, setGreeting] = useState(chatPlugin.greeting);
  const [description, setDescription] = useState(chatPlugin.description);
  const [page, setPage] = useState(chatPlugin.page ? chatPlugin.page.id : null);

  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  function validateData() {
    const validationErrors = {};

    if (!name || !name.trim()) {
      validationErrors.name = true;
    }

    if (!greeting || !greeting.trim() || greeting.length > 32) {
      validationErrors.greeting = true;
    }

    if (!description || !description.trim() || description.length > 128) {
      validationErrors.description = true;
    }

    return validationErrors;
  }

  async function onSubmit(ev) {
    ev.preventDefault();

    const validationErrors = validateData();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    setSubmitting(true);

    const postData = {
      name,
      greeting,
      description,
      page,
    };

    let updatedPlugin = null;
    try {
      const { data } = (await api.patch(`/chat_plugin/${chatPlugin.id}?expand[]=page&expand[]=domains&expand[]=category_set&expand[]=hours`, postData)).data;
      updatedPlugin = data;
    } catch (e) {
      // Silently ignore.
    }

    setSubmitting(false);

    if (updatedPlugin) {
      onChatPluginUpdated(updatedPlugin);
    } else {
      toast('Something went wrong. Please try again');
    }
  }

  return (
    <Edit
      description={description}
      errors={errors}
      greeting={greeting}
      name={name}
      onCancel={onCancel}
      onDescriptionChanged={setDescription}
      onGreetingChanged={setGreeting}
      onNameChanged={setName}
      onPageChanged={setPage}
      onSubmit={onSubmit}
      page={page}
      submitting={submitting}
    />
  );
}

export default EditContainer;
