/* ------ Module imports ------ */
import React, { useMemo, useState } from 'react';
import Select, { components } from 'react-select';

function SingleValue({ children, ...props }) {
  const { data } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.SingleValue {...props}>
      <span className="text-sm text-gray-800 font-medium">{data.label}</span>
    </components.SingleValue>
  );
}

function Option({ children, ...props }) {
  const { data } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.Option {...props}>
      <span className="text-sm text-gray-800 font-medium">{data.label}</span>
    </components.Option>
  );
}

function CategorySelector(props) {
  const {
    categories,
    disabled,
    loading,
    onChange,
    value,
  } = props;

  const [menuOpen, setMenuOpen] = useState(false);

  const options = useMemo(() => {
    if (!categories) {
      return [];
    }

    return categories.map(c => ({
      label: c.name,
      value: c.id,
    }));
  }, [categories]);

  return (
    <div className="flex-1">
      <Select
        autoFocus
        classNamePrefix="select"
        components={{ Option, SingleValue }}
        isDisabled={disabled}
        isLoading={loading}
        menuIsOpen={menuOpen}
        options={options}
        onChange={onChange}
        onMenuOpen={() => setMenuOpen(true)}
        onMenuClose={() => setMenuOpen(false)}
        placeholder="Choose a category"
        value={value}
      />
    </div>
  );
}

export default CategorySelector;
