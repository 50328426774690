/* ------ Module imports ------ */
import React, { useContext, useRef } from 'react';

/* ------ Context ------ */
import PlatformContext from 'components/platform/context';

/* ------ Helpers ------ */
import useOnClickOutside from 'helpers/hooks/use-on-click-outside';
import usePathname from 'helpers/hooks/use-pathname';

/* ------ Common imports ------ */
import Icon from 'common/icon';

/* ------ Local components ------ */
import Link from './link';

function Sidebar(props) {
  const { onClose } = props;

  const { isUnreadConversations } = useContext(PlatformContext);

  const ref = useRef();
  useOnClickOutside(ref, onClose);

  const currentPath = usePathname();

  return (
    <div
      className="py-10 bg-gray-800 w-80 max-w-xs h-screen max-h-screen overflow-y-auto flex flex-col"
      ref={ref}
    >
      <div className="flex items-center pl-10 mb-10">
        <button
          className="pr-2 focus:outline-none mr-6 block xl:hidden"
          onClick={onClose}
          type="button"
        >
          <Icon className="text-gray-100" icon="left-chevron" />
        </button>

        <img
          alt="Ralli logo"
          className="w-10"
          src="/assets/img/logo-round.svg"
        />
      </div>

      <Link
        icon="home"
        label="Home"
        href="/"
        onClick={onClose}
        selected={currentPath === ''}
      />
      <Link
        icon="conversations"
        label="Inbox"
        href="/inbox"
        notifications={isUnreadConversations}
        onClick={onClose}
        selected={currentPath.startsWith('/inbox')}
      />

      <p className="px-10 text-gray-500 text-xs uppercase font-medium mb-4 mt-10">Administration</p>
      <Link
        icon="flag"
        label="Messaging channels"
        href="/channels"
        onClick={onClose}
        selected={currentPath.startsWith('/channels')}
      />
      <Link
        icon="users"
        label="Users"
        href="/users"
        onClick={onClose}
        selected={currentPath === '/users'}
      />
      <Link
        icon="tags"
        label="Category sets"
        href="/category-sets"
        onClick={onClose}
        selected={currentPath.startsWith('/category-sets')}
      />
      <Link
        icon="sliders"
        label="Settings"
        href="/settings"
        onClick={onClose}
        selected={currentPath === '/settings'}
      />
    </div>
  );
}

export default Sidebar;
