/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import FacebookButton from 'common/facebook-button';

function Reauthenticate(props) {
  const {
    facebookCallback,
    facebookLoading,
    onButtonClicked,
    view,
  } = props;

  function renderMainView() {
    return (
      <div>
        <p className="text-sm text-gray-800 mt-4 mb-6">
          If you are not receiving messages from Facebook, or think there is something
          wrong with your integration, you can use the following button to reauthenticate with
          Facebook.
        </p>

        <Button
          onClick={onButtonClicked}
        >
          Reauthenticate
        </Button>
      </div>
    );
  }

  function renderReauthenticateView() {
    return (
      <div>
        <p className="text-sm text-gray-800 mt-4 mb-6">
          To reauthenticate with Facebook, click the button below and ensure you have granted
          all the requested permissions to this page.
        </p>

        <FacebookButton
          loading={facebookLoading}
          onComplete={facebookCallback}
        />
      </div>
    );
  }

  function renderSuccessView() {
    return (
      <div>
        <p className="text-sm text-gray-800 mt-4">
          <span className="block text-green-600 font-medium">Successfully reauthenticated with Facebook.</span>
          If you continue to have any trouble, please contact Ralli support.
        </p>
      </div>
    );
  }

  return (
    <div className="border-t border-gray-300 p-6">
      <p className="uppercase text-sm font-semibold text-gray-800">Reauthenticate with Facebook</p>

      {view === 'main' && renderMainView()}
      {view === 'reauthenticate' && renderReauthenticateView()}
      {view === 'success' && renderSuccessView()}
    </div>
  );
}

export default Reauthenticate;
