/* ------ Module imports ------ */
import React, { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

/* ------ Config ------ */
import config from 'config';

/* ------ Local components ------ */
import Form from './form';
import Success from './success';

const stripe = loadStripe(config.stripe.apiKey);

function Subscribe() {
  const [view, setView] = useState('form');

  if (view === 'success') {
    return <Success />;
  }

  return (
    <Elements stripe={stripe}>
      <Form
        onSuccess={() => setView('success')}
      />
    </Elements>
  );
}

export default Subscribe;
