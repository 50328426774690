/* ------ Module imports ------ */
import React from 'react';
import moment from 'moment';

/* ------ Common imports ------ */
import Card from 'common/card';
import Icon from 'common/icon';
import LoadingSpinner from 'common/loading-spinner';
import PlainButton from 'common/plain-button';

function PastPayments(props) {
  const {
    canLoadMore,
    invoices,
    loadingMore,
    onLoadMore,
    view,
  } = props;

  if (view === 'loading') {
    return null;
  }

  if (view === 'main' && invoices.length === 0) {
    return null;
  }

  return (
    <Card className="mt-10">
      <p className="px-6 py-4 font-medium text-gray-800">Past invoices</p>

      {view === 'loading' && (
        <div className="px-6 py-4 flex items-center justify-center border-t border-gray-300">
          <LoadingSpinner small />
        </div>
      )}
      {view === 'error' && (
        <div className="px-6 py-4 flex items-center justify-center border-t border-gray-300">
          <Icon className="text-orange-600" icon="warning" />
          <p className="ml-2 text-gray-600 text-sm font-medium text-center">Something went wrong loading invoices</p>
        </div>
      )}
      {view === 'main' && (
        <>
          {invoices.map(invoice => (
            <div
              className="px-6 py-4 border-t border-gray-300"
              key={invoice.stripe_id}
            >
              <p className="text-gray-800 text-sm">{moment.unix(invoice.timestamp).format('DD MMM YYYY')}</p>
              <PlainButton onClick={() => window.open(invoice.receipt)}>View invoice</PlainButton>
            </div>
          ))}
          {invoices.length === 0 && (
            <div className="px-6 py-4 text-center border-t border-gray-300 relative">
              <p className="text-gray-600 text-sm font-medium text-center">No previous invoices</p>
            </div>
          )}
          {invoices.length > 0 && (
            <div className="px-6 py-4 text-center border-t border-gray-300 relative">
              {canLoadMore && (
                <>
                  <PlainButton
                    className={loadingMore ? 'invisible' : ''}
                    disabled={loadingMore}
                    onClick={onLoadMore}
                  >
                    Load more
                  </PlainButton>

                  {loadingMore && (
                    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                      <LoadingSpinner small />
                    </div>
                  )}
                </>
              )}
              {!canLoadMore && (
                <p className="text-gray-600 text-sm font-medium text-center">No older invoices</p>
              )}
            </div>
          )}
        </>
      )}
    </Card>
  );
}

export default PastPayments;
