/* ------ Module imports ------ */
import React, { useRef } from 'react';

/* ------ Helpers ------ */
import useOnClickOutside from 'helpers/hooks/use-on-click-outside';

function Popover(props) {
  const {
    onClose,
    onSetDeleting,
    onSetRenaming,
  } = props;

  const ref = useRef();

  useOnClickOutside(ref, onClose);

  return (
    <div
      className="z-50 w-30 -mr-3 mt-10 absolute top-0 right-0 popover popover-right bg-white shadow-xl border border-gray-300 rounded-lg"
      ref={ref}
    >
      <div className="py-1">
        <button
          className="text-left w-full px-4 py-1 block hover:bg-gray-200 text-gray-800 text-sm font-semibold"
          onClick={onSetRenaming}
          type="button"
        >
          Rename
        </button>
        <button
          className="text-left w-full px-4 py-1 block hover:bg-gray-200 text-red-500 text-sm font-semibold"
          onClick={onSetDeleting}
          type="button"
        >
          Delete
        </button>
      </div>
    </div>
  );
}

export default Popover;
