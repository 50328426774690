/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import Card from 'common/card';
import Icon from 'common/icon';

function Onboarding(props) {
  const {
    fb,
    loading,
    onContinue,
    onToggleFb,
    onToggleWeb,
    web,
  } = props;

  return (
    <div>
      <img
        alt="Ralli Logo"
        className="absolute top-0 left-0 w-16 mt-10 ml-10"
        src="/assets/img/logo.svg"
      />

      <div className="pt-24 pb-20 block mx-auto text-center">
        <h1 className="font-heading text-2xl text-gray-800 mb-10">What are you planning on using Ralli for?</h1>

        <div className="inline-block">
          <Card className="flex p-6">
            <div className="pr-3">
              <button
                className={`focus:outline-none hover:bg-gray-100 flex items-top text-left border rounded-lg relative w-80 h-80 ${web ? 'border-green-500' : 'border-gray-300'}`}
                onClick={onToggleWeb}
                type="button"
              >
                <div className="flex justify-between w-full p-6">
                  <div>
                    <p className="text-gray-800 font-medium text-sm">Adding chat to my</p>
                    <h3 className="text-gray-800 font-heading text-xl">Website</h3>
                  </div>

                  <div className="w-8 h-8 rounded-lg bg-white border border-gray-300 text-green-500 flex items-center justify-center">
                    {web && <Icon icon="check" />}
                  </div>
                </div>

                <img
                  alt=""
                  className="w-full absolute bottom-0 left-0 right-0 px-6"
                  src="/assets/img/onboarding-web.svg"
                />
              </button>
            </div>
            <div className="pl-3">
              <button
                className={`focus:outline-none hover:bg-gray-100 flex items-top text-left border rounded-lg relative w-80 h-80 ${fb ? 'border-green-500' : 'border-gray-300'}`}
                onClick={onToggleFb}
                type="button"
              >
                <div className="flex justify-between w-full p-6">
                  <div>
                    <p className="text-gray-800 font-medium text-sm">Handling messages from my</p>
                    <h3 className="text-gray-800 font-heading text-xl">Facebook Page</h3>
                  </div>

                  <div className="w-8 h-8 rounded-lg bg-white border border-gray-300 text-green-500 flex items-center justify-center">
                    {fb && <Icon icon="check" />}
                  </div>
                </div>

                <img
                  alt=""
                  className="w-full absolute bottom-0 left-0 right-0 px-6"
                  src="/assets/img/onboarding-fb.svg"
                />
              </button>
            </div>
          </Card>
        </div>

        <div className="mt-10">
          <Button
            disabled={!fb && !web}
            loading={loading}
            onClick={onContinue}
          >
            <span className="px-10">Continue</span>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Onboarding;
