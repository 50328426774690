/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Icon from 'common/icon';

/* ------ Local components ------ */
import Selector from './selector';

function AddUser(props) {
  const {
    adding,
    category,
    onAdd,
    onCancelAdding,
    onManagerAdded,
  } = props;

  function renderSelector() {
    return (
      <Selector
        category={category}
        onCancel={onCancelAdding}
        onManagerAdded={onManagerAdded}
      />
    );
  }

  function renderButton() {
    return (
      <button
        className="mt-4 flex items-center"
        onClick={onAdd}
        type="button"
      >
        <div className="w-8 h-8 rounded-lg bg-gray-300 flex items-center justify-center font-bold text-blue-500">
          <Icon icon="plus" />
        </div>
        <p className="text-sm text-blue-500 font-medium ml-2">Add User</p>
      </button>
    );
  }

  return adding
    ? renderSelector()
    : renderButton();
}

export default AddUser;
