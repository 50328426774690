/* ------ Module imports ------ */
import React from 'react';
import { useHistory } from 'react-router-dom';

/* ------ Common imports ------ */
import Card from 'common/card';
import DeleteCategorySet from 'common/delete-category-set';
import Icon from 'common/icon';
import PlainButton from 'common/plain-button';
import RenameCategorySet from 'common/rename-category-set';
import Popover from './popover';

function CategorySet(props) {
  const {
    categorySet,
    deleting,
    onCancelDelete,
    onCancelRename,
    onClosePopover,
    onDeleted,
    onOpenPopover,
    onRenamed,
    onSetDeleting,
    onSetRenaming,
    popoverVisible,
    renaming,
  } = props;

  const history = useHistory();

  function renderUsedBy(inUseBy) {
    return (
      <div className="mt-4">
        <p className="text-gray-600 text-xs uppercase font-medium mb-2">Used by</p>
        {inUseBy.length > 0 && (
          <div className="flex">
            {inUseBy.map(channel => (
              <div className="flex items-center mr-4" key={channel.id}>
                <img
                  alt=""
                  className="w-3 mr-1"
                  src={`/assets/img/${channel.object === 'chat_plugin' ? 'web' : 'facebook'}.svg`}
                />
                <p className="text-gray-600 text-xs font-medium">{channel.name}</p>
              </div>
            ))}
          </div>
        )}

        {inUseBy.length === 0 && (
          <div className="flex items-center">
            <Icon className="text-orange-500" icon="warning" />
            <p className="text-gray-800 text-sm ml-2">Not used by any messaging channels</p>
          </div>
        )}
      </div>
    );
  }

  return (
    <Card className="mb-10">
      <div className="p-6">
        {renaming && (
          <RenameCategorySet
            categorySet={categorySet}
            onCancel={onCancelRename}
            onNameChanged={onRenamed}
          />
        )}
        {!renaming && (
          <div className="flex justify-between items-center">
            <p className="text-gray-800 text-lg">{categorySet.name}</p>
            <div className="flex justify-between relative">
              <PlainButton onClick={() => history.push(`/category-sets/${categorySet.id}`)}>View and Edit</PlainButton>
              <button
                className="flex items-center ml-4 border border-gray-300 px-3 py-2 hover:shadow rounded-lg focus:outline-none"
                onClick={onOpenPopover}
                type="button"
              >
                <Icon
                  className="text-gray-800 text-sm"
                  icon="more"
                />
              </button>

              {popoverVisible && (
                <Popover
                  onClose={onClosePopover}
                  onSetDeleting={onSetDeleting}
                  onSetRenaming={onSetRenaming}
                />
              )}
            </div>
          </div>
        )}
        {categorySet.in_use_by && renderUsedBy(categorySet.in_use_by)}
      </div>

      {deleting && (
        <div className="border-t border-gray-300 p-6">
          <DeleteCategorySet
            categorySet={categorySet}
            onCancelDelete={onCancelDelete}
            onDeleted={onDeleted}
          />
        </div>
      )}
    </Card>
  );
}

export default CategorySet;
