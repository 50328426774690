/* ------ Module imports ------ */
import React, { useContext, useState } from 'react';

/* ------ Context ------ */
import ConversationContext from 'common/conversation/context';

/* ------ Helpers ------ */
import toast from 'helpers/toast';
import useFileInput from 'helpers/hooks/use-file-input';

/* ------ View import ------ */
import Composer from './composer.view';

function ComposerContainer() {
  const {
    conversation,
    onSendMessage,
    onUploadFile,
  } = useContext(ConversationContext);

  const [text, setText] = useState('');
  const [attachment, setAttachment] = useState(null);

  const [uploading, setUploading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  async function onAddMessage() {
    if (!text && !attachment) {
      return;
    }

    setSubmitting(true);

    try {
      await onSendMessage(text, attachment);

      setText('');
      setAttachment(null);
    } catch (e) {
      let errorText = 'Something went wrong. Please try again.';

      if (e && e.response && e.response.data) {
        const { code } = e.response.data;
        if (code === 'blocked') {
          errorText = `Cannot send message - ${conversation.name} has blocked your page`;
        }
        if (code === 'window_expired') {
          errorText = `Cannot send message - it has been 24 hours since the last message from ${conversation.name}`;
        }
      }

      toast(errorText);
    }

    setSubmitting(false);
  }

  async function onAttachmentUploaded(files) {
    const file = files[0];
    if (!file) {
      return;
    }

    setUploading(true);

    let uploadedAttachment = null;
    try {
      uploadedAttachment = await onUploadFile(file);
    } catch (e) {
      // Silently ignore - `uploadedAttachment` will be null so will show an error below
    }

    if (uploadedAttachment) {
      setAttachment(uploadedAttachment);
    } else {
      toast('Something went wrong uploading your attachment');
    }

    setUploading(false);
  }

  const onUploadAttachment = useFileInput(onAttachmentUploaded);

  return (
    <Composer
      attachment={attachment}
      onAddMessage={onAddMessage}
      onChangeValue={setText}
      onRemoveAttachment={() => setAttachment(null)}
      onUploadAttachment={onUploadAttachment}
      submitting={submitting}
      text={text}
      uploading={uploading}
    />
  );
}

export default ComposerContainer;
