/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ View imports ------ */
import CategorySet from './category-set.view';

function CategorySetContainer(props) {
  const {
    categorySet,
    onDeleted,
    onUpdated,
  } = props;

  const [deleting, setDeleting] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [renaming, setRenaming] = useState(false);

  function onSetDeleting() {
    setPopoverVisible(false);
    setDeleting(true);
    setRenaming(false);
  }

  function onSetRenaming() {
    setPopoverVisible(false);
    setDeleting(false);
    setRenaming(true);
  }

  function onDelete() {
    setDeleting(false);
    onDeleted();
  }

  function onRenamed(name) {
    const updatedCategorySet = categorySet;
    updatedCategorySet.name = name;
    onUpdated(updatedCategorySet);
    setRenaming(false);
  }

  return (
    <CategorySet
      categorySet={categorySet}
      deleting={deleting}
      onCancelDelete={() => setDeleting(false)}
      onCancelRename={() => setRenaming(false)}
      onDeleted={onDelete}
      onClosePopover={() => setPopoverVisible(false)}
      onOpenPopover={() => setPopoverVisible(true)}
      onRenamed={onRenamed}
      onSetDeleting={onSetDeleting}
      onSetRenaming={onSetRenaming}
      popoverVisible={popoverVisible}
      renaming={renaming}
    />
  );
}

export default CategorySetContainer;
