/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ Common import ------ */
import CategorySetSelector from 'common/category-set-selector';

function AddCategorySetContainer(props) {
  const {
    pageId,
    onCancel,
    onCategorySetUpdated,
  } = props;

  const [selectedCategorySet, setSelectedCategorySet] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  async function onConfirm() {
    if (!selectedCategorySet) {
      return;
    }

    setSubmitting(true);

    let updatedPage = null;
    try {
      const { data } = (await api.patch(`/page/${pageId}?expand[]=category_set`, { category_set: selectedCategorySet.value })).data;
      updatedPage = data;
    } catch (e) {
      // Silently ignore
    }

    setSubmitting(false);

    if (updatedPage) {
      onCategorySetUpdated(updatedPage.category_set);
      onCancel();
    } else {
      toast('Something went wrong. Please try again.');
    }
  }

  return (
    <CategorySetSelector
      onCancel={onCancel}
      onChange={setSelectedCategorySet}
      onConfirm={onConfirm}
      submitting={submitting}
      value={selectedCategorySet}
    />
  );
}

export default AddCategorySetContainer;
