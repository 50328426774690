/* ------ Module imports ------ */
import React, { useState } from 'react';
import axios from 'axios';

/* ------ Helpers ------ */
import toast from 'helpers/toast';

/* ------ Config ------ */
import config from 'config';

/* ------ View import ------ */
import FacebookLogin from './facebook-login.view';

function FacebookLoginContainer(props) {
  const { onPagesLoaded } = props;

  const [loading, setLoading] = useState(false);

  async function facebookCallback(res) {
    setLoading(true);

    let loadedPages = null;
    try {
      const { data } = (await axios.get(`${config.facebook.apiUrl}/me/accounts?access_token=${res.accessToken}&fields=name,picture,tasks`)).data;
      loadedPages = data;
    } catch (e) {
      toast('Something went wrong. Please try again');
    }

    setLoading(false);

    if (loadedPages) {
      onPagesLoaded(res.accessToken, loadedPages);
    }
  }

  return (
    <FacebookLogin
      facebookAppId={config.facebook.appId}
      facebookCallback={facebookCallback}
      facebookPermissions={config.facebook.permissions}
      loading={loading}
    />
  );
}

export default FacebookLoginContainer;
