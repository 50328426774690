/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Common imports ------ */
import LoadingSpinner from 'common/loading-spinner';

/* ------ Helpers ------ */
import toast from 'helpers/toast';

function LoadMore(props) {
  const {
    onLoadMoreConversations,
  } = props;

  const [loading, setLoading] = useState(false);
  const [noNew, setNoNew] = useState(false);

  async function onLoad() {
    setLoading(true);

    try {
      const newConvs = await onLoadMoreConversations();
      if (newConvs && newConvs.length === 0) {
        setNoNew(true);
      }
    } catch (e) {
      toast('Could not load conversations - something went wrong');
    }

    setLoading(false);
  }

  if (noNew) {
    return (
      <p className="text-gray-600 text-xs font-medium text-center py-2">No older conversations</p>
    );
  }

  return (
    <button
      className="focus:outline-none border border-gray-300 inline-block w-full relative px-4 py-4 bg-white hover:bg-gray-200 disabled:pointer-events-none rounded-lg text-center text-xs"
      disabled={loading}
      onClick={onLoad}
      type="button"
    >
      {loading && (
        <span className="absolute top-0 left-0 flex items-center justify-center w-full h-full">
          <LoadingSpinner small />
        </span>
      )}

      <span className={`${loading ? 'invisible' : ''} text-blue-brand font-semibold`}>Load more conversations</span>
    </button>
  );
}

export default LoadMore;
