/* ------ Module imports ------ */
import React from 'react';
import { Link } from 'react-router-dom';

/* ------ Common imports ------ */
import Button from 'common/button';
import Field from 'common/input/field';

function Email(props) {
  const {
    email,
    error,
    onEmailChanged,
    onSubmit,
  } = props;

  return (
    <form onSubmit={onSubmit}>
      <Field
        className="py-5 px-6 text-lg text-gray-800"
        error={error}
        label="Email address"
        onChange={onEmailChanged}
        placeholder="name@example.com"
        value={email}
      />

      <Button className="mt-4 py-3 w-full" type="submit">Continue</Button>

      <div className="mt-12 text-center">
        <Link
          className="text-xs text-gray-800 font-semibold hover:text-green-600"
          to="/signup"
        >
          Not signed up to Ralli? Click here to sign up your business
        </Link>
      </div>
    </form>
  );
}

export default Email;
