/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ View import ------ */
import AddUser from './add-user.view';

function AddUserContainer(props) {
  const {
    noButton,
    onCancel,
    onManagerAdded,
  } = props;

  const [adding, setAdding] = useState(noButton || false);

  function onCancelAdding() {
    if (noButton) {
      onCancel();
    } else {
      setAdding(false);
    }
  }

  return (
    <AddUser
      adding={adding}
      onAdd={() => setAdding(true)}
      onCancelAdding={onCancelAdding}
      onManagerAdded={onManagerAdded}
    />
  );
}

export default AddUserContainer;
