/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Loading from 'common/loading';

/* ------ Local imports ------ */
import AddCategorySet from './add-category-set';
import CategorySet from './category-set';

function CategorySets(props) {
  const {
    categorySets,
    onCategorySetDeleted,
    onCategorySetUpdated,
    view,
  } = props;

  if (view === 'loading') {
    return <Loading />;
  }

  if (view === 'error') {
    // TODO
    return null;
  }

  return (
    <>
      <h1 className="mb-10 font-heading text-2xl font-medium text-gray-800">Category Sets</h1>
      {categorySets.map(categorySet => (
        <CategorySet
          categorySet={categorySet}
          key={categorySet.id}
          onDeleted={() => onCategorySetDeleted(categorySet)}
          onUpdated={onCategorySetUpdated}
        />
      ))}

      <AddCategorySet isEmpty={categorySets.length === 0} />
    </>
  );
}

export default CategorySets;
