/* ------ Module imports ------ */
import React, { useEffect, useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';

/* ------ Common imports ------ */
import Icon from 'common/icon';
import Loading from 'common/loading';

/* ------ Local components ------ */
import Ad from './ad';

function List(props) {
  const {
    page,
    status,
  } = props;

  const [ads, setAds] = useState(null);
  const [view, setView] = useState('loading');

  async function fetchAds() {
    setView('loading');

    let loadedAds = null;
    try {
      const { data } = (await api.get(`/ad?page=${page.id}&expand[]=category&status=${status}`)).data;
      loadedAds = data;
    } catch (e) {
      // Silently ignore - `loadedAds` will be null so will show an error
    }

    if (loadedAds) {
      setAds(loadedAds);
      setView('main');
    } else {
      setView('error');
    }
  }

  useEffect(() => {
    fetchAds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  function onAdArchived(ad) {
    const updatedAds = ads.filter(a => a.id !== ad.id);
    setAds(updatedAds);
  }

  function onAdUpdated(ad) {
    const updatedAds = ads.map(a => {
      if (a.id === ad.id) {
        return ad;
      }

      return a;
    });
    setAds(updatedAds);
  }

  function renderEmptyState() {
    return (
      <>
        <p className="text-gray-600 text-sm text-center p-6">
          {status === 'active' && <span>You haven&apos;t set up any ads yet</span>}
          {status === 'archived' && <span>No archived ads</span>}
        </p>

        {status === 'active' && (
          <div className="p-6 border-t border-gray-300">
            <p className="font-semibold text-sm mb-2 text-gray-800">Ads, explained.</p>
            <p className="text-sm text-gray-700">
              You can link your Facebook ads that use &quot;Message us&quot; buttons, to Ralli.
              Then, you can map those ads to a category so that any messages received from those ads
              automatically start conversations in the appropriate category.
            </p>
          </div>
        )}
      </>
    );
  }

  if (view === 'loading') {
    return <Loading />;
  }

  if (view === 'error') {
    return (
      <div className="px-6 py-4">
        <div className="bg-orange-300 text-orange-500 flex justify-center items-center rounded-full w-10 h-10">
          <Icon icon="warning" />
        </div>
        <p className="mt-6 font-medium text-gray-800 mb-2 text-xl">Could not load ads</p>
        <p className="text-gray-800 text-sm">Something went wrong loading the ads for this page. Please try again or contact us if you keep having this problem.</p>
      </div>
    );
  }

  if (ads.length === 0) {
    return renderEmptyState();
  }

  return ads.map(ad => (
    <Ad
      ad={ad}
      key={ad.id}
      onArchived={() => onAdArchived(ad)}
      onUpdated={onAdUpdated}
      page={page}
    />
  ));
}

export default List;
