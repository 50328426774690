/* ------ Module imports ------ */
import React from 'react';

function Dots(props) {
  const {
    color,
    right,
  } = props;

  let className = 'flex absolute';
  let dotClassName = 'h-2 w-2 mx-2 my-4 rounded-full';
  if (right) {
    className += ' right-0 top-0 -mr-10 -mt-12';
    if (color === 'green') {
      dotClassName += ' bg-green-500';
    } else {
      dotClassName += ' bg-gray-600';
    }
  } else {
    className += ' left-0 bottom-0 -ml-10 -mb-12';
    dotClassName += ' bg-green-500';
  }

  const dot = <div className={dotClassName} />;

  const column = (
    <div>
      {dot}
      {dot}
      {dot}
      {dot}
      {dot}
      {dot}
    </div>
  );

  return (
    <div className={className}>
      {column}
      {column}
      {column}
      {column}
      {column}
      {column}
      {column}
      {column}
      {column}
    </div>
  );
}

export default Dots;
