/* ------ Module imports ------ */
import React, { useRef } from 'react';
import { SketchPicker } from 'react-color';

/* ------ Helpers ------ */
import useOnClickOutside from 'helpers/hooks/use-on-click-outside';

function Dropdown(props) {
  const {
    color,
    onChange,
    onClose,
  } = props;

  const ref = useRef();

  useOnClickOutside(ref, onClose);

  return (
    <div
      className="absolute z-10 top-0 left-0 mt-10 popover popover-left p-2 border border-gray-300 bg-white shadow-xl rounded"
      ref={ref}
    >
      <SketchPicker
        color={color}
        onChange={col => onChange(col.hex)}
        styles={{ default: { picker: { boxShadow: 'none' } } }}
      />
    </div>
  );
}

export default Dropdown;
