/* ------ Module imports ------ */
import React from 'react';
import { useHistory } from 'react-router-dom';

/* ------ Helpers ------ */
import accounts from 'helpers/accounts';
import auth from 'helpers/auth';

/* ------ Common imports ------ */
import PlainButton from 'common/plain-button';
import SimpleLayout from 'common/layout/simple';

function BillingError(props) {
  const { error } = props;

  const history = useHistory();

  function onLogout() {
    accounts.removeCurrentAccount();
    auth.logout();
    history.push('/login');
  }

  let title = 'The subscription for this business has expired';
  if (error === 'trial_expired') {
    title = 'The free trial for this business has ended';
  }

  return (
    <SimpleLayout>
      <div className="text-center">
        <p className="text-orange-500 font-semibold text-xl mb-4">{title}</p>
        <p className="text-gray-800 mb-10">
          To continue using Ralli with this business, please contact
          your administrator and get them to set up a new subscription.
        </p>

        <PlainButton
          className="mt-6"
          color="gray"
          onClick={onLogout}
        >
          Need to switch accounts? Click here to logout
        </PlainButton>
      </div>
    </SimpleLayout>
  );
}

export default BillingError;
