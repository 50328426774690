/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers imports ------ */
import api from 'helpers/api';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ View imports ------ */
import ConversationManagers from './conversation-managers.view';

function ConversationManagersContainer() {
  const [adding, setAdding] = useState(false);
  const [managers, setManagers] = useState([]);
  const [view, setView] = useState('loading');

  async function fetchManagers() {
    let loadedManagers = null;
    try {
      const { data } = (await api.get('/user?filter=managers')).data;
      loadedManagers = data;
    } catch (e) {
      // Silently ignore.
    }

    if (loadedManagers) {
      setManagers(loadedManagers);
      setView('main');
    } else {
      setView('error');
    }
  }

  function onManagerAdded(user) {
    const updatedUsers = managers.concat(user);
    setManagers(updatedUsers);
  }

  function onManagerDeleted(userId) {
    const updatedUsers = managers.filter(u => u.id !== userId);
    setManagers(updatedUsers);
  }

  useDidMount(() => {
    fetchManagers();
  });

  return (
    <ConversationManagers
      adding={adding}
      managers={managers}
      onManagerAdded={onManagerAdded}
      onManagerDeleted={onManagerDeleted}
      onToggleAdding={() => setAdding(!adding)}
      view={view}
    />
  );
}

export default ConversationManagersContainer;
