/* ------ Module imports ------ */
import React from 'react';

/* ------ Helpers ------ */
import useRerender from 'helpers/hooks/use-rerender';

/* ------ Local components ------ */
import AdReferral from './ad-referral';
import CategoryChange from './category-change';
import Message from './message';
import StatusChange from './status-change';
import UrlReferral from './url-referral';
import User from './user';

function Timeline(props) {
  const {
    conversation,
    page,
    timelineItems,
  } = props;

  /* ------ Need to re-render every minute so that the message timestamps are accurate ------ */
  useRerender(60000);

  const timelineItemsToRender = [];
  let currentUserTimelineItem = null;

  for (let i = 0; i < timelineItems.length; i += 1) {
    const ti = timelineItems[i];

    if (ti.type.startsWith('user_')) {
      if (!currentUserTimelineItem) {
        currentUserTimelineItem = {
          id: ti.id,
          type: ti.type,
          users: [ti.updated_user],
          triggeredBy: ti.user,
        };
      } else if (
        ti.type === currentUserTimelineItem.type
          && ti.user.id === currentUserTimelineItem.triggeredBy.id
      ) {
        currentUserTimelineItem.users.push(ti.updated_user);
      } else {
        timelineItemsToRender.push(currentUserTimelineItem);
        currentUserTimelineItem = {
          id: ti.id,
          type: ti.type,
          users: [ti.updated_user],
          triggeredBy: ti.user,
        };
      }
    } else {
      if (currentUserTimelineItem) {
        timelineItemsToRender.push(currentUserTimelineItem);
        currentUserTimelineItem = null;
      }

      timelineItemsToRender.push(ti);
    }
  }

  if (currentUserTimelineItem) {
    timelineItemsToRender.push(currentUserTimelineItem);
  }

  function renderTimelineItem(item, i, items) {
    if (item.type === 'message') {
      let showDetails = true;

      if (i > 0) {
        const previousItem = items[i - 1];

        if (previousItem && previousItem.type === 'message') {
          const isSameUserAsPrevious = (item.message.is_echo && previousItem.message.is_echo)
            || (item.user
              && previousItem.user
              && item.user.id === previousItem.user.id
            )
            || (!item.user
              && !previousItem.user
              && !item.message.is_echo
              && !previousItem.message.is_echo
            );

          const isWithinFourtySeconds = item.timestamp < (previousItem.timestamp + 40000);

          if (isSameUserAsPrevious && isWithinFourtySeconds) {
            showDetails = false;
          }
        }
      }

      return (
        <Message
          conversation={conversation}
          key={item.id}
          message={item.message}
          page={page}
          showDetails={showDetails}
          timestamp={item.timestamp}
          user={item.user}
        />
      );
    }

    if (item.type === 'category_change') {
      return (
        <CategoryChange
          category={item.category}
          conversation={conversation}
          key={item.id}
          user={item.user}
        />
      );
    }

    if (item.type === 'url_referral') {
      return (
        <UrlReferral
          key={item.id}
          name={conversation.name}
          url={item.url}
        />
      );
    }

    if (item.type.startsWith('user_')) {
      return (
        <User
          data={item}
          key={item.id}
          user={item.user}
        />
      );
    }

    if (item.type === 'conv_activated' || item.type === 'conv_archived') {
      return (
        <StatusChange
          conversation={conversation}
          data={item}
          key={item.id}
          user={item.user}
        />
      );
    }

    if (item.type === 'ad_referral') {
      return (
        <AdReferral
          conversation={conversation}
          data={item}
          key={item.id}
        />
      );
    }

    return null;
  }

  return timelineItemsToRender.map(renderTimelineItem);
}

export default Timeline;
