/* ----- Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import auth from 'helpers/auth';
import toast from 'helpers/toast';

/* ----- View import ------ */
import ChangePasswordCard from './change-password-card.view';

function ChangePasswordCardContainer(props) {
  const {
    onClose,
    user,
  } = props;

  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');

  const [errors, setErrors] = useState({});

  const [saving, setSaving] = useState(false);

  function validate() {
    const validationErrors = {};

    if (!confirmPassword) {
      validationErrors.confirmPassword = true;
    }
    if (!newPassword) {
      validationErrors.newPassword = true;
    }
    if (!oldPassword) {
      validationErrors.oldPassword = true;
    }

    return validationErrors;
  }

  async function onSubmit() {
    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    if (confirmPassword !== newPassword) {
      toast('Passwords do not match');
      return;
    }

    setSaving(true);

    let currentSuccess = false;
    try {
      await auth.validatePassword(oldPassword);
      currentSuccess = true;
    } catch (e) {
      if (e.code === 'auth/wrong-password') {
        toast('Your old password is invalid');
      } else {
        toast('Something went wrong - please try again');
      }
    }

    if (!currentSuccess) {
      setSaving(false);
      return;
    }

    let success = false;
    try {
      await api.patch(`/user/${user.id}`, { password: newPassword });
      await auth.validatePassword(newPassword); // Signing back in with our new password
      success = true;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.code === 'invalid_password') {
        toast('Your password must container at least 2 of the following: numbers, lowercase letters, uppercase letters, and punctuation');
      } else {
        toast('Something went wrong - please try again');
      }
    }

    setSaving(false);

    if (success) {
      toast('Password changed successfully');
      onClose();
    }
  }

  return (
    <ChangePasswordCard
      confirmPassword={confirmPassword}
      errors={errors}
      newPassword={newPassword}
      oldPassword={oldPassword}
      onConfirmPasswordChange={setConfirmPassword}
      onNewPasswordChange={setNewPassword}
      onOldPasswordChange={setOldPassword}
      onClose={onClose}
      onSubmit={onSubmit}
      saving={saving}
    />
  );
}

export default ChangePasswordCardContainer;
