/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import Icon from 'common/icon';
import Input from 'common/input';
import PlainButton from 'common/plain-button';
import Title from 'common/title';

function Signup(props) {
  const {
    data,
    errors,
    onChange,
    onSubmit,
    submitting,
  } = props;

  const {
    businessName,
    confirmPassword,
    email,
    firstName,
    lastName,
    password,
  } = data;

  function renderSideBar() {
    return (
      <div className="hidden lg:block overflow-y-auto fixed top-0 left-0 w-full max-w-md h-screen max-h-screen" style={{ backgroundColor: '#0BE881' }}>
        <div className="mx-16 py-16">
          <img
            alt="Ralli logo"
            className="w-20"
            src="/assets/img/logo-white.svg"
          />
          <div className="mt-20">
            <p className="font-heading text-green-900 text-md">Why sign up to Ralli?</p>
            <div className="flex mt-8">
              <div className="bg-white flex-shrink-0 rounded-full w-4 h-4 flex items-center justify-center mt-1">
                <Icon className="text-green-500" fontSize="8px" icon="check" />
              </div>
              <p className="text-sm ml-4 font-semibold text-green-900">Ralli ensures your sales and support can be done anywhere, anytime.</p>
            </div>
            <div className="flex mt-8">
              <div className="bg-white flex-shrink-0 rounded-full w-4 h-4 flex items-center justify-center mt-1">
                <Icon className="text-green-500" fontSize="8px" icon="check" />
              </div>
              <p className="text-sm mx-4 font-semibold text-green-900">Ralli smartly organises queries so that customers are talking to the right person in the company from the get-go.</p>
            </div>
            <div className="flex mt-8">
              <div className="bg-white flex-shrink-0 rounded-full w-4 h-4 flex items-center justify-center mt-1">
                <Icon className="text-green-500" fontSize="8px" icon="check" />
              </div>
              <p className="text-sm mx-4 font-semibold text-green-900">Ralli&apos;s extensions supercharge your social media presence by plugging Ralli directly into your chats.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderForm() {
    return (
      <div className="lg:bg-white lg:pl-112">
        <div className="py-6 sm:py-20 max-w-2xl mx-auto px-6">
          <img
            alt="Ralli logo"
            className="block lg:hidden w-16 mb-10"
            src="/assets/img/logo-round.svg"
          />

          <Title>Sign up your business to Ralli</Title>
          <PlainButton href="/login">Already signed up? Click here to login</PlainButton>

          <form
            disabled={submitting}
            onSubmit={onSubmit}
          >
            <Input
              className="mt-10 mb-6 pb-10 border-b border-gray-300"
              disabled={submitting}
              error={errors.businessName}
              label="Enter your business name"
              onChange={nv => onChange('businessName', nv)}
              value={businessName}
            />

            <Input
              className="mb-6"
              disabled={submitting}
              error={errors.email}
              label="Enter your email address"
              onChange={nv => onChange('email', nv)}
              value={email}
            />

            <Input
              className="mb-6"
              disabled={submitting}
              error={errors.password}
              label="Password"
              onChange={nv => onChange('password', nv)}
              type="password"
              value={password}
            />

            <Input
              className="mb-6"
              disabled={submitting}
              error={errors.confirmPassword}
              label="Confirm Password"
              onChange={nv => onChange('confirmPassword', nv)}
              type="password"
              value={confirmPassword}
            />

            <div className="flex w-full mb-6">
              <Input
                className="flex-1 mr-2"
                disabled={submitting}
                error={errors.firstName}
                label="First Name"
                onChange={nv => onChange('firstName', nv)}
                value={firstName}
              />
              <Input
                className="flex-1 ml-2"
                disabled={submitting}
                error={errors.lastName}
                label="Last Name"
                onChange={nv => onChange('lastName', nv)}
                value={lastName}
              />
            </div>

            <p className="text-gray-800 mb-10 text-sm font-medium">
              By signing up to Ralli, you agree to our&nbsp;
              <a
                className="font-semibold text-blue-500 focus:text-blue-600 hover:text-blue-600"
                href="https://ralli.app/legals/terms-and-conditions"
                rel="noopener noreferrer"
                target="_blank"
              >
                terms and conditions
              </a>
              &nbsp;and&nbsp;
              <a
                className="font-semibold text-blue-500 focus:text-blue-600 hover:text-blue-600"
                href="https://ralli.app/legals/privacy-policy"
                rel="noopener noreferrer"
                target="_blank"
              >
                privacy policy
              </a>
            </p>

            <Button
              className="mt-4 py-3 w-full"
              loading={submitting}
              type="submit"
            >
              Continue
            </Button>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div>
      {renderSideBar()}
      {renderForm()}
    </div>
  );
}

export default Signup;
