/* ------- Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';

function Join(props) {
  const { onJoin, submitting } = props;

  return (
    <div className="border-t border-gray-300 px-4 py-6 text-center">
      <p className="w-full text-sm flex-1 font-medium mb-4">
        Join this conversation to send messages yourself, or assign it to another user
      </p>
      <Button loading={submitting} onClick={onJoin}>JOIN</Button>
    </div>
  );
}

export default Join;
