/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Common imports ------ */
import Icon from 'common/icon';

/* ------ Special import for add user ------ */
import AddUser from 'components/category-set/main/category/expanded/add-user';

/* ------ Local components ------ */
import User from './user';

function Users(props) {
  const {
    category,
    onCategoryUpdated,
  } = props;

  const [adding, setAdding] = useState(false);

  const isUsersEmpty = !category.users || category.users.length === 0;
  const isInvitesEmpty = !category.invites || category.invites.length === 0;

  const isEmpty = isUsersEmpty && isInvitesEmpty;

  return (
    <div className="border-t border-gray-300">
      {!isEmpty && (
        <div className="pr-4 pt-4">
          <p className="text-gray-600 text-xs uppercase font-medium mb-2">Users assigned to this category</p>
          {category.users.map(user => (
            <User
              category={category}
              key={user.id}
              onCategoryUpdated={onCategoryUpdated}
              user={user}
            />
          ))}
          {category.invites.map(user => (
            <User
              category={category}
              key={user.id}
              onCategoryUpdated={onCategoryUpdated}
              user={user}
            />
          ))}
        </div>
      )}

      {isEmpty && (
        <p className="p-6 border-b border-gray-300 text-gray-600 text-sm text-center font-medium mb-2">There are no users assigned to this category yet</p>
      )}

      <div className={`pr-4 ${isEmpty ? 'pb-4' : 'pb-6'}`}>
        {!adding && (
          <button
            className="mt-4 flex items-center"
            onClick={() => setAdding(true)}
            type="button"
          >
            <div className="w-8 h-8 rounded-lg bg-gray-300 flex items-center justify-center font-bold text-blue-500">
              <Icon icon="plus" />
            </div>
            <p className="text-sm text-blue-500 font-medium ml-2">Add User</p>
          </button>
        )}
        {adding && (
          <AddUser
            category={category}
            onCancel={() => setAdding(false)}
            onCategoryUpdated={onCategoryUpdated}
          />
        )}
      </div>
    </div>
  );
}

export default Users;
