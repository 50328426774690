/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import Archive from './archive.view';

function ArchiveContainer(props) {
  const {
    ad,
    onArchived,
    onCancel,
  } = props;

  const [submitting, setSubmitting] = useState(false);

  async function onSubmit() {
    setSubmitting(true);

    let success = false;
    try {
      await api.patch(`/ad/${ad.id}`, { status: 'archived' });
      success = true;
    } catch (e) {
      toast('Something went wrong. Please try again.');
    }

    setSubmitting(false);

    if (success) {
      onArchived();
      toast('Ad successfully archived');
    }
  }

  return (
    <Archive
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitting={submitting}
    />
  );
}

export default ArchiveContainer;
