/* ------ Module imports ------ */
import React from 'react';

function CategoryChange(props) {
  const {
    category,
    conversation,
    user,
  } = props;

  let text = null;
  if (user) {
    if (category) {
      text = `${user.first_name} ${user.last_name} changed the category to ${category.name}`;
    } else {
      text = `${user.first_name} ${user.last_name} removed the category from this conversation`;
    }
  } else {
    text = `${conversation.name} chose the ${category.name} category`;
  }

  return (
    <div className="py-2">
      <p className="text-xs text-center text-gray-600 font-medium">{text}</p>
    </div>
  );
}

export default CategoryChange;
