/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import PlainButton from 'common/plain-button';

/* ------ Local components ------ */
import Day from './day';
import HelpSection from './help-section';

function View(props) {
  const {
    channel,
    onAdd,
  } = props;

  function renderHours() {
    const { hours } = channel;

    return (
      <div className="p-6">
        <Day
          day="Monday"
          end={hours.mon_end}
          start={hours.mon_start}
        />
        <Day
          day="Tuesday"
          end={hours.tue_end}
          start={hours.tue_start}
        />
        <Day
          day="Wednesday"
          end={hours.wed_end}
          start={hours.wed_start}
        />
        <Day
          day="Thursday"
          end={hours.thu_end}
          start={hours.thu_start}
        />
        <Day
          day="Friday"
          end={hours.fri_end}
          start={hours.fri_start}
        />
        <Day
          day="Saturday"
          end={hours.sat_end}
          start={hours.sat_start}
        />
        <Day
          day="Sunday"
          end={hours.sun_end}
          start={hours.sun_start}
        />
      </div>
    );
  }

  return (
    <>
      {channel.hours && renderHours()}
      {!channel.hours && (
        <div className="p-6 text-center">
          <p className="text-gray-600 text-sm">You haven&apos;t added opening hours yet</p>
          <PlainButton onClick={onAdd}>Add opening hours</PlainButton>
        </div>
      )}
      <HelpSection />
    </>
  );
}

export default View;
