/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import Colors from 'common/colors';
import Card from 'common/card';
import Input from 'common/input';
import PageSelector from 'common/page-selector';
import Title from 'common/title';

/* ------ Local components ------ */
import ProfilePicture from './profile-picture';

function AddChatPlugin(props) {
  const {
    backgroundColor,
    description,
    errors,
    foregroundColor,
    greeting,
    name,
    onBackgroundColorChanged,
    onDescriptionChanged,
    onForegroundColorChanged,
    onGreetingChanged,
    onNameChanged,
    onPageChanged,
    onProfilePictureChanged,
    onSubmit,
    page,
    profilePicture,
    submitting,
  } = props;

  return (
    <>
      <Title>Add chat plugin</Title>
      <p className="mt-4 text-sm font-medium text-gray-600">
        A chat plugin is the little bubble that appears in the corner of your website
        which customers can use to chat you on Ralli.
      </p>

      <Card className="mt-10 p-6">
        <Input
          className="mb-6"
          disabled={submitting}
          error={errors.name}
          label="Name (something to help you identify this)"
          onChange={onNameChanged}
          placeholder="Eg. Homepage plugin"
          value={name}
        />
        <Input
          disabled={submitting}
          error={errors.greeting}
          label="Greeting"
          onChange={onGreetingChanged}
          placeholder="Eg. Hi there!"
          value={greeting}
        />
        <div className={`mt-1 mb-2 text-right text-xs font-medium ${greeting.length > 32 ? 'text-red-500' : 'text-gray-600'}`}>{`${greeting.length} / 32`}</div>
        <Input
          disabled={submitting}
          error={errors.description}
          label="Description"
          onChange={onDescriptionChanged}
          placeholder="Eg. Feel free to ask us any questions you may have"
          value={description}
        />
        <div className={`mt-1 text-right text-xs font-medium ${description.length > 128 ? 'text-red-500' : 'text-gray-600'}`}>{`${description.length} / 128`}</div>
      </Card>

      <ProfilePicture
        onChange={onProfilePictureChanged}
        profilePictureUrl={profilePicture ? profilePicture.url : null}
      />

      <Card className="mt-10">
        <Card.Title>Do you want to add a Facebook Page?</Card.Title>
        <div className="p-6">
          <p className="text-sm font-medium text-gray-800 mb-4">
            If you add a Facebook page, when people interact with the plugin on
            your website they will have the option to chat to you via your Facebook
            page instead.
          </p>

          <PageSelector
            disabled={submitting}
            onChange={onPageChanged}
            value={page}
          />
        </div>
      </Card>

      <Card className="mt-10 p-6">
        <Colors
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
          greeting={greeting}
          name={name}
          onBackgroundColorChanged={onBackgroundColorChanged}
          onForegroundColorChanged={onForegroundColorChanged}
        />
      </Card>

      <div className="mt-10">
        <Button
          loading={submitting}
          onClick={onSubmit}
        >
          Confirm
        </Button>
      </div>
    </>
  );
}

export default AddChatPlugin;
