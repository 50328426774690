/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Checkbox from 'common/checkbox';
import LoadingSpinner from 'common/loading-spinner';

function AddUsers(props) {
  const {
    allChecked,
    onContinue,
    onSelectAll,
    onSelectUser,
    selectedUsers,
    submitting,
    users,
  } = props;

  if (submitting) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <p className="px-4 mb-3 text-gray-600 text-xs uppercase font-medium">Add users from this category</p>

      <div className="max-h-md overflow-y-auto px-4">
        {users.length > 1 && (
          <>
            <div className="inline-block">
              <Checkbox checked={allChecked} onSelect={onSelectAll} />
            </div>

            <div className="pt-2 border-b border-gray-300" />
          </>
        )}

        {users.map(u => (
          <div
            className="flex items-center pt-2"
            key={u.id}
          >
            <Checkbox checked={selectedUsers[u.id]} onSelect={() => onSelectUser(u)} />
            <span className="flex-1 text-sm text-gray-800 ml-3">{`${u.first_name} ${u.last_name}`}</span>
          </div>
        ))}
      </div>

      <div className="px-4 mt-4 border-t border-gray-300 pt-3">
        <button
          className="w-full bg-blue-500 hover:bg-blue-600 text-white rounded-lg text-sm font-semibold py-1 uppercase"
          onClick={onContinue}
          type="button"
        >
          Done
        </button>
      </div>
    </div>
  );
}

export default AddUsers;
