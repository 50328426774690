/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import ChangeEmail from './change-email-card.view';

function ChangeEmailCardContainer(props) {
  const {
    onClose,
    onEmailSent,
    user,
  } = props;

  const [email, setEmail] = useState('');

  const [error, setError] = useState(false);
  const [saving, setSaving] = useState(false);

  async function onSubmit() {
    if (!email) {
      setError(true);
      return;
    }

    setSaving(true);
    setError(false);

    let success = false;
    try {
      await api.post('/email_change', { email });
      success = true;
    } catch (e) {
      // Silently ignore - success will stay `false` so will toast an error
    }

    setSaving(false);

    if (success) {
      toast('A verification email has been sent to your new email address');
      onEmailSent(email);
      onClose();
    } else {
      toast('Please provide a vaild email address');
    }
  }

  return (
    <ChangeEmail
      email={email}
      error={error}
      onChange={setEmail}
      onClose={onClose}
      onSubmit={onSubmit}
      saving={saving}
      user={user}
    />
  );
}

export default ChangeEmailCardContainer;
