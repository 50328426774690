/* ------ Module imports ------ */
import React from 'react';

/* ------ Common import ------ */
import LoadingSpinner from 'common/loading-spinner';

/* ------ Local components ------ */
import Layer from './layer';

function SelectCategory(props) {
  const {
    categories,
    categoryStack,
    conversation,
    loading,
    onBack,
    onSelectCategory,
  } = props;

  function renderCategories() {
    if (!categories || categories.length === 0) {
      return (
        <p className="w-full text-left text-sm text-gray-800 px-4 py-1">
          {`No categories for this ${conversation.type === 'facebook' ? 'page' : 'chat plugin'}`}
        </p>
      );
    }

    return (
      <div className="relative">
        <button
          className="w-full text-left text-sm text-gray-800 hover:bg-gray-200 px-4 py-1 focus:outline-none"
          onClick={() => onSelectCategory(null)}
          type="button"
        >
          No category
        </button>

        <div>
          <Layer
            categories={categories}
            onSelectCategory={onSelectCategory}
          />

          {categoryStack.map(category => (
            <Layer
              categories={category.children}
              key={category.id}
              parent={category}
              onBack={onBack}
              onSelectCategory={onSelectCategory}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="max-h-md overflow-y-auto">
      <p className="px-4 mb-3 text-gray-600 text-xs uppercase font-medium">Change category</p>

      {loading && (
        <div className="px-4">
          <LoadingSpinner small />
        </div>
      )}
      {!loading && renderCategories()}
    </div>
  );
}

export default SelectCategory;
