/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ Common imports ------ */
import Checkbox from 'common/checkbox';
import Icon from 'common/icon';
import LoadingSpinner from 'common/loading-spinner';

function Dropdown(props) {
  const {
    filter,
    includeArchived,
    onSelectChannel,
    onToggleIncludeArchived,
  } = props;

  const [view, setView] = useState('loading');
  const [channels, setChannels] = useState(null);

  async function fetchPages() {
    const { data } = (await api.get('/page')).data;
    return data;
  }

  async function fetchChatPlugins() {
    const { data } = (await api.get('/chat_plugin')).data;
    return data;
  }

  async function fetchChannels() {
    let loadedPages = null;
    let loadedChatPlugins = null;

    try {
      [loadedPages, loadedChatPlugins] = await Promise.all([
        fetchPages(),
        fetchChatPlugins(),
      ]);
    } catch (e) {
      // Silently ignore - will be null so will show error
    }

    if (loadedPages && loadedChatPlugins) {
      setChannels(
        loadedPages
          .concat(loadedChatPlugins)
          .map(channel => {
            channel.type = channel.object === 'page' ? 'facebook' : 'web';
            return channel;
          }),
      );
      setView('main');
    } else {
      setView('error');
    }
  }

  useDidMount(() => {
    fetchChannels();
  });

  return (
    <div className="z-50 w-64 ml-4 mt-2 absolute top-0 left-0 popover popover-left bg-white shadow-xl border border-gray-300 rounded-lg">
      {view === 'loading' && (
        <div className="py-4 w-full flex justify-center">
          <LoadingSpinner small />
        </div>
      )}
      {view === 'error' && (
        <div className="py-4 w-full text-center">
          <Icon
            className="inline-block text-orange-600"
            icon="warning"
          />
          <p className="text-xs font-semibold text-orange-600">Something went wrong</p>
        </div>
      )}
      {view === 'main' && (
        <>
          <div className="px-4 py-2 flex items-center border-b border-gray-300">
            <Checkbox
              checked={includeArchived}
              onSelect={onToggleIncludeArchived}
            />
            <p className="ml-2 text-xs text-gray-800 font-medium">Show archived conversations</p>
          </div>
          <div className="rounded-b-lg overflow-hidden">
            <button
              className="w-full px-4 py-2 flex items-center justify-between hover:bg-gray-200"
              onClick={() => onSelectChannel(null)}
              type="button"
            >
              <div className="flex items-center">
                <div className="w-4" />
                <p className="ml-2 text-sm text-gray-800 font-medium">All channels</p>
              </div>
              <div>
                {!filter && (
                  <Icon
                    className="text-gray-800 text-xs"
                    icon="check"
                  />
                )}
              </div>
            </button>

            {channels.map(channel => (
              <button
                className="w-full px-4 py-2 flex items-center justify-between hover:bg-gray-200"
                key={channel.id}
                onClick={() => onSelectChannel(channel)}
                type="button"
              >
                <div className="flex items-center">
                  <img
                    alt={channel.name}
                    className="w-4"
                    src={`/assets/img/${channel.type}.svg`}
                  />
                  <p className="ml-2 text-sm text-gray-800 font-medium">{channel.name}</p>
                </div>
                <div>
                  {(filter && filter.id === channel.id) && (
                    <Icon
                      className="text-gray-800 text-xs"
                      icon="check"
                    />
                  )}
                </div>
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Dropdown;
