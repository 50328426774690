/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------- View imports ------ */
import Edit from './edit.view';

function EditContainer(props) {
  const {
    channel,
    onCancel,
    onUpdated,
  } = props;

  const currentZone = Intl.DateTimeFormat().resolvedOptions().timeZone || null;

  const [awayMessage, setAwayMessage] = useState(channel.hours ? channel.hours.away_message : '');
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [timezone, setTimezone] = useState(channel.hours ? channel.hours.timezone : currentZone);

  const [hours, setHours] = useState(channel.hours ? channel.hours : {
    mon_start: '09:00:00',
    mon_end: '17:00:00',
    tue_start: '09:00:00',
    tue_end: '17:00:00',
    wed_start: '09:00:00',
    wed_end: '17:00:00',
    thu_start: '09:00:00',
    thu_end: '17:00:00',
    fri_start: '09:00:00',
    fri_end: '17:00:00',
    sat_start: null,
    sat_end: null,
    sun_start: null,
    sun_end: null,
  });

  function validateData() {
    const validationErrors = {};

    if (!awayMessage || !awayMessage.trim() || awayMessage.length > 128) {
      validationErrors.awayMessage = true;
    }

    if (!timezone) {
      validationErrors.timezone = true;
    }

    if (hours.mon_start > hours.mon_end) {
      validationErrors.monday = true;
    }

    if (hours.tue_start > hours.tue_end) {
      validationErrors.tuesday = true;
    }

    if (hours.wed_start > hours.wed_end) {
      validationErrors.wednesday = true;
    }

    if (hours.thu_start > hours.thu_end) {
      validationErrors.thursday = true;
    }

    if (hours.fri_start > hours.fri_end) {
      validationErrors.friday = true;
    }

    if (hours.sat_start > hours.sat_end) {
      validationErrors.saturday = true;
    }

    if (hours.sun_start > hours.sun_end) {
      validationErrors.sunday = true;
    }

    return validationErrors;
  }

  async function onSubmit(ev) {
    ev.preventDefault();

    const validationErrors = validateData();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    setSubmitting(true);

    const postData = {
      ...hours,
      away_message: awayMessage,
      timezone,
    };

    if (channel.object === 'chat_plugin') {
      postData.chat_plugin = channel.id;
    } else {
      postData.page = channel.id;
    }

    let updatedHours;
    try {
      if (channel.hours) {
        const { data } = (await api.patch(`/hours/${channel.hours.id}`, postData)).data;
        updatedHours = data;
      } else {
        const { data } = (await api.post('/hours', postData)).data;
        updatedHours = data;
      }
    } catch (e) {
      // Silently ignore.
    }

    setSubmitting(false);

    if (updatedHours) {
      onUpdated(updatedHours);
    } else {
      toast('Something went wrong. Please try again');
    }
  }

  function onChangeHour(key, value) {
    const updatedHours = hours;
    updatedHours[key] = `${value}:00`;
    setHours(updatedHours);
  }

  function onToggleOpen(day) {
    const startKey = `${day}_start`;
    const endKey = `${day}_end`;

    const updatedHours = hours;
    if (updatedHours[startKey] && updatedHours[endKey]) {
      updatedHours[startKey] = null;
      updatedHours[endKey] = null;
    } else {
      updatedHours[startKey] = '09:00:00';
      updatedHours[endKey] = '17:00:00';
    }

    setHours({ ...updatedHours });
  }

  return (
    <Edit
      awayMessage={awayMessage}
      errors={errors}
      hours={hours}
      onAwayMessageChanged={setAwayMessage}
      onCancel={onCancel}
      onChangeHour={onChangeHour}
      onChangeTimezone={setTimezone}
      onSubmit={onSubmit}
      onToggleOpen={onToggleOpen}
      submitting={submitting}
      timezone={timezone}
    />
  );
}

export default EditContainer;
