/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import FacebookButton from 'common/facebook-button';

function FacebookLogin(props) {
  const {
    loading,
    facebookCallback,
  } = props;

  return (
    <div className="p-10 shadow-xl rounded-lg bg-white text-center">
      <p className="text-gray-800 text-sm font-medium mb-6">Login to Facebook to load your pages</p>

      <div className="flex justify-center">
        <FacebookButton
          loading={loading}
          onComplete={facebookCallback}
        />
      </div>
    </div>
  );
}

export default FacebookLogin;
