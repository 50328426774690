/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Card from 'common/card';
import PlainButton from 'common/plain-button';

/* ------ Local components ------ */
import Crop from './crop';

function ProfilePicture(props) {
  const {
    chatPluginId,
    onCancel,
    onUploadImage,
    onUpdated,
    profilePictureUrl,
    uploadedImage,
  } = props;

  return (
    <Card className="mt-10">
      <Card.Title>Profile picture</Card.Title>
      {!uploadedImage && (
        <div className="p-6 flex items-center">
          <img
            alt="Profile"
            className="border border-gray-300 w-16 h-16 rounded-full"
            src={profilePictureUrl}
          />
          <div className="ml-4">
            <p className="text-sm text-gray-800">This will show up on your chat plugin when people start a conversation</p>
            <PlainButton onClick={onUploadImage}>Change profile picture</PlainButton>
          </div>
        </div>
      )}
      {uploadedImage && (
        <Crop
          chatPluginId={chatPluginId}
          image={uploadedImage}
          onCancel={onCancel}
          onCompleted={onUpdated}
        />
      )}
    </Card>
  );
}

export default ProfilePicture;
