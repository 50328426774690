/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import PendingCancellation from './pending-cancellation.view';

function PendingCancellationContainer(props) {
  const {
    onResumed,
    periodEnd,
  } = props;

  const [resuming, setResuming] = useState(false);

  async function onResume() {
    setResuming(true);

    let subscription = null;
    try {
      const { data } = (await api.patch('/subscription', { cancel_at_period_end: false })).data;
      subscription = data;
    } catch (e) {
      // Silently ignore - `subscription` will be null so will show an error
    }

    setResuming(false);

    if (subscription) {
      onResumed(subscription);
    } else {
      toast('Something went wrong. Please try again.');
    }
  }

  return (
    <PendingCancellation
      onResume={onResume}
      periodEnd={periodEnd}
      resuming={resuming}
    />
  );
}

export default PendingCancellationContainer;
