/* ------ Module imports ------ */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

/* ------ Helpers ------ */
import api from 'helpers/api';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ View import ------ */
import Billing from './billing.view';

function BillingContainer(props) {
  const {
    business,
    onBusinessUpdated,
  } = props;

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [subview, setSubview] = useState(null);

  async function loadSubscription() {
    setLoading(true);

    let loadedSubscription = null;
    try {
      const { data } = (await api.get('/subscription')).data;
      loadedSubscription = data;
    } catch (e) {
      setError(true);
    }

    setSubscription(loadedSubscription);
    setLoading(false);
  }

  useDidMount(() => {
    loadSubscription();
  });

  function onCancelled(updatedSubscription) {
    setSubscription(updatedSubscription);
    setSubview(null);
  }

  function onCardChanged() {
    loadSubscription();
    setSubview(null);
  }

  function onReactivated(newSubscription) {
    onBusinessUpdated();
    setSubscription(newSubscription);
  }

  function onBillingEmailChanged(email) {
    const updatedSubscription = subscription;
    updatedSubscription.billing_email = email;
    setSubscription(updatedSubscription);
  }

  return (
    <Billing
      business={business}
      error={error}
      loading={loading}
      onBillingEmailChanged={onBillingEmailChanged}
      onCancel={() => setSubview('cancel')}
      onCancelled={onCancelled}
      onCardChanged={onCardChanged}
      onChangeCard={() => setSubview('change_card')}
      onPaid={onBusinessUpdated}
      onReactivated={onReactivated}
      onRemoveSubview={() => setSubview(null)}
      onResumed={setSubscription}
      onSetupSubscription={() => history.push('/subscribe')}
      subscription={subscription}
      subview={subview}
    />
  );
}

export default BillingContainer;
