/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ View import ------ */
import User from './user.view';

function UserContainer(props) {
  const {
    isCurrentUser,
    isLastChild,
    onUserDeleted,
    onUserUpdated,
    totalUsers,
    user,
  } = props;

  const [expanded, setExpanded] = useState(false);

  return (
    <User
      expanded={expanded}
      isCurrentUser={isCurrentUser}
      isLastChild={isLastChild}
      onToggleExpand={() => setExpanded(!expanded)}
      onUserDeleted={onUserDeleted}
      onUserUpdated={onUserUpdated}
      totalUsers={totalUsers}
      user={user}
    />
  );
}

export default UserContainer;
