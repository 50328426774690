/* ------ Module imports ------ */
import React from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

/* ------ Common imports ------ */
import Button from 'common/button';
import PlainButton from 'common/plain-button';

function ChangeCard(props) {
  const {
    onCancel,
    onSave,
    saving,
  } = props;

  const elements = useElements();
  const stripe = useStripe();

  function onSaveCard() {
    const card = elements.getElement(CardElement);
    onSave(card, stripe);
  }

  return (
    <div className="pt-4 p-6">
      <p className="inline-block text-sm text-gray-800 font-medium mb-1">Enter your new card details</p>
      <div className="px-3 py-2 border border-gray-300 rounded-lg mb-6">
        <CardElement options={{ hidePostalCode: true }} />
      </div>

      <div className="flex items-center">
        <Button
          loading={saving}
          onClick={onSaveCard}
        >
          Save
        </Button>

        <PlainButton
          className="ml-4"
          disabled={saving}
          onClick={onCancel}
        >
          Cancel
        </PlainButton>
      </div>
    </div>
  );
}

export default ChangeCard;
