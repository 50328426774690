/* ------ Module imports ------ */
import React, { useState } from 'react';
import Select, { components } from 'react-select';

/* ------ Data import ------ */
import countries from './countries';

function SingleValue({ children, ...props }) {
  const { data } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.SingleValue {...props}>
      <span className="text-sm text-gray-800 font-medium">{data.label}</span>
    </components.SingleValue>
  );
}

function Option({ children, ...props }) {
  const { data } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.Option {...props}>
      <span className="text-sm text-gray-800 font-medium">{data.label}</span>
    </components.Option>
  );
}

function CountrySelector(props) {
  const {
    onChange,
    value,
  } = props;

  const options = countries.map(country => ({
    value: country.countryName,
    label: country.countryName,
  }));

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="flex-1">
      <Select
        autoFocus
        classNamePrefix="select"
        components={{ Option, SingleValue }}
        menuIsOpen={menuOpen}
        options={options}
        onChange={onChange}
        onMenuOpen={() => setMenuOpen(true)}
        onMenuClose={() => setMenuOpen(false)}
        placeholder="Select your country"
        value={value}
      />
    </div>
  );
}

export default CountrySelector;
