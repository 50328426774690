/* ------ Module imports ------ */
import React, { useMemo, useState } from 'react';
import Select, { components } from 'react-select';
import { useHistory } from 'react-router-dom';

/* ------ Common imports ------ */
import PlainButton from 'common/plain-button';

function SingleValue({ children, ...props }) {
  const { data } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.SingleValue {...props}>
      <div className="inline-block align-middle mr-2 w-6 h-6 rounded bg-cover" style={{ backgroundImage: `url(${data.profilePic})` }} />
      <span className="inline-block align-middle text-sm text-gray-800 font-medium">{data.label}</span>
    </components.SingleValue>
  );
}

function Option({ children, ...props }) {
  const { data } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.Option {...props}>
      <div className="inline-block align-middle mr-2 w-8 h-8 rounded-lg bg-cover" style={{ backgroundImage: `url(${data.profilePic})` }} />
      <span className="inline-block align-middle text-sm text-gray-800 font-medium">{data.label}</span>
    </components.Option>
  );
}

function NoOptionsMessage({ children, ...props }) {
  const { selectProps } = props;

  const history = useHistory();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.NoOptionsMessage {...props}>
      <div className="text-center py-4">
        {!selectProps.error && (
          <>
            <p className="mb-2">No Facebook Pages found.</p>
            <PlainButton onClick={() => history.push('/pages-add')}>Want to set up a new page?</PlainButton>
          </>
        )}
        {selectProps.error && (
          <p className="text-orange-500">Could not load pages. Please try again.</p>
        )}
      </div>
    </components.NoOptionsMessage>
  );
}

function PageSelector(props) {
  const {
    disabled,
    loading,
    onChange,
    pages,
    value,
  } = props;

  const [menuOpen, setMenuOpen] = useState(false);

  const options = useMemo(() => {
    if (!pages) {
      return [];
    }

    return pages.map(p => ({
      label: p.name,
      value: p.id,
      profilePic: p.profile_picture,
    }));
  }, [pages]);

  let selectedValue = null;
  options.forEach(opt => {
    if (opt.value === value) {
      selectedValue = opt;
    }
  });

  function onUpdate(option) {
    onChange(option ? option.value : null);
  }

  return (
    <div className="flex-1">
      <Select
        classNamePrefix="select"
        components={{ Option, SingleValue, NoOptionsMessage }}
        isClearable
        isDisabled={disabled}
        isLoading={loading}
        menuIsOpen={menuOpen}
        options={options}
        onChange={onUpdate}
        onMenuOpen={() => setMenuOpen(true)}
        onMenuClose={() => setMenuOpen(false)}
        placeholder="Choose a Facebook Page"
        value={selectedValue}
      />
    </div>
  );
}

export default PageSelector;
