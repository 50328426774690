/* ------ Module imports ------ */
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

/* ------ Context ------ */
import PlatformContext from 'components/platform/context';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';
import userUtil from 'helpers/user';

/* ------ View import ------ */
import AddChatPlugin from './add-chat-plugin.view';

function AddChatPluginContainer() {
  const { user } = useContext(PlatformContext);

  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  const [backgroundColor, setBackgroundColor] = useState('#4CD964');
  const [foregroundColor, setForegroundColor] = useState('#FFFFFF');
  const [name, setName] = useState('');
  const [greeting, setGreeting] = useState('');
  const [description, setDescription] = useState('');
  const [page, setPage] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);

  const history = useHistory();

  function validateData() {
    const validationErrors = {};

    if (!name || !name.trim()) {
      validationErrors.name = true;
    }

    if (!greeting || !greeting.trim() || greeting.length > 32) {
      validationErrors.greeting = true;
    }

    if (!description || !description.trim() || description.length > 128) {
      validationErrors.description = true;
    }

    return validationErrors;
  }

  async function onSubmit() {
    const validationErrors = validateData();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    setSubmitting(true);

    const postData = {
      background_color: backgroundColor,
      foreground_color: foregroundColor,
      name,
      greeting,
      description,
    };

    if (page) {
      postData.page = page;
    }

    if (profilePicture && profilePicture.url) {
      postData.profile_picture_url = profilePicture.url;
    }

    let plugin = null;
    try {
      const { data } = (await api.post('/chat_plugin', postData)).data;
      plugin = data;
    } catch (e) {
      // Silently ignore - `plugin` will be null so will show an error
    }

    if (user.business.onboarding_status !== 'finished') {
      try {
        await userUtil.refreshUser();
      } catch (err) {
        // Ignore.
      }
    }

    setSubmitting(false);

    if (plugin) {
      history.push(`/chat-plugin/${plugin.id}`);
    } else {
      toast('Something went wrong. Please try again.');
    }
  }

  return (
    <AddChatPlugin
      backgroundColor={backgroundColor}
      description={description}
      errors={errors}
      foregroundColor={foregroundColor}
      greeting={greeting}
      name={name}
      onBackgroundColorChanged={setBackgroundColor}
      onDescriptionChanged={setDescription}
      onForegroundColorChanged={setForegroundColor}
      onGreetingChanged={setGreeting}
      onNameChanged={setName}
      onPageChanged={setPage}
      onProfilePictureChanged={setProfilePicture}
      onSubmit={onSubmit}
      page={page}
      profilePicture={profilePicture}
      submitting={submitting}
    />
  );
}

export default AddChatPluginContainer;
