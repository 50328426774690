/* ------ Module imports ------ */
import React from 'react';
import moment from 'moment';

function Day(props) {
  const {
    day,
    end,
    start,
  } = props;

  return (
    <div className="flex items-center mb-3 last:mb-0">
      <p className="w-32 text-sm text-gray-800 font-medium">{day}</p>

      {start && end && (
        <p className="text-sm text-gray-800">{`${moment(start, 'HH:mm:ss').format('h:mma')} to ${moment(end, 'HH:mm:ss').format('hh:mma')}`}</p>
      )}
      {(!start || !end) && (
        <p className="text-sm text-gray-800">Closed</p>
      )}
    </div>
  );
}

export default Day;
