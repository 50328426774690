/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import Edit from './edit.view';

function EditContainer(props) {
  const {
    ad,
    onCancel,
    onUpdated,
    page,
  } = props;

  const [name, setName] = useState(ad.name);
  const [fbId, setFbId] = useState(ad.fb_ad_id);
  const [category, setCategory] = useState(
    ad.category
      ? { label: ad.category.name, value: ad.category.id }
      : null,
  );

  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  function validateData() {
    const validationErrors = {};

    if (!name) {
      validationErrors.name = true;
    }
    if (!fbId) {
      validationErrors.fbId = true;
    }
    if (!category) {
      validationErrors.category = true;
    }

    return validationErrors;
  }

  async function onSubmit(e) {
    e.preventDefault();

    const validationErrors = validateData();
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    setSubmitting(true);

    const postData = {};
    if (name !== ad.name) {
      postData.name = name;
    }
    if (fbId !== ad.fb_ad_id) {
      postData.fb_ad_id = fbId;
    }
    if (!ad.category || category.value !== ad.category.id) {
      postData.category = category.value;
    }

    let updatedAd = null;
    try {
      const { data } = (await api.patch(`/ad/${ad.id}?expand[]=category`, postData)).data;
      updatedAd = data;
    } catch (err) {
      toast('Something went wrong. Please try again');
    }

    setSubmitting(false);

    if (updatedAd) {
      onUpdated(updatedAd);
      toast('Ad edited successfully');
    }
  }

  return (
    <Edit
      category={category}
      errors={errors}
      fbId={fbId}
      name={name}
      onCancel={onCancel}
      onCategoryChanged={setCategory}
      onFbIdChanged={setFbId}
      onNameChanged={setName}
      onSubmit={onSubmit}
      page={page}
      submitting={submitting}
    />
  );
}

export default EditContainer;
