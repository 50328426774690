/* ------ Module imports ------ */
import React, { useContext, useState } from 'react';

/* ------ Context ------ */
import ConversationContext from 'common/conversation/context';

/* ------ Helpers ------ */
import toast from 'helpers/toast';

/* ------ Local components ------ */
import User from './user';

function Users() {
  const {
    conversation,
    onUpdateConversation,
  } = useContext(ConversationContext);
  const { users } = conversation;

  const [removing, setRemoving] = useState(false);

  async function onRemove(userToRemove) {
    setRemoving(true);

    const updatedUsers = users.filter(u => u.id !== userToRemove.id).map(u => u.id);

    let success = false;
    try {
      await onUpdateConversation('users', updatedUsers);
      success = true;
    } catch (e) {
      // Silently ignore - `success` will be false so will show an error
    }

    setRemoving(false);

    if (!success) {
      toast('Could not remove user - something went wrong.');
    }
  }

  return users.map(user => (
    <User
      data={user}
      key={user.id}
      onRemove={() => onRemove(user)}
      removing={removing}
    />
  ));
}

export default Users;
