/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ View import ------ */
import ConnectAccount from './connect-account.view';

function ConnectAccountContainer(props) {
  const { id } = props;

  const [loading, setLoading] = useState(true);
  const [business, setBusiness] = useState(null);

  async function fetchData() {
    let loadedBusiness = null;
    try {
      const { data } = (await api.get(`/business/${id}?expand[]=connect_account`)).data;
      loadedBusiness = data;
    } catch (e) {
      // Silently ignore
    }

    if (loadedBusiness) {
      setBusiness(loadedBusiness);
    }

    setLoading(false);
  }

  useDidMount(() => {
    fetchData();
  });

  return (
    <ConnectAccount
      business={business}
      loading={loading}
    />
  );
}

export default ConnectAccountContainer;
