/* ------ Module imports ------ */
import React from 'react';

/* ------ Special category component import ------ */
import Category from 'components/category-set/main/category';

/* ------ Local components ------ */
import AddCategory from './add-category';
import Delete from './delete';
import Empty from './empty';
import Users from './users';

function Expanded(props) {
  const {
    category,
    categorySetId,
    depth,
    onCancel,
    onCategoryDeleted,
    onCategoryUpdated,
    onSubcategoryAdded,
    onSubcategoryUpdated,
    subview,
  } = props;

  if (subview === 'delete') {
    return (
      <Delete
        category={category}
        onCancel={onCancel}
        onDeleted={() => onCategoryDeleted(category)}
      />
    );
  }

  if (category.children && category.children.length) {
    return (
      <div>
        {category.children.map(cat => (
          <Category
            category={cat}
            categorySetId={categorySetId}
            depth={depth + 1}
            key={cat.id}
            onCategoryDeleted={onCategoryDeleted}
            onCategoryUpdated={onSubcategoryUpdated}
            parent={category}
          />
        ))}
        {subview === 'add' && (
          <AddCategory
            categorySetId={categorySetId}
            onCancel={onCancel}
            onCategoryAdded={onSubcategoryAdded}
            parent={category}
          />
        )}
      </div>
    );
  }

  if ((category.users && category.users.length) || (category.invites && category.invites.length)) {
    return (
      <Users
        category={category}
        onCategoryUpdated={onCategoryUpdated}
      />
    );
  }

  return (
    <Empty
      category={category}
      categorySetId={categorySetId}
      canAddSubcategory={depth < 4}
      onCategoryAdded={onSubcategoryAdded}
      onCategoryUpdated={onCategoryUpdated}
    />
  );
}

export default Expanded;
