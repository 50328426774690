/* ------ Module imports ------ */
import React, { useContext, useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';
import userUtil from 'helpers/user';

/* ------ Context ------ */
import PlatformContext from 'components/platform/context';

/* ------ View import ------ */
import InviteCard from './invite-card.view';

function InviteCardContaier(props) {
  const {
    onCloseInviteCard,
    onInviteAdded,
  } = props;

  const { user } = useContext(PlatformContext);

  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);

  async function onSubmit(e) {
    e.preventDefault();

    setSubmitting(true);

    let invite = null;
    try {
      const { data } = (await api.post('/invite', { email })).data;
      invite = data;
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.code === 'invited') {
        toast('You have already invited this user. You can resend their invitation on the invited users page.');
        setSubmitting(false);
        return;
      }

      // Silently ignore - `invite` will stay null so will show an error
    }

    if (!invite) {
      toast('Please provide a valid email address');
      setSubmitting(false);
      return;
    }

    if (user.business.onboarding_status !== 'finished') {
      try {
        await userUtil.refreshUser();
      } catch (err) {
        // Ignore.
      }
    }

    setSubmitting(false);

    toast('An invitation email has been sent');

    onInviteAdded(invite);
    onCloseInviteCard();
  }

  return (
    <InviteCard
      email={email}
      onChange={setEmail}
      onCloseInviteCard={onCloseInviteCard}
      onSubmit={onSubmit}
      submitting={submitting}
    />
  );
}

export default InviteCardContaier;
