/* ------ Module imports ------ */
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';

/* ------ Context ------ */
import InboxContext from 'components/inbox/context';
import PlatformContext from 'components/platform/context';

/* ------ Helpers ------ */
import api from 'helpers/api';
import usePathname from 'helpers/hooks/use-pathname';
import useRerender from 'helpers/hooks/use-rerender';
import useWatcher from 'helpers/hooks/use-watcher';

/* ------ Common imports ------ */
import BillingError from 'common/billing-error';

/* ------ View import ------ */
import Inbox from './inbox.view';

function InboxContainer() {
  const {
    billingError,
    user,
  } = useContext(PlatformContext);

  useRerender(60000);

  const currentPath = usePathname();

  const [view, setView] = useState('loading');
  const [tab, setTab] = useState('assigned');
  const [includeArchived, setIncludeArchived] = useState(false);
  const [conversations, setConversations] = useState(null);
  const [filter, setFilter] = useState(null);

  const expansions = ['page', 'last_timeline_item', 'last_timeline_item.user', 'chat_plugin'];

  function buildUrl() {
    const base = '/conversation';

    let queryParams = ['limit=20'];

    if (!includeArchived) {
      queryParams.push('status=active');
    }

    if (tab === 'unassigned') {
      queryParams.push('filter=unassigned');
    }
    if (tab === 'all') {
      queryParams.push('filter=all');
    }

    if (filter) {
      if (filter.type === 'facebook') {
        queryParams.push(`page=${filter.id}`);
      }
      if (filter.type === 'web') {
        queryParams.push(`chat_plugin=${filter.id}`);
      }
    }

    queryParams = queryParams.concat(expansions.map(field => `expand=${field}`));
    return `${base}?${queryParams.join('&')}`;
  }

  async function fetchConversations() {
    setView('loading');

    const url = buildUrl();

    let loadedConversations = null;
    try {
      const { data } = (await api.get(url)).data;
      loadedConversations = data;
    } catch (e) {
      // Silently ignore.
    }

    if (loadedConversations) {
      /* ------ If we are viewing a conversation, mark it as read ------ */
      const path = currentPath.split('/');
      if (path.length === 3) {
        const id = path[path.length - 1];
        loadedConversations = loadedConversations.map(conv => {
          if (conv.id === id) {
            conv.unread_notifications = false;
          }

          return conv;
        });
      }

      setConversations(loadedConversations);
      setView('main');
    } else {
      setView('error');
    }
  }

  async function loadMoreConversations() {
    const lastConv = conversations[conversations.length - 1];
    if (!lastConv || !lastConv.last_timeline_item) {
      return null;
    }

    const before = moment(lastConv.last_timeline_item.timestamp).unix();

    let url = buildUrl();
    url = `${url}&before=${before}`;

    let newConversations = null;
    try {
      const { data } = (await api.get(url)).data;
      newConversations = data;
    } catch (e) {
      // Silently ignore
    }

    if (!newConversations) {
      return null;
    }

    setConversations(conversations.concat(newConversations));
    return newConversations;
  }

  function onConversationStatusChanged(conversation) {
    const shouldRemove = conversation.status === 'archived' && !includeArchived;

    if (shouldRemove) {
      setConversations(conversations.filter(c => c.id !== conversation.id));
    }
  }

  function onConversationRead(conversation) {
    if (!conversation || !conversations) {
      return;
    }

    setConversations(conversations.map(conv => {
      if (conversation.id === conv.id) {
        conv.unread_notifications = false;
      }

      return conv;
    }));
  }

  useEffect(() => {
    fetchConversations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, filter, includeArchived]);

  function fitsFilter(conversation) {
    if (!filter) {
      return true;
    }

    if (conversation.chat_plugin) {
      return filter.id === conversation.chat_plugin.id;
    }
    if (conversation.page) {
      return filter.id === conversation.page.id;
    }

    return false;
  }

  useWatcher('conv_new', async id => {
    let conversation = null;
    try {
      const { data } = (await api.get(`/conversation/${id}?expand[]=${expansions.join('&expand[]=')}&expand[]=is_assigned_to_me`)).data;
      conversation = data;
    } catch (e) {
      // Silently ignore
    }

    if (conversation && (includeArchived || conversation.status === 'active') && fitsFilter(conversation)) {
      /* ------ If we are looking at it right now, mark it as read too ------ */
      if (currentPath === `/inbox/${conversation.id}`) {
        conversation.unread_notifications = false;
      }

      const updatedConversations = [...conversations];
      if (tab === 'unassigned' && conversation.is_unassigned) {
        updatedConversations.unshift(conversation);
      }
      if (tab === 'assigned' && conversation.is_assigned_to_me) {
        updatedConversations.unshift(conversation);
      }
      if (tab === 'all') {
        updatedConversations.unshift(conversation);
      }

      if (!conversations.find(c => c.id === conversation.id)) {
        setConversations(updatedConversations);
      }
    }
  });

  useWatcher('conv_event', async message => {
    /* ------ See if we have the conversation ------ */
    let conversation = null;
    conversations.forEach(conv => {
      if (conv.id === message.conversation) {
        conversation = conv;
      }
    });

    if (!conversation) {
      /* ------ If we don't have it, we should load it ------ */
      try {
        const { data } = (await api.get(`/conversation/${message.conversation}?expand[]=${expansions.join('&expand[]=')}&expand[]=is_assigned_to_me`)).data;
        conversation = data;
      } catch (e) {
        // Silently ignore
      }

      /* ------ See if we are supposed to have it ------ */
      if (conversation && (includeArchived || conversation.status === 'active') && fitsFilter(conversation)) {
        /* ------ If we are looking at it right now, mark it as read too ------ */
        if (currentPath === `/inbox/${conversation.id}`) {
          conversation.unread_notifications = false;
        }

        const updatedConversations = [...conversations];
        if (tab === 'unassigned' && conversation.is_unassigned) {
          updatedConversations.unshift(conversation);
        }
        if (tab === 'assigned' && conversation.is_assigned_to_me) {
          updatedConversations.unshift(conversation);
        }
        if (tab === 'all') {
          updatedConversations.unshift(conversation);
        }

        if (!conversations.find(c => c.id === conversation.id)) {
          setConversations(updatedConversations);
        }
      }
    } else {
      /* ------ If we do have it, update it and bring it to the front ------ */
      const updatedConversations = conversations.filter(conv => conv.id !== conversation.id);

      /**
       * If the new timeline item was us leaving the conversation,
       * and we are looking at the assigned tab, remove it.
       */
      if (message.timeline_item.type === 'user_left' || message.timeline_item.type === 'user_removed') {
        if (message.timeline_item.updated_user.id === user.id && tab === 'assigned') {
          setConversations(updatedConversations);
          return;
        }
      }

      /**
       * If the timeline item was someone joining the conversation,
       * and we are looking at the unassigned tab, remove it.
       */
      if (message.timeline_item.type === 'user_added') {
        if (tab === 'unassigned') {
          setConversations(updatedConversations);
          return;
        }
      }

      conversation.last_timeline_item = message.timeline_item;

      /* ------ If we aren't looking at right now, mark it as unread too ------ */
      if (currentPath !== `/inbox/${conversation.id}`) {
        conversation.unread_notifications = true;
      }

      updatedConversations.unshift(conversation);
      setConversations(updatedConversations);
    }
  });

  useWatcher('conv_assigned', async (id, isUnassigned) => {
    /* ------ If there is a new unassigned, and we're in the unassigned tab, add it ------ */
    if (tab === 'unassigned' && isUnassigned && !conversations.find(conv => conv.id === id)) {
      let conversation = null;
      try {
        const { data } = (await api.get(`/conversation/${id}?expand[]=${expansions.join('&expand[]=')}&expand[]=is_assigned_to_me`)).data;
        conversation = data;
      } catch (e) {
        // Silently ignore
      }

      /* ------ If we are looking at it right now, mark it as read ------ */
      if (conversation && fitsFilter(conversation)) {
        if (currentPath !== `/inbox/${conversation.id}`) {
          conversation.unread_notifications = false;
        }

        if (!conversations.find(c => c.id === conversation.id)) {
          setConversations([conversation, ...conversations]);
        }
      }
    }

    if (tab === 'unassigned' && !isUnassigned) {
      setConversations(conversations.filter(c => c.id !== id));
    }
  });

  if (billingError) {
    return <BillingError error={billingError} />;
  }

  const context = {
    onConversationStatusChanged,
    onConversationRead,
  };

  return (
    <InboxContext.Provider value={context}>
      <Inbox
        conversations={conversations}
        filter={filter}
        includeArchived={includeArchived}
        onChangeTab={setTab}
        onFilter={setFilter}
        onLoadMoreConversations={loadMoreConversations}
        onToggleIncludeArchived={() => setIncludeArchived(!includeArchived)}
        showAllTab={user.type === 'admin'}
        tab={tab}
        view={view}
      />
    </InboxContext.Provider>
  );
}

export default InboxContainer;
