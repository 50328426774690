/* ------ Module imports ------ */
import React from 'react';
import { Route, Switch } from 'react-router-dom';

/* ------ Helpers ------ */
import usePathname from 'helpers/hooks/use-pathname';
import useWindowHeight from 'helpers/hooks/use-window-height';

/* ------ Common imports ------ */
import LoadingSpinner from 'common/loading-spinner';

/* ------ Local components ------ */
import Conversation from './conversation';
import Filter from './filter';
import LoadMore from './load-more';
import SidebarConversation from './sidebar-conversation';

function Inbox(props) {
  const {
    conversations,
    filter,
    includeArchived,
    onChangeTab,
    onFilter,
    onLoadMoreConversations,
    onToggleIncludeArchived,
    showAllTab,
    tab,
    view,
  } = props;

  const currentPath = usePathname();
  const ref = useWindowHeight();

  function renderConversation(conversation) {
    return (
      <SidebarConversation
        conversation={conversation}
        key={conversation.id}
        selected={currentPath === `/inbox/${conversation.id}`}
      />
    );
  }

  const showChildrenView = currentPath !== '/inbox';

  return (
    <div
      className="absolute top-0 left-0 w-full h-screen flex items-start pt-16"
      ref={ref}
    >
      <div className={`${showChildrenView ? 'hidden' : 'flex'} md:flex flex-col bg-white w-full md:w-80 border-r border-gray-300 h-full overflow-y-hidden`}>

        <div>
          <div className="flex items-center px-6 overflow-x-auto border-b border-gray-300">
            <button
              className={`focus:outline-none uppercase text-xs font-semibold whitespace-no-wrap pt-3 pb-2 border-b-2 ${tab === 'assigned' ? 'border-green-brand text-gray-800' : 'border-transparent text-gray-600'}`}
              onClick={() => onChangeTab('assigned')}
              type="button"
            >
              Assigned to me
            </button>
            <button
              className={`focus:outline-none uppercase text-xs font-semibold whitespace-no-wrap pt-3 pb-2 border-b-2 ${tab === 'unassigned' ? 'border-green-brand text-gray-800' : 'border-transparent text-gray-600'} ml-4`}
              onClick={() => onChangeTab('unassigned')}
              type="button"
            >
              Unassigned
            </button>
            {showAllTab && (
              <button
                className={`focus:outline-none uppercase text-xs font-semibold whitespace-no-wrap pt-3 pb-2 border-b-2 ${tab === 'all' ? 'border-green-brand text-gray-800' : 'border-transparent text-gray-600'} ml-4`}
                onClick={() => onChangeTab('all')}
                type="button"
              >
                All
              </button>
            )}
          </div>

          <Filter
            filter={filter}
            includeArchived={includeArchived}
            onFilter={onFilter}
            onToggleIncludeArchived={onToggleIncludeArchived}
          />
        </div>

        <div className="flex-1 p-2 overflow-y-auto">
          {view === 'loading' && (
            <div className="mt-4 w-full flex justify-center">
              <LoadingSpinner />
            </div>
          )}
          {view === 'main' && (
            <>
              {conversations.map(renderConversation)}
              {(conversations.length > 0 && onLoadMoreConversations) && (
                <LoadMore
                  key={`${tab}_${filter ? filter.id : 'all'}`}
                  onLoadMoreConversations={onLoadMoreConversations}
                />
              )}
              {conversations.length === 0 && (
                <div className="text-center my-20">
                  <img
                    alt=""
                    className="w-12 mb-4 mx-auto"
                    src="/assets/icons/mailbox.svg"
                  />
                  <p className="text-gray-600 text-sm font-semibold">No conversations</p>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className={`${showChildrenView ? 'block' : 'hidden'} md:block flex-1 relative h-full`}>
        <Switch>
          <Route
            path="/inbox/:id"
            component={Conversation}
          />
        </Switch>
      </div>
    </div>
  );
}

export default Inbox;
