/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import Input from 'common/input';
import PageSelector from 'common/page-selector';
import PlainButton from 'common/plain-button';

function Edit(props) {
  const {
    description,
    errors,
    greeting,
    name,
    onCancel,
    onDescriptionChanged,
    onGreetingChanged,
    onNameChanged,
    onPageChanged,
    onSubmit,
    page,
    submitting,
  } = props;

  return (
    <div className="p-6">
      <form
        disabled={submitting}
        onSubmit={onSubmit}
      >
        <Input
          className="mb-6"
          disabled={submitting}
          error={errors.name}
          label="Name"
          onChange={onNameChanged}
          value={name}
        />
        <Input
          disabled={submitting}
          error={errors.greeting}
          label="Greeting"
          onChange={onGreetingChanged}
          value={greeting}
        />
        <div className={`mt-1 mb-2 text-right text-xs font-medium ${greeting.length > 32 ? 'text-red-500' : 'text-gray-600'}`}>{`${greeting.length} / 32`}</div>
        <Input
          disabled={submitting}
          error={errors.description}
          label="Description"
          onChange={onDescriptionChanged}
          value={description}
        />
        <div className={`mt-1 mb-2 text-right text-xs font-medium ${description.length > 128 ? 'text-red-500' : 'text-gray-600'}`}>{`${description.length} / 128`}</div>

        <div>
          <span className="inline-block text-sm text-gray-800 font-medium mb-1">Facebook Page</span>
          <PageSelector
            disabled={submitting}
            onChange={onPageChanged}
            value={page}
          />
        </div>

        <div className="mt-10 flex items-center">
          <Button
            loading={submitting}
            type="submit"
          >
            Save
          </Button>

          <PlainButton
            className="ml-4"
            disabled={submitting}
            onClick={onCancel}
            type="button"
          >
            Cancel
          </PlainButton>
        </div>
      </form>
    </div>
  );
}

export default Edit;
