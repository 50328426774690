/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import Input from 'common/input';
import PlainButton from 'common/plain-button';

function Delete(props) {
  const {
    confirmValue,
    onCancel,
    onConfirmDelete,
    onConfirmValueChanged,
    onStartDelete,
    submitting,
    view,
  } = props;

  function renderMainView() {
    return (
      <>
        <p className="text-sm text-gray-800 mt-2 mb-6">
          Note that if you delete this chat plugin, all conversations from it will also be deleted.
        </p>

        <Button
          destructive
          onClick={onStartDelete}
        >
          Delete this chat plugin
        </Button>
      </>
    );
  }

  function renderDeletingView() {
    return (
      <>
        <Input
          className="mt-2 mb-6"
          label={'Type the word "delete" to confirm you want to delete this chat plugin'}
          onChange={onConfirmValueChanged}
          value={confirmValue}
        />

        <Button
          destructive
          disabled={confirmValue.toLowerCase().trim() !== 'delete'}
          loading={submitting}
          onClick={onConfirmDelete}
        >
          Delete
        </Button>
        <PlainButton
          className="ml-4"
          color="gray"
          disabled={submitting}
          onClick={onCancel}
        >
          Cancel
        </PlainButton>
      </>
    );
  }

  return (
    <div className="border-t border-gray-300 p-6">
      <p className="uppercase text-sm font-semibold text-gray-800">Delete chat plugin</p>

      {view === 'main' && renderMainView()}
      {view === 'deleting' && renderDeletingView()}
    </div>
  );
}

export default Delete;
