/* ------ Module imports ------ */
import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';

/* ------ Helpers ------ */
import usePathname from 'helpers/hooks/use-pathname';

/* ------ Common imports ------ */
import Card from 'common/card';
import Hours from 'common/hours';
import Loading from 'common/loading';
import Tab from 'common/tab';
import Title from 'common/title';

/* ------ Local components ------ */
import CategorySet from './category-set';
import ChangeColors from './change-colors';
import DangerZone from './danger-zone';
import Details from './details';
import Domains from './domains';
import GeneratedCode from './generated-code';
import ProfilePicture from './profile-picture';
import Settings from './settings';

function ChatPlugin(props) {
  const {
    chatPlugin,
    onCategorySetUpdated,
    onColorsUpdated,
    onChatPluginUpdated,
    onDomainsUpdated,
    onHoursUpdated,
    onProfilePictureUpdated,
    view,
  } = props;

  const history = useHistory();

  const currentPath = usePathname();

  if (view === 'loading') {
    return <Loading />;
  }

  function renderAppearance() {
    return (
      <>
        <ProfilePicture
          chatPlugin={chatPlugin}
          onUpdated={onProfilePictureUpdated}
        />

        <Card className="mt-10">
          <Card.Title>Colors</Card.Title>

          <ChangeColors
            chatPlugin={chatPlugin}
            onColorsUpdated={onColorsUpdated}
          />
        </Card>
      </>
    );
  }

  function renderDetails() {
    return (
      <>
        <Details
          chatPlugin={chatPlugin}
          onChatPluginUpdated={onChatPluginUpdated}
        />

        <CategorySet
          categorySet={chatPlugin.category_set}
          chatPluginId={chatPlugin.id}
          onCategorySetUpdated={onCategorySetUpdated}
        />

        <Hours
          channel={chatPlugin}
          onHoursUpdated={onHoursUpdated}
        />

        <Domains
          chatPlugin={chatPlugin}
          onDomainsUpdated={onDomainsUpdated}
        />

        <GeneratedCode chatPluginId={chatPlugin.id} />

        <DangerZone chatPlugin={chatPlugin} />
      </>
    );
  }

  function renderSettings() {
    return (
      <>
        <Settings
          chatPlugin={chatPlugin}
          onChatPluginUpdated={onChatPluginUpdated}
        />
      </>
    );
  }

  return (
    <>
      <Title>{chatPlugin.name}</Title>
      <p className="text-gray-600 font-semibold text-sm">Chat plugin</p>
      <div className="flex border-b border-gray-400 mt-6 mb-6">
        <Tab
          onClick={() => history.push(`/chat-plugin/${chatPlugin.id}`)}
          selected={currentPath === `/chat-plugin/${chatPlugin.id}`}
        >
          Details
        </Tab>
        <Tab
          onClick={() => history.push(`/chat-plugin/${chatPlugin.id}/appearance`)}
          selected={currentPath === `/chat-plugin/${chatPlugin.id}/appearance`}
        >
          Appearance
        </Tab>
        <Tab
          onClick={() => history.push(`/chat-plugin/${chatPlugin.id}/settings`)}
          selected={currentPath === `/chat-plugin/${chatPlugin.id}/settings`}
        >
          Settings
        </Tab>
      </div>

      <Switch>
        <Route path="/chat-plugin/:id/settings" render={renderSettings} />
        <Route path="/chat-plugin/:id/appearance" render={renderAppearance} />
        <Route path="/chat-plugin/:id" render={renderDetails} />
      </Switch>
    </>
  );
}

export default ChatPlugin;
