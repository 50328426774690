/* ------ Module imports ------ */
import React, { useMemo, useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';
import useDidMount from 'helpers/hooks/use-did-mount';
import user from 'helpers/user';

/* ------ View import ------ */
import Form from './form.view';

function FormContainer(props) {
  const { onSuccess } = props;

  const [country, setCountry] = useState(null);
  const [pricingPlans, setPricingPlans] = useState(false);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  async function fetchPlans() {
    let plans = null;
    try {
      const { data } = (await api.get('/billing_plan')).data;
      plans = data;
    } catch (e) {
      // Silently ignore - `plans` will be null so will show an error
    }

    if (plans) {
      const planObj = {};
      plans.forEach(plan => {
        planObj[plan.currency] = plan;
      });
      setPricingPlans(planObj);
    } else {
      setError(true);
    }

    setLoading(false);
  }

  useDidMount(() => {
    fetchPlans();
  });

  const pricingPlan = useMemo(() => {
    if (!country || !pricingPlans) {
      return null;
    }

    let plan = null;
    if (country.value === 'New Zealand') {
      plan = pricingPlans.nzd;
    } else if (country.value === 'Australia') {
      plan = pricingPlans.aud;
    } else {
      plan = pricingPlans.usd;
    }

    return plan;
  }, [country, pricingPlans]);

  async function onSubmit() {
    if (!pricingPlan) {
      return;
    }

    setSubmitting(true);

    let stripeRes = null;
    try {
      stripeRes = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });
    } catch (err) {
      // Silently ignore - `stripeRes` will be null so will show an error
    }

    if (!stripeRes) {
      toast('Could not setup subscription. Something went wrong.');
      setSubmitting(false);
      return;
    }

    let success = false;
    try {
      await api.post('/subscription', { plan: pricingPlan.stripe_id, stripe_payment_method: stripeRes.paymentMethod.id });
      success = true;
    } catch (err) {
      // Silently ignore - success will be false so will show an error
    }

    setSubmitting(false);

    if (success) {
      user.refreshUser();
      onSuccess(true);
    } else {
      toast('Could not setup subscription. Something went wrong.');
    }
  }

  return (
    <Form
      country={country}
      error={error}
      loading={loading}
      onCountryChanged={setCountry}
      onSubmit={onSubmit}
      pricingPlan={pricingPlan}
      submitting={submitting}
    />
  );
}

export default FormContainer;
