/* ------- Module imports ------ */
import React, { useContext, useState } from 'react';

/* ------ Context ------ */
import ConversationContext from 'common/conversation/context';
import PlatformContext from 'components/platform/context';

/* ------ Helpers imports ------ */
import history from 'helpers/history';
import toast from 'helpers/toast';

/* ------ View imports ------ */
import Join from './join.view';

function JoinContainer() {
  const { conversation, onUpdateConversation } = useContext(ConversationContext);
  const { user } = useContext(PlatformContext);

  const [submitting, setSubmitting] = useState(false);

  async function onJoin() {
    setSubmitting(true);

    const users = conversation.users.map(u => u.id);
    users.push(user.id);

    let success = false;
    try {
      await onUpdateConversation('users', users);
      success = true;
    } catch (e) {
      // Silently ignore - `success` will be false so will show an error
    }

    if (success) {
      history.push(`/inbox/${conversation.id}`);
    } else {
      setSubmitting(false);
      toast('Could not add users. Something went wrong.');
    }
  }

  return (
    <Join
      onJoin={onJoin}
      submitting={submitting}
    />
  );
}

export default JoinContainer;
