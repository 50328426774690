/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import PlainButton from 'common/plain-button';

function Title(props) {
  const {
    action,
    children,
  } = props;

  return (
    <div className="flex items-center justify-between px-6 py-4 bg-white border-b border-gray-300 rounded-t-lg">
      <p className="font-medium text-gray-800 text">{children}</p>
      {action && (
        <div>
          <PlainButton
            href={action.href || null}
            onClick={action.onClick || null}
          >
            {action.label}
          </PlainButton>
        </div>
      )}
    </div>
  );
}

export default Title;
