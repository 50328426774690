/* ------ Module imports ------ */
import React, { useContext, useState } from 'react';

/* ------ Context ------ */
import PlatformContext from 'components/platform/context';

/* ------ Helpers ------ */
import api from 'helpers/api';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ View import ------ */
import ViewPage from './view-page.view';

function ViewPageContainer(props) {
  const { match } = props;

  const { user } = useContext(PlatformContext);

  const [view, setView] = useState('loading');
  const [page, setPage] = useState(null);

  async function fetchPage() {
    let loadedPage = null;
    try {
      const { data } = (await api.get(`/page/${match.params.id}?expand[]=added_by&expand[]=category_set&expand[]=hours`)).data;
      loadedPage = data;
    } catch (e) {
      // Silently ignore - page or categories will be null so will show an error
    }

    if (loadedPage) {
      setPage(loadedPage);

      setView('main');
    } else {
      setView('error');
    }
  }

  function onReauthenticated() {
    const updatedPage = page;
    updatedPage.added_by = user;
    setPage({ ...updatedPage });
  }

  function onCategorySetUpdated(categorySet) {
    const updatedPage = { ...page };
    updatedPage.category_set = categorySet;
    setPage(updatedPage);
  }

  function onHoursUpdated(hours) {
    const updatedPage = { ...page };
    updatedPage.hours = hours;
    setPage(updatedPage);
  }

  useDidMount(() => {
    fetchPage();
  });

  return (
    <ViewPage
      onCategorySetUpdated={onCategorySetUpdated}
      onHoursUpdated={onHoursUpdated}
      onReauthenticated={onReauthenticated}
      page={page}
      view={view}
    />
  );
}

export default ViewPageContainer;
