/* ------ Module imports ------ */
import React from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';
import user from 'helpers/user';

/* ------ View import ------ */
import Onboarding from './onboarding.view';

function OnboardingContainer(props) {
  const {
    onSkip,
    steps,
  } = props;

  async function onSkipOnboarding() {
    let success = false;
    try {
      await api.remove('/business_onboarding_step');
      success = true;
    } catch (e) {
      // Silently ignore - `success` will be false so will show an error
    }

    if (success) {
      await user.refreshUser();
      onSkip();
    } else {
      toast('Something went wrong skipping onboarding. Please try again.');
    }
  }

  return (
    <Onboarding
      steps={steps}
      onSkipOnboarding={onSkipOnboarding}
    />
  );
}

export default OnboardingContainer;
