/* ------ Module imports ------ */
import React from 'react';

function User(props) {
  const { data } = props;

  const {
    triggeredBy,
    type,
    users,
  } = data;

  function getTextForUsers() {
    if (users.length === 1) {
      const [user] = users;
      return `${user.first_name} ${user.last_name}`;
    }

    const allUsersExceptLast = users.slice(0, users.length - 1).map(u => `${u.first_name} ${u.last_name}`);
    const lastUser = users[users.length - 1];

    let text = allUsersExceptLast.join(', ');
    text += ` and ${lastUser.first_name} ${lastUser.last_name}`;

    return text;
  }

  let text = null;
  switch (type) {
    case 'user_added':
      text = `${triggeredBy.first_name} ${triggeredBy.last_name} added ${getTextForUsers()} to the conversation`;
      break;
    case 'user_removed':
      text = `${triggeredBy.first_name} ${triggeredBy.last_name} removed ${getTextForUsers(users)} from the conversation`;
      break;
    case 'user_left':
      text = `${getTextForUsers(users)} left the conversation`;
      break;
    default:
      // Ignore.
  }

  if (!text) {
    return null;
  }

  return (
    <div className="py-2">
      <p className="text-xs text-center text-gray-600 font-medium">{text}</p>
    </div>
  );
}

export default User;
