/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import Card from 'common/card';
import Input from 'common/input';
import PlainButton from 'common/plain-button';

/* ------ Local components ------ */
import HelpSection from './help-section';

function AddCategorySet(props) {
  const {
    adding,
    errors,
    isEmpty,
    name,
    onNameChanged,
    onSubmit,
    onToggleAdding,
    submitting,
  } = props;

  return (
    <Card>
      {!adding && !isEmpty && (
        <div className="px-6 py-4">
          <PlainButton onClick={onToggleAdding}>+ Add new category set</PlainButton>
        </div>
      )}

      {!adding && isEmpty && (
        <div className="pt-12 pb-10 text-center">
          <img
            alt=""
            className="w-12 mb-4 mx-auto"
            src="/assets/icons/box.svg"
          />
          <p className="text-gray-600 text-sm">You haven&apos;t added any category sets yet</p>
          <PlainButton onClick={onToggleAdding}>Add new category set</PlainButton>
        </div>
      )}

      {adding && (
        <>
          <Card.Title>Add category set</Card.Title>
          <form
            className="px-6 pt-4 pb-6"
            disabled={submitting}
            onSubmit={onSubmit}
          >
            <Input
              className="mb-6"
              disabled={submitting}
              error={errors.name}
              label="Category set name"
              onChange={onNameChanged}
              value={name}
            />

            <div className="flex items-center">
              <Button
                loading={submitting}
                type="submit"
              >
                Add
              </Button>
              <PlainButton
                className="ml-4"
                color="gray"
                disabled={submitting}
                onClick={onToggleAdding}
                type="button"
              >
                Cancel
              </PlainButton>
            </div>
          </form>
        </>
      )}

      <HelpSection />
    </Card>
  );
}

export default AddCategorySet;
