/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import Input from 'common/input';
import PlainButton from 'common/plain-button';
import TimezoneSelector from 'common/timezone-selector';

/* ------ Local imports ------ */
import Day from './day';

function Edit(props) {
  const {
    awayMessage,
    errors,
    hours,
    onAwayMessageChanged,
    onCancel,
    onChangeHour,
    onChangeTimezone,
    onToggleOpen,
    onSubmit,
    submitting,
    timezone,
  } = props;

  return (
    <form
      disabled={submitting}
      onSubmit={onSubmit}
    >
      <div className="p-6">
        <Day
          day="Monday"
          error={errors.monday}
          end={hours.mon_end}
          open={hours.mon_start !== null}
          start={hours.mon_start}
          onEndChanged={v => onChangeHour('mon_end', v)}
          onStartChanged={v => onChangeHour('mon_start', v)}
          onToggleOpen={() => onToggleOpen('mon')}
        />

        <Day
          day="Tuesday"
          error={errors.tuesday}
          end={hours.tue_end}
          open={hours.tue_start !== null}
          start={hours.tue_start}
          onEndChanged={v => onChangeHour('tue_end', v)}
          onStartChanged={v => onChangeHour('tue_start', v)}
          onToggleOpen={() => onToggleOpen('tue')}
        />

        <Day
          day="Wednesday"
          error={errors.wednesday}
          end={hours.wed_end}
          open={hours.wed_start !== null}
          start={hours.wed_start}
          onEndChanged={v => onChangeHour('wed_end', v)}
          onStartChanged={v => onChangeHour('wed_start', v)}
          onToggleOpen={() => onToggleOpen('wed')}
        />

        <Day
          day="Thursday"
          error={errors.thursday}
          end={hours.thu_end}
          open={hours.thu_start !== null}
          start={hours.thu_start}
          onEndChanged={v => onChangeHour('thu_end', v)}
          onStartChanged={v => onChangeHour('thu_start', v)}
          onToggleOpen={() => onToggleOpen('thu')}
        />

        <Day
          day="Friday"
          error={errors.friday}
          end={hours.fri_end}
          open={hours.fri_start !== null}
          start={hours.fri_start}
          onEndChanged={v => onChangeHour('fri_end', v)}
          onStartChanged={v => onChangeHour('fri_start', v)}
          onToggleOpen={() => onToggleOpen('fri')}
        />

        <Day
          day="Saturday"
          error={errors.saturday}
          end={hours.sat_end}
          open={hours.sat_start !== null}
          start={hours.sat_start}
          onEndChanged={v => onChangeHour('sat_end', v)}
          onStartChanged={v => onChangeHour('sat_start', v)}
          onToggleOpen={() => onToggleOpen('sat')}
        />

        <Day
          day="Sunday"
          error={errors.sunday}
          end={hours.sun_end}
          open={hours.sun_start !== null}
          start={hours.sun_start}
          onEndChanged={v => onChangeHour('sun_end', v)}
          onStartChanged={v => onChangeHour('sun_start', v)}
          onToggleOpen={() => onToggleOpen('sun')}
        />
      </div>

      <div className="p-6 pb-4 border-t border-gray-300">
        <p className="inline-block text-sm text-gray-800 font-medium mb-1">Timezone</p>
        <TimezoneSelector
          error={errors.timezone}
          value={timezone}
          onChange={onChangeTimezone}
        />

        <Input
          disabled={submitting}
          error={errors.awayMessage}
          label="Away message"
          onChange={onAwayMessageChanged}
          value={awayMessage}
        />
        <div className={`mt-1 text-right text-xs font-medium ${awayMessage.length > 128 ? 'text-red-500' : 'text-gray-600'}`}>{`${awayMessage.length} / 128`}</div>
      </div>

      <div className="px-6 py-4 border-t border-gray-300">
        <div className="flex items-center">
          <Button
            loading={submitting}
            type="submit"
          >
            Save
          </Button>

          <PlainButton
            className="ml-4"
            disabled={submitting}
            onClick={onCancel}
            type="button"
          >
            Cancel
          </PlainButton>
        </div>
      </div>
    </form>
  );
}

export default Edit;
