/* ------ Module imports ------ */
import React, { useState } from 'react';
import Select, { components } from 'react-select';

/* ------ Helpers ------ */
import history from 'helpers/history';

/* ------ Common imports ------ */
import Icon from 'common/icon';
import IconButton from 'common/icon-button';
import PlainButton from 'common/plain-button';

function SingleValue({ children, ...props }) {
  const { data } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.SingleValue {...props}>
      <div className="flex items-center">
        <span className="text-sm text-gray-800 font-medium">{data.label}</span>
      </div>
    </components.SingleValue>
  );
}

function Option({ children, ...props }) {
  const { data } = props;

  function renderUsedBy(inUseBy) {
    return (
      <div className="mt-1">
        {inUseBy.length > 0 && (
          <div className="flex">
            {inUseBy.map(channel => (
              <div className="flex items-center mr-4" key={channel.id}>
                <img
                  alt=""
                  className="w-3 mr-1"
                  src={`/assets/img/${channel.object === 'chat_plugin' ? 'web' : 'facebook'}.svg`}
                />
                <p className="text-gray-600 text-xs font-medium">{channel.name}</p>
              </div>
            ))}
          </div>
        )}

        {inUseBy.length === 0 && (
          <div className="flex items-center">
            <Icon className="text-sm text-orange-500" icon="warning" />
            <p className="text-gray-800 text-xs ml-2">Not used by any messaging channels</p>
          </div>
        )}
      </div>
    );
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.Option {...props}>
      <div className="px-2 py-1">
        <span className="text-sm text-gray-800 font-medium">{data.label}</span>
        {renderUsedBy(data.inUseBy)}
      </div>
    </components.Option>
  );
}

function NoOptionsMessage({ children, ...props }) {
  const { selectProps } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.NoOptionsMessage {...props}>
      <div className="text-center py-4">
        {!selectProps.error && (
          <>
            <p className="mb-2">No category sets found.</p>
            <PlainButton onClick={() => history.push('/category-sets')}>Create new category set</PlainButton>
          </>
        )}
        {selectProps.error && (
          <p className="text-orange-500">Could not load category sets. Please try again.</p>
        )}
      </div>
    </components.NoOptionsMessage>
  );
}

function Selector(props) {
  const {
    data,
    error,
    loading,
    onCancel,
    onChange,
    onConfirm,
    onFocus,
    submitting,
    value,
  } = props;

  const [menuOpen, setMenuOpen] = useState(true);

  let options = [];
  if (data) {
    options = data.map(categorySet => {
      const categorySetObject = {
        value: categorySet.id,
        label: categorySet.name,
        inUseBy: categorySet.in_use_by,
      };

      return categorySetObject;
    });
  }

  return (
    <div className="p-4 flex">
      <div className="flex-1">
        <Select
          autoFocus
          classNamePrefix="select"
          components={{ SingleValue, Option, NoOptionsMessage }}
          error={error}
          isDisabled={submitting}
          isLoading={loading}
          menuIsOpen={menuOpen}
          options={options}
          onChange={onChange}
          onFocus={onFocus}
          onMenuOpen={() => setMenuOpen(true)}
          onMenuClose={() => setMenuOpen(false)}
          placeholder="Choose a category set"
          value={value}
        />
      </div>
      <IconButton
        color="green"
        icon="check"
        loading={submitting}
        onClick={onConfirm}
      />
      <IconButton
        color="gray"
        disabled={submitting}
        icon="cross"
        onClick={onCancel}
      />
    </div>
  );
}

export default Selector;
