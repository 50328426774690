/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import LoadingSpinner from 'common/loading-spinner';

function ArchiveAction(props) {
  const {
    onToggleStatus,
    status,
    submitting,
  } = props;

  return (
    <button
      className="focus:outline-none w-full px-4 py-2 text-xs text-left font-semibold text-blue-500 uppercase"
      disabled={submitting}
      onClick={onToggleStatus}
      type="button"
    >
      {submitting && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <LoadingSpinner small />
        </div>
      )}
      <div className={submitting ? 'invisible' : ''}>
        {status === 'active' && 'Archive conversation'}
        {status === 'archived' && 'Make conversation active'}
      </div>
    </button>
  );
}

export default ArchiveAction;
