/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Common imports ------ */
import SimpleLayout from 'common/layout/simple';

/* ------ Local components ------ */
import Businesses from './businesses';
import Email from './email';
import ForgottenPassword from './forgotten-password';
import User from './user';

function Login() {
  const [email, setEmail] = useState(null);
  const [business, setBusiness] = useState(null);
  const [forgottenPassword, setForgottenPassword] = useState(false);

  function renderHeader() {
    if (forgottenPassword) {
      return null;
    }

    let text = null;
    if (!email) {
      text = 'Enter your email address';
    } else if (!business) {
      text = 'Select which business you want to login to';
    } else {
      text = `Enter your password for ${business.name}`;
    }

    return (
      <div>
        <p className="font-medium text-gray-800 mb-1 text-2xl font-heading">Login</p>
        <p className="text-gray-800 mb-10">{text}</p>
      </div>
    );
  }

  function renderInner() {
    if (forgottenPassword) {
      return (
        <ForgottenPassword
          business={business}
          email={email}
          onCancel={() => setForgottenPassword(false)}
        />
      );
    }

    if (!email) {
      return <Email onSubmit={setEmail} />;
    }

    if (!business) {
      return (
        <Businesses
          email={email}
          onBack={() => setEmail(null)}
          onSubmit={setBusiness}
        />
      );
    }

    return (
      <User
        business={business}
        email={email}
        onBack={() => setBusiness(null)}
        onForgotPassword={() => setForgottenPassword(true)}
      />
    );
  }

  return (
    <SimpleLayout>
      {renderHeader()}
      {renderInner()}
    </SimpleLayout>
  );
}

export default Login;
