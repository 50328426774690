/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Common imports ------ */
import Card from 'common/card';
import Icon from 'common/icon';

/* ------ View imports ------ */
import Edit from './edit';
import Delete from './delete';
import Popover from './popover';
import View from './view';

function Hours(props) {
  const {
    channel,
    onHoursUpdated,
  } = props;

  const [view, setView] = useState('view');
  const [popoverVisible, setPopoverVisible] = useState(false);

  function onUpdated(updatedHours) {
    onHoursUpdated(updatedHours);
    setView('view');
  }

  function renderInner() {
    if (view === 'edit') {
      return (
        <Edit
          channel={channel}
          onCancel={() => setView('view')}
          onUpdated={onUpdated}
        />
      );
    }

    if (view === 'delete') {
      return (
        <Delete
          channel={channel}
          onCancel={() => setView('view')}
          onDeleted={() => onUpdated(null)}
        />
      );
    }

    return (
      <View
        channel={channel}
        onAdd={() => setView('edit')}
      />
    );
  }

  function onChangeView(newView) {
    setPopoverVisible(false);
    setView(newView);
  }

  return (
    <Card className="mt-10">
      <div className="flex items-center justify-between px-6 py-4 bg-white border-b border-gray-300 rounded-t-lg">
        <p className="font-medium text-gray-800 text">Opening hours</p>

        {channel.hours && view === 'view' && (
          <div>
            <button
              className="flex items-center ml-4 border border-gray-300 px-3 py-2 hover:shadow rounded-lg focus:outline-none"
              onClick={() => setPopoverVisible(true)}
              type="button"
            >
              <Icon
                className="text-gray-800 text-sm"
                icon="more"
              />
            </button>

            {popoverVisible && (
              <div className="relative">
                <Popover
                  channel={channel}
                  onClose={() => setPopoverVisible(false)}
                  onChangeView={onChangeView}
                />
              </div>
            )}
          </div>
        )}
      </div>

      {renderInner()}
    </Card>
  );
}

export default Hours;
