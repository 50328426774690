/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';
import user from 'helpers/user';

/* ------ View import ------ */
import Onboarding from './onboarding.view';

function OnboardingContainer() {
  const [loading, setLoading] = useState(false);
  const [web, setWeb] = useState(false);
  const [fb, setFb] = useState(false);

  async function onContinue() {
    setLoading(true);

    const types = [];
    if (web) {
      types.push('web');
    }
    if (fb) {
      types.push('fb');
    }

    try {
      await api.post('/business_onboarding_step', { types });
    } catch (e) {
      toast('Something went wrong. Please try again.');
    }

    await user.refreshUser();
  }

  return (
    <Onboarding
      fb={fb}
      loading={loading}
      onContinue={onContinue}
      onToggleFb={() => setFb(!fb)}
      onToggleWeb={() => setWeb(!web)}
      web={web}
    />
  );
}

export default OnboardingContainer;
