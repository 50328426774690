/* ------ Module imports ------ */
import React, { useContext, useState } from 'react';

/* ------ Context ------ */
import ConversationContext from 'common/conversation/context';

/* ------ Helpers ------ */
import toast from 'helpers/toast';

/* ------ View import ------ */
import ArchiveAction from './archive-action.view';

function ArchiveActionContainer() {
  const {
    conversation,
    onUpdateConversation,
  } = useContext(ConversationContext);

  const [submitting, setSubmitting] = useState(false);

  async function onToggleStatus() {
    setSubmitting(true);

    const updatedStatus = conversation.status === 'active'
      ? 'archived'
      : 'active';

    let success = false;
    try {
      await onUpdateConversation('status', updatedStatus);
      success = true;
    } catch (e) {
      // Silently ignore - `success` will be false so will show an error
    }

    setSubmitting(false);

    if (success) {
      if (updatedStatus === 'archived') {
        toast('Conversation archived');
      } else {
        toast('Conversation made active');
      }
    } else if (updatedStatus === 'archived') {
      toast('Could not archive conversation. Something went wrong.');
    } else {
      toast('Conversation could not be made active. Something went wrong.');
    }
  }

  return (
    <ArchiveAction
      onToggleStatus={onToggleStatus}
      status={conversation.status}
      submitting={submitting}
    />
  );
}

export default ArchiveActionContainer;
