/* ------ Module imports ------ */
import React from 'react';

function Settings(props) {
  const {
    onArchive,
    onEdit,
  } = props;

  return (
    <>
      <button
        className="my-4 flex items-center text-sm font-medium text-gray-800"
        onClick={onEdit}
        type="button"
      >
        <div className="w-8 h-8 rounded-lg bg-gray-200 flex items-center justify-center mr-2">
          <img alt="" className="w-4" src="/assets/icons/edit.svg" />
        </div>
        Edit
      </button>
      <button
        className="flex items-center text-sm font-medium text-gray-800"
        onClick={onArchive}
        type="button"
      >
        <div className="w-8 h-8 rounded-lg bg-gray-200 flex items-center justify-center mr-2">
          <img alt="" className="w-4" src="/assets/icons/archive.svg" />
        </div>
        Archive
      </button>
    </>
  );
}

export default Settings;
