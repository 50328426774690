/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import Input from 'common/input';
import PlainButton from 'common/plain-button';

function ChangeEmailCard(props) {
  const {
    email,
    error,
    onChange,
    onClose,
    onSubmit,
    saving,
  } = props;

  return (
    <div className="mt-10 bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="px-6 py-4 border-b border-gray-300">
        <p className="font-medium text-gray-800">Change email</p>
      </div>
      <div className="p-6 pt-4">
        <Input
          className="mb-6"
          disabled={saving}
          error={error}
          label="Enter your new email address"
          onChange={onChange}
          type="email"
          value={email}
        />

        <div className="flex items-center">
          <Button
            loading={saving}
            onClick={onSubmit}
          >
            UPDATE
          </Button>

          <PlainButton
            className="ml-6"
            disabled={saving}
            color="gray"
            onClick={onClose}
          >
            CANCEL
          </PlainButton>
        </div>
      </div>
    </div>
  );
}

export default ChangeEmailCard;
