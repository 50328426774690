/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Common imports ------ */
import Icon from 'common/icon';

function Actions(props) {
  const {
    onCancel,
    onChangeCard,
  } = props;

  const [showMenu, setShowMenu] = useState(false);

  function onCloseAndCall(fn) {
    setShowMenu(false);
    fn();
  }

  return (
    <div>
      <button
        className="focus:outline-none hover:shadow px-3 py-1 rounded-lg"
        onClick={() => setShowMenu(!showMenu)}
        type="button"
      >
        <Icon icon="more" />
      </button>

      {showMenu && (
        <div className="relative">
          <div className="popover popover-right absolute right-0 mt-2 z-10 bg-white shadow-xl border border-gray-300 rounded-lg w-64 text-left">
            <div className="py-2">
              <button
                className="focus:outline-none text-gray-800 font-semibold text-left text-sm w-full block px-6 py-1 hover:bg-gray-100"
                onClick={() => onCloseAndCall(onChangeCard)}
                type="button"
              >
                Change card details
              </button>
              <button
                className="focus:outline-none font-semibold text-red-600 text-left text-sm w-full block px-6 py-1 hover:bg-gray-100"
                onClick={() => onCloseAndCall(onCancel)}
                type="button"
              >
                Cancel subscription
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Actions;
