/* ------ Module imports ------ */
import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Link } from 'react-router-dom';

/* ------ Common imports ------ */
import Icon from 'common/icon';
import Timeline from 'common/timeline';

/* ------ Context ------ */
import PlatformContext from 'components/platform/context';
import ConversationContext from './context';

/* ------ Local components ------ */
import Composer from './composer';
import Details from './details';
import Join from './join';
import Options from './options';
import Users from './users';

function Conversation(props) {
  const {
    baseUrl,
    conversation,
    onFetchCategories,
    onFetchUsers,
    onSendMessage,
    onUpdateConversation,
    onUploadFile,
    timelineItems,
  } = props;

  const { user } = useContext(PlatformContext);

  const scroll = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (scroll.current !== null) {
        scroll.current.scrollTop = scroll.current.scrollHeight;
      }
    });
  }, [scroll, timelineItems]);

  const isEmpty = useMemo(() => {
    if (!timelineItems) {
      return true;
    }

    return timelineItems
      .filter(item => item.type === 'message')
      .length === 0;
  }, [timelineItems]);

  const isUserJoined = conversation.users.filter(u => u.id === user.id).length > 0;

  function renderEmptyConversation() {
    return (
      <div className="text-center mt-16 text-gray-700">
        <Icon className="text-xl" icon="warning" />
        <p className="text-sm mt-2">This conversation has no messages yet</p>
      </div>
    );
  }

  const context = {
    conversation,
    onFetchCategories,
    onFetchUsers,
    onSendMessage,
    onUpdateConversation,
    onUploadFile,
  };

  return (
    <ConversationContext.Provider value={context}>
      <div className="bg-white absolute top-0 left-0 w-full h-full flex flex-col">
        <div className="relative">
          <div className="md:hidden px-4 py-4 border-b border-gray-300">
            <Link className="flex items-center" to={baseUrl}>
              <Icon className="text-gray-600" icon="left-arrow" />
              <span className="ml-2 text-xs font-semibold text-gray-600 uppercase">Back</span>
            </Link>
          </div>

          <div className="relative w-full h-20">
            <div className="absolute left-0 bottom-0 w-full flex p-4 border-b border-gray-300 justify-between items-center">
              <div className="flex items-center">
                <div
                  className="h-10 w-10 mr-4 rounded-lg bg-cover bg-center"
                  style={{ backgroundImage: `url(${conversation.profile_picture})` }}
                />

                <div>
                  <p className="text-sm text-green-500 font-semibold">{conversation.name}</p>
                  {(conversation.page || conversation.chat_plugin) && (
                    <div className="flex items-center mt-1">
                      {(conversation.type === 'facebook' || conversation.type === 'web') && (
                        <img
                          alt={conversation.type}
                          className="w-4 mr-2"
                          src={`/assets/img/${conversation.type}.svg`}
                        />
                      )}
                      {conversation.page && <p className="text-xs text-gray-800">{conversation.page.name}</p>}
                      {conversation.chat_plugin && <p className="text-xs text-gray-800">{conversation.chat_plugin.name}</p>}
                    </div>
                  )}
                </div>
              </div>

              <div className="flex items-center">
                <div className="hidden md:block">
                  <Users />
                </div>
                <Options />
              </div>
            </div>
          </div>

          <div className="absolute bottom-0 left-0 -mb-4 transform translate-y-full">
            <div className="px-4 inline-block">
              <Details />
            </div>
          </div>
        </div>

        <div ref={scroll} className="overflow-auto flex-1">
          <div className="p-4 pt-20">
            {isEmpty && renderEmptyConversation()}
            {!isEmpty && (
              <Timeline
                conversation={conversation}
                page={conversation.page}
                timelineItems={timelineItems}
              />
            )}
          </div>
        </div>

        {(conversation.status === 'active' && isUserJoined) && (
          <Composer />
        )}
        {(conversation.is_unassigned && !isUserJoined) && (
          <Join />
        )}
      </div>
    </ConversationContext.Provider>
  );
}

export default Conversation;
