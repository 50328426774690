/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Common imports ------ */
import Icon from 'common/icon';

function HelpSection() {
  const [showHelp, setShowHelp] = useState(false);

  return (
    <div className={`border-t border-gray-300 px-6 ${showHelp ? 'py-6' : 'py-4'}`}>
      {showHelp && (
        <div className="mb-6">
          <p className="font-semibold text-sm mb-2 text-gray-800">What are category sets?</p>
          <p className="text-sm text-gray-700">
            When people use the chat plugin on your website they will get to choose a category
            that they want to chat about, and their conversation will only be visible to your
            users who have been assigned to that category.
          </p>
          <p className="text-sm text-gray-700 mt-2">
            A category set contains the categories that people will be able to choose from.
          </p>
        </div>
      )}

      <button
        className="flex items-center text-gray-800 font-semibold text-xs"
        onClick={() => setShowHelp(!showHelp)}
        type="button"
      >
        <Icon
          fontSize="8px"
          icon={showHelp ? 'up-chevron' : 'down-chevron'}
        />

        <span className="ml-2">{showHelp ? 'Hide help' : 'Learn more about category sets'}</span>
      </button>
    </div>
  );
}

export default HelpSection;
