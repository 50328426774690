/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import Reauthenticate from './reauthenticate.view';

function ReauthenticateContainer(props) {
  const {
    onReauthenticated,
    page,
  } = props;

  const [view, setView] = useState('main');

  const [facebookLoading, setFacebookLoading] = useState(false);

  async function facebookCallback(res) {
    setFacebookLoading(true);

    let success = false;
    try {
      await api.post(`/page/${page.id}/reauthenticate`, { fb_access_token: res.accessToken });
      success = true;
    } catch (e) {
      toast('Something went wrong. Please try again.');
    }

    setFacebookLoading(false);

    if (success) {
      setView('success');
      onReauthenticated();
    }
  }

  return (
    <Reauthenticate
      facebookCallback={facebookCallback}
      facebookLoading={facebookLoading}
      onButtonClicked={() => setView('reauthenticate')}
      view={view}
    />
  );
}

export default ReauthenticateContainer;
