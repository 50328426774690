/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ View import ------ */
import Channels from './channels.view';

function ChannelsContainer() {
  const [view, setView] = useState('loading');
  const [pages, setPages] = useState(null);
  const [chatPlugins, setChatPlugins] = useState(null);

  async function fetchPages() {
    const { data } = (await api.get('/page')).data;
    return data;
  }

  async function fetchChatPlugins() {
    const { data } = (await api.get('/chat_plugin?expand[]=page')).data;
    return data;
  }

  async function fetchData() {
    let loadedPages = null;
    let loadedChatPlugins = null;

    try {
      [loadedPages, loadedChatPlugins] = await Promise.all([
        fetchPages(),
        fetchChatPlugins(),
      ]);
    } catch (e) {
      // Silently ignore - will be null so will show error
    }

    if (loadedPages && loadedChatPlugins) {
      setPages(loadedPages);
      setChatPlugins(loadedChatPlugins);
      setView('main');
    } else {
      setView('error');
    }
  }

  useDidMount(() => {
    fetchData();
  });

  return (
    <Channels
      chatPlugins={chatPlugins}
      pages={pages}
      view={view}
    />
  );
}

export default ChannelsContainer;
