/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import CategorySelector from 'common/category-selector';
import Input from 'common/input';

function NewAd(props) {
  const {
    category,
    errors,
    id,
    name,
    onCancel,
    onCategoryChanged,
    onIdChanged,
    onNameChanged,
    onSubmit,
    page,
    submitting,
  } = props;

  return (
    <div className="px-6 py-4 bg-gray-100 border-b border-gray-300">
      <p className="text-gray-600 text-xs uppercase font-medium mb-2">Add a new Facebook Ad</p>

      <form
        disabled={submitting}
        onSubmit={onSubmit}
      >
        <Input
          className="mb-4"
          disabled={submitting}
          error={errors.name}
          label="Enter a name to easily identify this ad"
          onChange={onNameChanged}
          value={name}
        />
        <Input
          className="mb-4"
          disabled={submitting}
          error={errors.id}
          label="Enter the id of the the Facebook ad you want to use"
          onChange={onIdChanged}
          value={id}
        />
        <div className="mb-6">
          <span className="inline-block text-sm text-gray-800 font-medium mb-1">Choose which category to assign messages from this ad to</span>
          <CategorySelector
            disabled={submitting}
            onChange={onCategoryChanged}
            page={page}
            value={category}
          />
        </div>
        <Button
          loading={submitting}
          type="submit"
        >
          Confirm
        </Button>
        <button
          className="text-sm uppercase font-semibold text-gray-600 ml-6"
          disabled={submitting}
          onClick={onCancel}
          type="button"
        >
          Cancel
        </button>
      </form>
    </div>
  );
}

export default NewAd;
