/* ------ Module imports ------ */
import React from 'react';
import { Link } from 'react-router-dom';

/* ------ Common imports ------ */
import Card from 'common/card';
import Icon from 'common/icon';

function ExistingPages(props) {
  const { pages } = props;

  function renderPage(page) {
    return (
      <Link
        className="flex items-center justify-between w-full px-6 py-4 border-b border-gray-300 last:border-b-0 hover:bg-gray-100"
        key={page.id}
        to={`/pages/${page.id}`}
      >
        <div className="flex items-center">
          <div className="rounded-lg rounded-lg w-10 h-10 bg-center bg-cover" style={{ backgroundImage: `url(${page.profile_picture})` }} />
          <div className="ml-4">
            <p className="text-sm text-gray-800">{page.name}</p>
            <p className="text-xs text-gray-600">{`Added by ${page.added_by.first_name} ${page.added_by.last_name}`}</p>
          </div>
        </div>

        <div>
          <Icon
            className="text-gray-600"
            icon="right-chevron"
          />
        </div>
      </Link>
    );
  }

  return (
    <Card className="mt-10 overflow-hidden">
      <Card.Title>Pages already connected</Card.Title>

      {pages.map(renderPage)}
      {pages.length === 0 && (
        <p className="px-6 py-10 text-gray-800 text-sm text-center">No pages have been set up yet</p>
      )}
    </Card>
  );
}

export default ExistingPages;
