/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Card from 'common/card';
import DeleteCategorySet from 'common/delete-category-set';
import Icon from 'common/icon';
import RenameCategorySet from 'common/rename-category-set';

/* ------ Local imports ------ */
import AddCategory from './add-category';
import Category from './category';
import Popover from './popover';

function Main(props) {
  const {
    categorySet,
    categories,
    deleting,
    onCancelDelete,
    onCancelRename,
    onCategoryAdded,
    onCategoryDeleted,
    onCategorySetDeleted,
    onCategoryUpdated,
    onNameChanged,
    onReorder,
    onSetDeleting,
    onSetRenaming,
    onTogglePopover,
    popoverVisible,
    renaming,
  } = props;

  function renderUsedBy(inUseBy) {
    return (
      <div className="mt-4">
        <p className="text-gray-600 text-xs uppercase font-medium mb-2">Used by</p>
        {inUseBy.length > 0 && (
          <div className="flex">
            {inUseBy.map(channel => (
              <div className="flex items-center mr-4" key={channel.id}>
                <img
                  alt=""
                  className="w-3 mr-1"
                  src={`/assets/img/${channel.object === 'chat_plugin' ? 'web' : 'facebook'}.svg`}
                />
                <p className="text-gray-600 text-xs font-medium">{channel.name}</p>
              </div>
            ))}
          </div>
        )}

        {inUseBy.length === 0 && (
          <div className="flex items-center">
            <Icon className="text-orange-500" icon="warning" />
            <p className="text-gray-800 text-sm ml-2">Not used by any messaging channels</p>
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      <div className="mb-10">
        {renaming && (
          <RenameCategorySet
            categorySet={categorySet}
            onCancel={onCancelRename}
            onNameChanged={onNameChanged}
          />
        )}
        {!renaming && (
          <div className="flex items-center justify-between">
            <p className="font-heading text-2xl font-medium text-gray-800">{categorySet.name}</p>

            <div>
              <button
                className="flex items-center ml-2 border border-gray-300 bg-white hover:shadow px-3 py-2 rounded-lg focus:outline-none"
                onClick={onTogglePopover}
                type="button"
              >
                <Icon
                  className="text-gray-800 text-sm"
                  icon="more"
                />
              </button>

              {popoverVisible && (
                <div className="relative">
                  <Popover
                    onClose={onTogglePopover}
                    onSetDeleting={onSetDeleting}
                    onSetRenaming={onSetRenaming}
                    onToggleReorderView={onReorder}
                    showReorder={categories.length > 0}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        {categorySet.in_use_by && renderUsedBy(categorySet.in_use_by)}
      </div>

      {deleting && (
        <Card className="p-6 mb-6">
          <DeleteCategorySet
            categorySet={categorySet}
            onCancelDelete={onCancelDelete}
            onDeleted={onCategorySetDeleted}
          />
        </Card>
      )}

      <div>
        {categories.length > 0 && categories.map(category => (
          <Category
            category={category}
            categorySetId={categorySet.id}
            depth={1}
            key={category.id}
            onCategoryDeleted={onCategoryDeleted}
            onCategoryUpdated={onCategoryUpdated}
          />
        ))}
      </div>

      <AddCategory
        categorySetId={categorySet.id}
        onCategoryAdded={onCategoryAdded}
      />
    </>
  );
}

export default Main;
