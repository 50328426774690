/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import ActionSheet from 'common/action-sheet';

/* ------ Local(ish) components ------ */
import ArchiveAction from 'common/conversation/options/archive-action';
import Users from 'common/conversation/users';

function MobileActionSheet(props) {
  const { open, onClose } = props;

  if (!open) {
    return null;
  }

  return (
    <div className="md:hidden">
      <ActionSheet onClose={onClose}>
        <div className="p-6">
          <div>
            <p className="text-xs text-gray-800 font-semibold">Users</p>
            <Users />
          </div>

          <div className="mt-6">
            <p className="text-xs text-gray-800 font-semibold">Actions</p>
            <div className="-mx-4">
              <ArchiveAction />
            </div>
          </div>
        </div>
      </ActionSheet>
    </div>
  );
}

export default MobileActionSheet;
