/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Icon from 'common/icon';

/* ------ Local components ------ */
import Settings from './settings';

function User(props) {
  const {
    expanded,
    isCurrentUser,
    isLastChild,
    onToggleExpand,
    onUserDeleted,
    onUserUpdated,
    totalUsers,
    user,
  } = props;

  function renderExpandedView() {
    return (
      <div className="pl-6">

        <Settings
          isCurrentUser={isCurrentUser}
          onDeleted={onUserDeleted}
          onUserUpdated={onUserUpdated}
          totalUsers={totalUsers}
          user={user}
        />
      </div>
    );
  }

  function buildButtonClassname() {
    let className = 'focus:outline-none hover:bg-gray-100 flex w-full justify-between items-center px-6 py-4 text-left';

    if (isLastChild && !expanded) {
      className += ' rounded-b-lg';
    }

    return className;
  }

  return (
    <div className="border-b border-gray-300 last:border-b-0">
      <div className="flex items-center justify-between w-full">
        <button
          className={buildButtonClassname()}
          onClick={onToggleExpand}
          type="button"
        >
          <div className="flex items-center">
            <img
              alt=""
              className="h-10 w-10 mr-4 rounded-lg"
              src={user.profile_picture}
            />

            <div className="flex items-center">
              <p className="font-semibold text-gray-800">
                {user.object === 'user' && `${user.first_name} ${user.last_name}`}
                {user.object === 'invite' && user.email}
              </p>

              {isCurrentUser && (
                <p className="bg-green-200 rounded-full ml-4 text-green-700 font-semibold text-xs px-3 py-px">YOU</p>
              )}
            </div>
          </div>
          <div>
            <Icon icon={expanded ? 'up-chevron' : 'down-chevron'} />
          </div>
        </button>
      </div>

      {expanded && renderExpandedView()}
    </div>
  );
}

export default User;
