/* ------ Module imports ------ */
import React from 'react';

/* ------ Local components ------ */
import Dropdown from './dropdown';

function Picker(props) {
  const {
    color,
    disabled,
    label,
    onChange,
    onClose,
    onOpen,
    pickerVisible,
  } = props;

  return (
    <div className="relative">
      <div className="flex items-center">
        <button
          aria-label="Change color"
          className="w-12 h-8 border border-gray-300 rounded-lg shadow"
          disabled={disabled}
          onClick={onOpen}
          style={{ backgroundColor: color }}
          type="button"
        />
        <p className="text-sm text-gray-800 font-medium ml-4">{label}</p>
      </div>

      {(pickerVisible && !disabled) && (
        <Dropdown
          color={color}
          onChange={onChange}
          onClose={onClose}
        />
      )}
    </div>
  );
}

export default Picker;
