/* ------ Module imports ------ */
import React, { useContext, useState } from 'react';

/* ------ Helpers ------ */
import toast from 'helpers/toast';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ Context ------ */
import ConversationContext from 'common/conversation/context';

/* ------ View import ------ */
import AddUser from './add-user.view';

function AddUserContainer(props) {
  const { onCancel } = props;

  const {
    conversation,
    onFetchUsers,
    onUpdateConversation,
  } = useContext(ConversationContext);

  const existingUsers = conversation.users;

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);

  const [users, setUsers] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);

  async function fetchUsers() {
    let loadedUsers = null;
    try {
      loadedUsers = await onFetchUsers();
    } catch (e) {
      // Silently ignore - `loadedUsers` will be null so will show an error
    }

    setLoading(false);

    if (loadedUsers) {
      /* ------ Filter out existing users ------ */
      const existingUserObj = {};
      existingUsers.forEach(u => {
        existingUserObj[u.id] = true;
      });

      setUsers(loadedUsers.filter(u => !existingUserObj[u.id]));
    } else {
      setError(true);
    }
  }

  useDidMount(() => {
    fetchUsers();
  });

  async function onSubmit() {
    setSubmitting(true);

    const selectedUserIds = selectedUsers.map(u => u.value);
    const updatedUsers = conversation.users.map(u => u.id).concat(selectedUserIds);

    let success = false;
    try {
      await onUpdateConversation('users', updatedUsers);
      success = true;
    } catch (e) {
      // Silently ignore - `success` will be false so will show an error
    }

    setSubmitting(false);

    if (success) {
      onCancel();
    } else {
      toast('Could not add users. Something went wrong.');
    }
  }

  return (
    <AddUser
      error={error}
      loading={loading}
      onCancel={onCancel}
      onChange={setSelectedUsers}
      onSubmit={onSubmit}
      selectedUsers={selectedUsers}
      submitting={submitting}
      users={users}
    />
  );
}

export default AddUserContainer;
