/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Local components ------ */
import Archive from './archive';
import Edit from './edit';
import Settings from './settings.view';

function SettingsContainer(props) {
  const {
    ad,
    onArchived,
    onUpdated,
    page,
  } = props;

  const [view, setView] = useState('settings');

  function onAdUpdated(updatedAd) {
    onUpdated(updatedAd);
    setView('settings');
  }

  function renderArchive() {
    return (
      <Archive
        ad={ad}
        onArchived={onArchived}
        onCancel={() => setView('settings')}
      />
    );
  }

  function renderEdit() {
    return (
      <Edit
        ad={ad}
        onCancel={() => setView('settings')}
        onUpdated={onAdUpdated}
        page={page}
      />
    );
  }

  function renderSettings() {
    return (
      <Settings
        onArchive={() => setView('archive')}
        onEdit={() => setView('edit')}
      />
    );
  }

  let inner = null;
  let title = null;
  switch (view) {
    case 'archive':
      inner = renderArchive();
      title = 'Archive ad';
      break;
    case 'edit':
      inner = renderEdit();
      title = 'Edit ad';
      break;
    default:
      inner = renderSettings();
      title = 'Settings';
  }

  return (
    <div className="border-t border-gray-300 py-6 pr-6">
      <p className="text-gray-600 text-xs uppercase font-medium">{title}</p>
      {inner}
    </div>
  );
}

export default SettingsContainer;
