/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Icon from 'common/icon';

function User(props) {
  const {
    data,
    isAdmin,
    isMe,
    onRemove,
    removing,
  } = props;

  return (
    <div
      className="mt-2 flex items-center justify-between"
      key={data.id}
    >
      <div className="flex items-center">
        <div
          className="bg-cover bg-center rounded-full w-6 h-6"
          key={data.id}
          style={{ backgroundImage: `url(${data.profile_picture})` }}
        />
        <p className="text-xs font-medium text-gray-800 text-sm ml-2">{`${data.first_name} ${data.last_name}`}</p>
      </div>

      {(isAdmin || isMe) && (
        <div>
          <button
            className="w-6 h-6 flex items-center justify-center rounded-lg hover:shadow focus:outline-none disabled:cursor-not-allowed"
            disabled={removing}
            onClick={onRemove}
            type="button"
          >
            <Icon className="text-gray-600" fontSize="8px" icon="cross" />
          </button>
        </div>
      )}
    </div>
  );
}

export default User;
