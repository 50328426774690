/* ------- Module imports ------ */
import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';

/* ------ Common imports ------ */
import Loading from 'common/loading';

/* ------ Local components ------ */
import Attachment from './attachment';

function Composer(props) {
  const {
    attachment,
    onAddMessage,
    onChangeValue,
    onRemoveAttachment,
    onUploadAttachment,
    submitting,
    text,
    uploading,
  } = props;

  function keyListener(e) {
    if (!uploading && !submitting) {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        onAddMessage();
      }
    }
  }

  function renderActions() {
    return (
      <>
        {(!uploading && !attachment) && (
          <button
            className="bg-none border-none focus:outline-none py-2 pl-4 pr-3"
            onClick={onUploadAttachment}
            type="button"
          >
            <img
              alt=""
              className="w-5 cursor-pointer"
              src="/assets/icons/images.svg"
            />
          </button>
        )}

        {(submitting || uploading) && (
          <Loading small />
        )}
      </>
    );
  }

  return (
    <div>
      <div className="flex items-center border-t border-gray-300">
        <TextareaAutosize
          className="w-full px-4 py-3 text-sm outline-none flex-1 resize-none overflow-hidden rounded-b-lg"
          disabled={submitting || uploading}
          onChange={e => onChangeValue(e.target.value)}
          onKeyDown={keyListener}
          maxRows={3}
          placeholder="Write a message..."
          value={text}
        />

        {renderActions()}
      </div>

      {attachment && (
        <Attachment
          attachment={attachment}
          canRemove={!submitting}
          onRemoveAttachment={onRemoveAttachment}
        />
      )}
    </div>
  );
}

export default Composer;
