/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ View import ------ */
import CategorySelector from './category-selector.view';

function CategorySelectorContainer(props) {
  const {
    disabled,
    onChange,
    page,
    value,
  } = props;

  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState(null);

  async function fetchCategories() {
    let loadedCategories = null;
    try {
      const { data } = (await api.get(`/category?category_set=${page.category_set.id}`)).data;
      loadedCategories = data;
    } catch (e) {
      // Silently ignore - `loadedCategories` will stay null so will show an error
    }

    if (loadedCategories) {
      setCategories(loadedCategories);
    } else {
      setCategories([]);
    }

    setLoading(false);
  }

  useDidMount(() => {
    fetchCategories();
  });

  return (
    <CategorySelector
      categories={categories}
      disabled={disabled}
      loading={loading}
      onChange={onChange}
      value={value}
    />
  );
}

export default CategorySelectorContainer;
