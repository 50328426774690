/* ------ Module imports ------ */
import React, { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

/* ------ Config ------ */
import config from 'config';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import ChangeCard from './change-card.view';

const stripe = loadStripe(config.stripe.apiKey);

function ChangeCardContainer(props) {
  const {
    billingStatus,
    onCancel,
    onSuccess,
  } = props;

  const [saving, setSaving] = useState(false);

  async function onSave(card, stripeInstance) {
    setSaving(true);

    let stripeRes = null;
    try {
      stripeRes = await stripeInstance.createPaymentMethod({
        type: 'card',
        card,
      });
    } catch (err) {
      // Silently ignore - `stripeRes` will be null so will show an error
    }

    if (!stripeRes) {
      toast('Could not update payment details. Something went wrong.');
      setSaving(false);
      return;
    }

    let success = false;
    try {
      if (billingStatus === 'cancelled') {
        await api.post('/subscription', { stripe_payment_method: stripeRes.paymentMethod.id });
      } else {
        await api.patch('/subscription', { stripe_payment_method: stripeRes.paymentMethod.id });
      }

      success = true;
    } catch (err) {
      // Silently ignore - `success` will be false so will show an error
    }

    setSaving(false);

    if (success) {
      onSuccess();
    } else {
      toast('Could not update payment details. Something went wrong.');
    }
  }

  return (
    <Elements stripe={stripe}>
      <ChangeCard
        onCancel={onCancel}
        onSave={onSave}
        saving={saving}
      />
    </Elements>
  );
}

export default ChangeCardContainer;
