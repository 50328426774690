/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import PagesToSetup from './pages-to-setup.view';

function PagesToSetupContainer(props) {
  const {
    fbAccessToken,
    onPageConnected,
    pages,
  } = props;

  const [submitting, setSubmitting] = useState(null);

  async function onSetupPage(page) {
    setSubmitting(page.id);

    const postData = {
      fb_access_token: fbAccessToken,
      fb_page_id: page.id,
    };

    let createdPage = null;
    try {
      const { data } = (await api.post('/page', postData)).data;
      createdPage = data;
    } catch (e) {
      // Silently ignore
    }

    setSubmitting(null);

    if (createdPage) {
      onPageConnected(createdPage);
    } else {
      toast('Could not setup page. Something went wrong.');
    }
  }

  return (
    <PagesToSetup
      onSetupPage={onSetupPage}
      pages={pages}
      submitting={submitting}
    />
  );
}

export default PagesToSetupContainer;
