/* ------ Module imports ------ */
import React, { useContext, useState } from 'react';

/* ------ Context ------ */
import PlatformContext from 'components/platform/context';

/* ------ Common imports ------ */
import Loading from 'common/loading';

/* ------ Helpers ------ */
import api from 'helpers/api';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ View import ------ */
import Dashboard from './dashboard.view';

function DashboardContainer() {
  const { user } = useContext(PlatformContext);

  const [categories, setCategories] = useState(null);
  const [metrics, setMetrics] = useState(null);
  const [onboardingSteps, setOnboardingSteps] = useState(null);
  const [recentConversations, setRecentConversations] = useState(null);
  const [view, setView] = useState('loading');

  const metricColors = ['#48bb78', '#4299e1', '#9f7aea', '#38b2ac', '#667eea', '#ed64a6'];

  async function fetchConversationMetrics() {
    const { data } = (await api.get('/metrics')).data;
    return data;
  }

  async function fetchOnboardingStep() {
    const { data } = (await api.get('/business_onboarding_step')).data;
    return data;
  }

  async function fetchRecentConversations() {
    const { data } = (await api.get('/conversation?status=active&limit=5&expand[]=category&expand[]=last_timeline_item')).data;
    return data;
  }

  async function fetchData() {
    let loadedMetrics = null;
    let loadedRecentConversations = null;
    let loadedOnboardingSteps = null;

    const endpoints = [fetchConversationMetrics(), fetchRecentConversations()];

    if (user.business.onboarding_status === 'started') {
      endpoints.push(fetchOnboardingStep());
    }

    try {
      [
        loadedMetrics,
        loadedRecentConversations,
        loadedOnboardingSteps,
      ] = await Promise.all(endpoints);
    } catch (e) {
      // Silently ignore - loadedMetrics will still be null so view will be set to `error`
    }

    if (loadedMetrics && loadedRecentConversations) {
      const loadedCategories = loadedMetrics.categories.map((c, i) => ({
        title: c.name,
        value: parseInt(c.count),
        color: metricColors[i],
        key: i,
      }));

      setCategories(loadedCategories);
      setMetrics(loadedMetrics);
      setRecentConversations(loadedRecentConversations);

      if (loadedOnboardingSteps) {
        setOnboardingSteps(loadedOnboardingSteps);
      }

      setView('main');
    } else {
      setView('error');
    }
  }

  useDidMount(() => {
    fetchData();
  });

  if (view === 'loading') {
    return <Loading />;
  }

  return (
    <Dashboard
      categories={categories}
      metrics={metrics}
      onboardingSteps={onboardingSteps}
      onSkipOnboarding={() => setOnboardingSteps(null)}
      recentConversations={recentConversations}
      view={view}
    />
  );
}

export default DashboardContainer;
