/* ------ Module imports ------ */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

/* ------ Helpers ------ */
import history from 'helpers/history';
import usePathname from 'helpers/hooks/use-pathname';

/* ------ Common imports ------ */
import Loading from 'common/loading';
import Tab from 'common/tab';

/* ------ Local components ------ */
import AdList from './ad-list';
import Settings from './settings';

function ViewPage(props) {
  const {
    onCategorySetUpdated,
    onHoursUpdated,
    onReauthenticated,
    page,
    view,
  } = props;

  const currentPath = usePathname();

  function renderLoading() {
    return <Loading />;
  }

  function renderError() {
    // TODO.
  }

  function renderAdList() {
    return (
      <AdList
        page={page}
      />
    );
  }

  function renderSettings() {
    return (
      <Settings
        onCategorySetUpdated={onCategorySetUpdated}
        onHoursUpdated={onHoursUpdated}
        onReauthenticated={onReauthenticated}
        page={page}
      />
    );
  }

  function renderMain() {
    return (
      <div>
        <div className="flex items-center">
          <div className="rounded-lg rounded-lg w-12 h-12 bg-center bg-cover" style={{ backgroundImage: `url(${page.profile_picture})` }} />
          <h1 className="ml-4 font-heading text-2xl font-medium text-gray-800">{page.name}</h1>
        </div>

        <div className="flex border-b border-gray-400 mt-6 mb-6">
          <Tab
            onClick={() => history.push(`/pages/${page.id}`)}
            selected={currentPath === `/pages/${page.id}`}
          >
            Page Settings
          </Tab>
          <Tab
            onClick={() => history.push(`/pages/${page.id}/ads`)}
            selected={currentPath === `/pages/${page.id}/ads`}
          >
            Ads
          </Tab>
        </div>

        <Switch>
          <Route path="/pages/:id/ads" render={renderAdList} />
          <Route path="/pages/:id" render={renderSettings} />
        </Switch>
      </div>
    );
  }

  if (view === 'loading') {
    return renderLoading();
  }

  if (view === 'error') {
    return renderError();
  }

  return renderMain();
}

export default ViewPage;
