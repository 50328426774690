/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import auth from 'helpers/auth';
import history from 'helpers/history';
import toast from 'helpers/toast';
import userUtil from 'helpers/user';

/* ------ Views ------ */
import Signup from './signup.view';

const INITIAL_DATA = {
  businessName: '',
  confirmPassword: '',
  email: '',
  firstName: '',
  lastName: '',
  password: '',
};

function SignupContainer() {
  const [userData, setUserData] = useState(INITIAL_DATA);
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  function getPostData() {
    return {
      business_name: userData.businessName,
      email: userData.email,
      first_name: userData.firstName,
      last_name: userData.lastName,
      password: userData.password,
    };
  }

  function onChange(key, value) {
    const updatedData = userData;
    updatedData[key] = value;
    setUserData({ ...updatedData });
  }

  function validateData() {
    const fieldErrors = {};

    if (!userData.businessName || !userData.businessName.trim()) {
      fieldErrors.businessName = true;
    }
    if (!userData.email || !userData.email.trim()) {
      fieldErrors.email = true;
    }
    if (!userData.password) {
      fieldErrors.password = true;
    }
    if (!userData.confirmPassword) {
      fieldErrors.confirmPassword = true;
    }
    if (!userData.firstName || !userData.firstName.trim()) {
      fieldErrors.firstName = true;
    }
    if (!userData.lastName || !userData.lastName.trim()) {
      fieldErrors.lastName = true;
    }

    return fieldErrors;
  }

  async function onSubmit(ev) {
    ev.preventDefault();

    const validationErrors = validateData();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    if (userData.password !== userData.confirmPassword) {
      toast('Passwords do not match');
      return;
    }

    if (userData.password.length < 6) {
      toast('Password must be at least 6 characters long');
    }

    setSubmitting(true);

    let user = null;
    const postData = getPostData();

    try {
      const { data } = (await api.post('/user', postData, false)).data;
      if (data.object === 'user') {
        user = data;
      }
    } catch (e) {
      toast('Something went wrong. Please try again.');
    }

    if (user) {
      let loginSuccess = false;
      try {
        await auth.login(userData.email, userData.password, user.business.auth_tenant_id);

        // Need to trigger a user load so the account is added to the accounts cache
        await userUtil.refreshUser();

        loginSuccess = true;
      } catch (e) {
        // Silently ignore - loginSuccess will still be false so will redirect to /login
      }

      setSubmitting(false);

      if (loginSuccess) {
        history.push('/');
      } else {
        history.push('/login');
      }
    } else {
      setSubmitting(false);
      toast('Something went wrong. Please try again');
    }
  }

  return (
    <Signup
      data={userData}
      errors={errors}
      onChange={onChange}
      onSubmit={onSubmit}
      submitting={submitting}
    />
  );
}

export default SignupContainer;
