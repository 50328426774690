/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import NewAd from './new-ad.view';

function NewAdContainer(props) {
  const {
    onAdCreated,
    onCancel,
    page,
  } = props;

  const [name, setName] = useState('');
  const [id, setId] = useState('');
  const [category, setCategory] = useState(null);

  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  function validateData() {
    const validationErrors = {};

    if (!name) {
      validationErrors.name = true;
    }
    if (!id) {
      validationErrors.id = true;
    }
    if (!category) {
      validationErrors.category = true;
    }

    return validationErrors;
  }

  async function onSubmit(e) {
    e.preventDefault();

    const validationErrors = validateData();
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    const postData = {
      name,
      fb_ad_id: id,
      category: category.value,
      page: page.id,
    };

    let ad = null;
    try {
      const { data } = (await api.post('/ad', postData)).data;
      ad = data;
    } catch (err) {
      // Silently ignore - `ad` will be null so will show an error
    }

    setSubmitting(false);

    if (ad) {
      onAdCreated();
    } else {
      toast('Could not set up ad. Something went wrong.');
    }
  }

  return (
    <NewAd
      category={category}
      errors={errors}
      id={id}
      name={name}
      onCancel={onCancel}
      onCategoryChanged={setCategory}
      onIdChanged={setId}
      onNameChanged={setName}
      onSubmit={onSubmit}
      page={page}
      submitting={submitting}
    />
  );
}

export default NewAdContainer;
