/* ------ Module imports ------ */
import { useCallback, useEffect } from 'react';

const noop = () => {};

function useDidMount(fn) {
  const memoizedFn = useCallback(fn, []);

  useEffect(() => {
    const unregisterFn = memoizedFn();

    return unregisterFn || noop;
  }, [memoizedFn]);
}

export default useDidMount;
