/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ Common imports ------ */
import UserSelector from 'common/user-selector';

function AddUser(props) {
  const {
    category,
    onCancel,
    onCategoryUpdated,
  } = props;

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [submitting, setSubmitting] = useState(false);

  async function fetchUsers() {
    const { data } = (await api.get('/user')).data;
    return data;
  }

  async function fetchInvites() {
    const { data } = (await api.get('/invite')).data;
    return data;
  }

  async function fetchData() {
    let loadedInvites = null;
    let loadedUsers = null;
    try {
      [loadedInvites, loadedUsers] = await Promise.all([
        fetchInvites(),
        fetchUsers(),
      ]);
    } catch (e) {
      toast('Could not load users. Something went wrong.');
    }

    if (!loadedUsers) {
      onCancel();
      return;
    }

    /* ------ Need to filter out users who are already on this category ------ */
    const currentUserObject = {};
    category.users.forEach(user => {
      currentUserObject[user.id] = true;
    });
    category.invites.forEach(invite => {
      currentUserObject[invite.id] = true;
    });

    const filteredUsers = loadedUsers.filter(u => !currentUserObject[u.id]);
    const filteredInvites = loadedInvites.filter(i => !currentUserObject[i.id]);

    setUsers(filteredUsers.concat(filteredInvites));
    setLoading(false);
  }

  useDidMount(() => {
    fetchData();
  });

  async function onConfirm() {
    if (!selectedUsers || selectedUsers.length === 0) {
      return;
    }

    setSubmitting(true);

    let updatedCategory = null;

    const updatedUsers = {
      users: category.users.map(u => u.id),
      invites: category.invites.map(i => i.id),
    };

    selectedUsers.forEach(selectedUser => {
      if (selectedUser.object === 'invite') {
        updatedUsers.invites.push(selectedUser.value);
      } else {
        updatedUsers.users.push(selectedUser.value);
      }
    });

    try {
      const { data } = (await api.patch(`/category/${category.id}?expand[]=users&expand[]=invites`, updatedUsers)).data;
      if (data.object === 'category') {
        updatedCategory = data;
      }
    } catch (e) {
      // Silently ignore - updatedCategory will stay null so we will show an error toast
    }

    setSubmitting(false);

    if (!updatedCategory) {
      toast('Something went wrong. Please try again.');
      return;
    }

    onCategoryUpdated(updatedCategory);
    onCancel();
  }

  async function onFocus() {
    setLoading(true);
    await fetchData();
  }

  return (
    <UserSelector
      data={users}
      isMulti
      loading={loading}
      onCancel={onCancel}
      onChange={setSelectedUsers}
      onConfirm={onConfirm}
      onFocus={onFocus}
      submitting={submitting}
      value={selectedUsers}
    />
  );
}

export default AddUser;
