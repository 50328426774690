/* ------ Module imports ------ */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

/* ------ Helper imports ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View imports ------ */
import AddCategorySet from './add-category-set.view';

function AddCategorySetContainer(props) {
  const { isEmpty } = props;

  const history = useHistory();

  const [adding, setAdding] = useState(false);
  const [errors, setErrors] = useState({});
  const [name, setName] = useState('');
  const [submitting, setSubmitting] = useState(false);

  function validateData() {
    const validationErrors = {};

    if (!name || !name.trim()) {
      validationErrors.name = true;
    }

    return validationErrors;
  }

  async function onSubmit(ev) {
    ev.preventDefault();

    const validationErrors = validateData();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    setSubmitting(true);

    let categorySet = null;
    try {
      const { data } = (await api.post('/category_set', { name })).data;
      categorySet = data;
    } catch (e) {
      // Silently ignore.
    }

    setSubmitting(false);

    if (categorySet) {
      setAdding(false);
      history.push(`/category-sets/${categorySet.id}`);
    } else {
      toast('Something went wrong. Please try again.');
    }
  }

  return (
    <AddCategorySet
      adding={adding}
      errors={errors}
      isEmpty={isEmpty}
      name={name}
      onNameChanged={setName}
      onSubmit={onSubmit}
      onToggleAdding={() => setAdding(!adding)}
      submitting={submitting}
    />
  );
}

export default AddCategorySetContainer;
