/* ------ Module imports ------ */
import React, { useContext, useState } from 'react';

/* ------ Context ------ */
import PlatformContext from 'components/platform/context';

/* ------ Helpers ------ */
import accounts from 'helpers/accounts';
import auth from 'helpers/auth';
import history from 'helpers/history';
import usePathname from 'helpers/hooks/use-pathname';

/* ------ Common imports ------ */
import Icon from 'common/icon';

/* ------ Local components ------ */
import BillingBanner from './billing-banner';
import Popover from './popover';

function Nav(props) {
  const { onToggleSidebar } = props;

  const {
    user,
    billingError,
  } = useContext(PlatformContext);

  const [popoverVisible, setPopoverVisible] = useState(false);

  const currentPath = usePathname();

  function getTitle() {
    if (currentPath.startsWith('/inbox')) {
      return 'Inbox';
    }

    if (currentPath.startsWith('/archive')) {
      return 'Archive';
    }

    if (currentPath.startsWith('/unassigned')) {
      return 'Unassigned';
    }

    return null;
  }

  function onLogout() {
    /* ------ Close the popover manually so we don't call set state on unmounted component ------ */
    setPopoverVisible(false);

    accounts.removeCurrentAccount();
    auth.logout();
    history.push('/login');
  }

  function onShowPopover() {
    if (!popoverVisible) {
      setPopoverVisible(true);
    }
  }

  return (
    <div className={`fixed top-0 right-0 w-full ${user.type === 'admin' ? 'xl:pl-80' : ''} z-40`}>
      {user.type === 'admin' && (
        <>
          {(billingError && currentPath !== '') && (
            <div className="w-full">
              <BillingBanner business={user.business} />
            </div>
          )}
        </>
      )}

      <div className="w-full bg-white border-b border-gray-300 flex items-center justify-between pl-6">
        <div className="flex items-center">
          {user.type === 'admin' && (
            <button
              className="focus:outline-none xl:hidden pr-4 py-2"
              onClick={onToggleSidebar}
              type="button"
            >
              <Icon className="text-gray-800" icon="menu" />
            </button>
          )}
          <p className="text-gray-800 font-heading">{getTitle()}</p>
        </div>

        <div className="relative">
          <button
            className="focus:outline-none px-6 py-4 flex items-center bg-white hover:bg-gray-200"
            onClick={onShowPopover}
            type="button"
          >
            <div
              className="bg-gray-400 rounded-full w-8 h-8 bg-cover bg-center"
              style={{ backgroundImage: `url(${user.profile_picture})` }}
            />

            <div className="ml-2">
              <i className="text-xs ralli-icon-down-chevron text-gray-600" />
            </div>
          </button>

          {popoverVisible && (
            <div className="relative">
              <Popover
                onClose={() => setPopoverVisible(false)}
                onLogout={onLogout}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Nav;
