/* ------ Module imports ------ */
import React, { useContext } from 'react';

/* ------ Context ------ */
import PlatformContext from 'components/platform/context';

/* ------ Helpers ------ */
import userUtil from 'helpers/user';

/* ------ Local components ------ */
import Billing from './billing';
import ConversationManagers from './conversation-managers';
import Details from './details';
import PastPayments from './past-payments';

function Settings() {
  const { user } = useContext(PlatformContext);

  async function onBusinessUpdated() {
    userUtil.refreshUser();
  }

  return (
    <>
      <h1 className="font-heading text-2xl font-medium text-gray-800">Business settings</h1>

      <Details
        business={user.business}
        onBusinessUpdated={onBusinessUpdated}
      />

      <ConversationManagers />

      <Billing
        business={user.business}
        onBusinessUpdated={onBusinessUpdated}
      />

      <PastPayments />
    </>
  );
}

export default Settings;
