/* ------ Module imports ------ */
import React from 'react';

function View(props) {
  const { chatPlugin } = props;

  return (
    <div className="p-6">
      <div className="flex items-center">
        <p className="text-sm font-semibold w-40 text-gray-800">Name</p>
        <p className="text-sm text-gray-800">{chatPlugin.name}</p>
      </div>
      <div className="flex mt-4">
        <p className="text-sm flex-shrink-0 font-semibold w-40 text-gray-800">Greeting</p>
        <p className="text-sm text-gray-800 break-all">{chatPlugin.greeting}</p>
      </div>
      <div className="flex mt-4">
        <p className="text-sm flex-shrink-0 font-semibold w-40 text-gray-800">Description</p>
        <p className="text-sm text-gray-800 break-all">{chatPlugin.description}</p>
      </div>
      <div className="flex items-center mt-4">
        <p className="text-sm font-semibold w-40 text-gray-800">Facebook Page</p>
        {!chatPlugin.page && <p className="text-sm text-gray-800">Not set up</p>}
        {chatPlugin.page && (
          <div className="flex items-center">
            <div className="mr-2 w-6 h-6 rounded bg-cover" style={{ backgroundImage: `url(${chatPlugin.page.profile_picture})` }} />
            <span className="text-sm text-gray-800">{chatPlugin.page.name}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default View;
