/* ------ Module imports ------ */
import React from 'react';
import anchorme from 'anchorme';

function UrlReferral(props) {
  const {
    name,
    url,
  } = props;

  const link = anchorme(url, {
    attributes: [
      {
        name: 'target',
        value: '_blank',
      },
      {
        name: 'rel',
        value: 'noopener noreferrer',
      },
    ],
  });

  return (
    <div className="py-2 text-center">
      <p className="text-xs text-center text-gray-600 font-medium mb-1">{`${name} started a conversation from the following website`}</p>
      <div
        className="inline-block rounded-full px-3 py-1 bg-gray-200 text-xs text-gray-800 font-medium"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: link }}
      />
    </div>
  );
}

export default UrlReferral;
