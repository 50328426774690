/* ------ Module imports ------ */
import React from 'react';

function Card(props) {
  const { card } = props;

  const imageFile = ['visa', 'mastercard', 'amex'].indexOf(card.brand) >= 0 ? card.brand : 'credit-card';

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <img
          alt={imageFile}
          className="w-10"
          src={`/assets/icons/${imageFile}.svg`}
        />
        <span className="font-semibold text-gray-800 ml-4">
          &lowast;&lowast;&lowast;&lowast;
          &lowast;&lowast;&lowast;&lowast;
          &lowast;&lowast;&lowast;&lowast;&nbsp;
          {card.last_digits}
        </span>
      </div>
      <span className="text-sm text-gray-800">{`Expires ${card.exp_month} / ${card.exp_year}`}</span>
    </div>
  );
}

export default Card;
