/* ------ Module imports ------ */
import React, { useContext, useMemo, useState } from 'react';

/* ------ Context ------ */
import ConversationContext from 'common/conversation/context';

/* ------ Helpers ------ */
import toast from 'helpers/toast';

/* ------ View import ------ */
import AddUsers from './add-users.view';

function AddUsersContainer(props) {
  const {
    onClose,
    users,
  } = props;

  const {
    conversation,
    onUpdateConversation,
  } = useContext(ConversationContext);

  const [selectedUsers, setSelectedUsers] = useState({});
  const [submitting, setSubmitting] = useState(false);

  function onSelectUser(user) {
    selectedUsers[user.id] = !selectedUsers[user.id];
    setSelectedUsers({ ...selectedUsers });
  }

  const allChecked = useMemo(() => {
    let checked = true;

    users.forEach(u => {
      if (!selectedUsers[u.id]) {
        checked = false;
      }
    });

    return checked;
  }, [selectedUsers, users]);

  function onSelectAll() {
    if (allChecked) {
      setSelectedUsers({});
    } else {
      const updatedSelectedUsers = {};
      users.forEach(u => {
        updatedSelectedUsers[u.id] = true;
      });
      setSelectedUsers(updatedSelectedUsers);
    }
  }

  async function onContinue() {
    let updatedUsers = Object.keys(selectedUsers).filter(u => selectedUsers[u]);
    if (updatedUsers.length > 0) {
      updatedUsers = updatedUsers.concat(conversation.users.map(u => u.id));

      setSubmitting(true);

      let success = false;
      try {
        await onUpdateConversation('users', updatedUsers);
        success = true;
      } catch (e) {
        // Silently ignore - `updatedConversation` will be null so will show an error
      }

      setSubmitting(false);

      if (success) {
        onClose();
      } else {
        toast('Could not add users. Something went wrong.');
      }
    } else {
      onClose();
    }
  }

  return (
    <AddUsers
      allChecked={allChecked}
      onContinue={onContinue}
      onSelectAll={onSelectAll}
      onSelectUser={onSelectUser}
      selectedUsers={selectedUsers}
      submitting={submitting}
      users={users}
    />
  );
}

export default AddUsersContainer;
