/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Icon from 'common/icon';

/* ------ Local components ------ */
import Settings from './settings';

function Ad(props) {
  const {
    ad,
    expanded,
    onArchived,
    onToggleExpand,
    onUpdated,
    page,
  } = props;

  return (
    <div className="border-b border-gray-300 last:border-b-0">
      <button
        className="w-full flex justify-between items-center text-left px-6 py-4 hover:bg-gray-100 focus:outline-none"
        onClick={onToggleExpand}
        type="button"
      >
        <div>
          <p className="font-medium text-sm text-gray-800">{ad.name}</p>
          <p className="text-gray-600 text-sm">
            {ad.category && `Assigned to ${ad.category.name}`}
            {!ad.category && (
              <div className="flex items-center">
                <Icon className="text-orange-500 mr-2" icon="warning" />
                <span>No category assigned</span>
              </div>
            )}
          </p>
        </div>

        <div>
          <Icon icon={expanded ? 'up-chevron' : 'down-chevron'} />
        </div>
      </button>

      {expanded && (
        <div className="pl-6">
          <div className="py-6 border-t border-gray-300">
            <p className="text-gray-600 text-xs uppercase font-medium">Facebook Ad ID</p>
            <p className="mt-1 text-gray-800 text-sm font-medium">{ad.fb_ad_id}</p>
          </div>

          {ad.status === 'active' && (
            <Settings
              ad={ad}
              onArchived={onArchived}
              onUpdated={onUpdated}
              page={page}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default Ad;
