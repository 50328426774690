/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import Cancel from './cancel.view';

function CancelContainer(props) {
  const {
    onCancel,
    onCancelled,
  } = props;

  const [submitting, setSubmitting] = useState(false);

  async function onSubmit() {
    setSubmitting(true);

    let subscription = null;
    try {
      const { data } = (await api.remove('/subscription')).data;
      subscription = data;
    } catch (e) {
      // Silently ignore - `success` will be false so will show an error
    }

    setSubmitting(false);

    if (subscription) {
      onCancelled(subscription);
    } else {
      toast('Could not cancel subscription. Something went wrong.');
    }
  }

  return (
    <Cancel
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitting={submitting}
    />
  );
}

export default CancelContainer;
