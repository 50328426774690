/* ------ Module imports ------ */
import React, { useRef, useState } from 'react';

/* ------ Common imports ------ */
import Icon from 'common/icon';

/* ------ Helpers ------ */
import useOnClickOutside from 'helpers/hooks/use-on-click-outside';

/* ------ Local components ------ */
import Dropdown from './dropdown';

function Filter(props) {
  const {
    filter,
    includeArchived,
    onFilter,
    onToggleIncludeArchived,
  } = props;

  const [expanded, setExpanded] = useState(false);

  const ref = useRef(null);
  useOnClickOutside(ref, () => setExpanded(false));

  function onSelectChannel(channel) {
    onFilter(channel);
    setExpanded(false);
  }

  return (
    <div ref={ref}>
      <button
        className="focus:outline-none w-full flex items-center px-6 py-2 overflow-x-auto border-b border-gray-300"
        onClick={() => setExpanded(!expanded)}
        type="button"
      >
        <Icon
          className="text-xs text-gray-600 mr-2"
          icon="select"
        />
        {!filter && <p className="text-xs text-gray-800 font-semibold">Filter</p>}
        {filter && (
          <div className="flex items-center">
            <img
              alt={filter.name}
              className="w-4"
              src={`/assets/img/${filter.type}.svg`}
            />
            <p className="ml-1 text-xs text-gray-800 font-medium">{filter.name}</p>
          </div>
        )}
      </button>

      {expanded && (
        <div className="relative">
          <Dropdown
            filter={filter}
            includeArchived={includeArchived}
            onSelectChannel={onSelectChannel}
            onToggleIncludeArchived={onToggleIncludeArchived}
          />
        </div>
      )}
    </div>
  );
}

export default Filter;
