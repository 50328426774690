/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ Common imports ------ */
import UserView from 'common/user';

function User(props) {
  const {
    onManagerDeleted,
    user,
  } = props;

  const [deleting, setDeleting] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  async function onDelete() {
    setSubmitting(true);

    let success = false;
    try {
      api.patch(`/user/${user.id}`, { is_manager: false });
      success = true;
    } catch (e) {
      // Silently ignore.
    }

    setSubmitting(false);

    if (success) {
      onManagerDeleted(user.id);
    } else {
      toast('Something went wrong. Please try again.');
    }
  }

  return (
    <UserView
      deleting={deleting}
      onCancelDelete={() => setDeleting(false)}
      onConfirmDelete={onDelete}
      onDelete={() => setDeleting(true)}
      submitting={submitting}
      user={user}
    />
  );
}

export default User;
