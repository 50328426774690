/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ View import ------ */
import PageSelector from './page-selector.view';

function PageSelectorContainer(props) {
  const {
    disabled,
    onChange,
    value,
  } = props;

  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState(null);

  async function fetchPages() {
    let loadedPages = null;
    try {
      const { data } = (await api.get('/page')).data;
      loadedPages = data;
    } catch (e) {
      // Silently ignore - `loadedPages` will stay null so will show an error
    }

    if (loadedPages) {
      setPages(loadedPages);
    } else {
      setPages([]);
    }

    setLoading(false);
  }

  useDidMount(() => {
    fetchPages();
  });

  return (
    <PageSelector
      disabled={disabled}
      loading={loading}
      onChange={onChange}
      pages={pages}
      value={value}
    />
  );
}

export default PageSelectorContainer;
