/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Icon from 'common/icon';

/* ------ Local components ------ */
import Category from './category';

function Layer(props) {
  const {
    categories,
    parent,
    onBack,
    onSelectCategory,
  } = props;

  return (
    <div className={`bg-white ${!parent ? '' : 'absolute top-0 left-0'} w-full h-full`}>
      {parent && (
        <div className="border-t border-b border-gray-300 px-4 py-2">
          <button
            className="flex items-center"
            onClick={onBack}
            type="button"
          >
            <Icon className="text-xs text-gray-600" icon="left-chevron" />
            <p className="ml-2 text-xs text-gray-800">{parent.name}</p>
          </button>
        </div>
      )}

      {categories.map(category => (
        <Category
          category={category}
          key={category.id}
          onSelect={() => onSelectCategory(category)}
        />
      ))}
    </div>
  );
}

export default Layer;
