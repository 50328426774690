/* ------- Module imports ------ */
import React from 'react';
import { Link } from 'react-router-dom';

/* ------ Common imports ------ */
import Dots from 'common/dots';
import Icon from 'common/icon';
import PlainButton from 'common/plain-button';

function Onboarding(props) {
  const {
    onSkipOnboarding,
    steps,
  } = props;

  function getDetails(step) {
    if (step === 'fb') {
      return { text: 'Set up your facebook page', url: '/pages-add' };
    }

    if (step === 'web') {
      return { text: 'Add a chat plugin to your website', url: '/chat-plugin/add' };
    }

    return { text: 'Invite other people from your business', url: '/users?expand=invite' };
  }

  function renderChecklist() {
    return (
      <>
        {steps.map(step => {
          const details = getDetails(step.type);

          return (
            <Link
              className="border-t pl-6 pr-10 py-4 hover:bg-gray-100 flex w-full justify-between items-center"
              key={step.id}
              to={details.url}
            >
              <div className="flex items-center">
                <div className="w-6 h-6 rounded bg-white border border-gray-300 text-green-500 flex items-center justify-center">
                  {step.done && <Icon icon="check" />}
                </div>
                <p className={`ml-4 text-sm ${step.done ? 'line-through' : ''}`}>{details.text}</p>
              </div>
              <Icon
                className="text-green-500"
                icon="right-arrow"
              />
            </Link>
          );
        })}
      </>
    );
  }

  return (
    <div className="relative pb-10 border-b border-gray-300">
      <div className="relative bg-white shadow-lg rounded-lg z-10 overflow-hidden">
        <div className="flex justify-between px-6 py-4">
          <p className="font-medium text-gray-800 text">Getting started with Ralli</p>
          <PlainButton
            color="gray"
            onClick={onSkipOnboarding}
          >
            Skip getting started
          </PlainButton>
        </div>
        {renderChecklist()}
      </div>

      <Dots
        color="green"
        right
      />
    </div>
  );
}

export default Onboarding;
