/* ------ Module imports ------ */
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

/* ------ Context ------ */
import InboxContext from 'components/inbox/context';
import PlatformContext from 'components/platform/context';

/* ------ Common imports ------ */
import Conversation from 'common/conversation';
import Icon from 'common/icon';
import Loading from 'common/loading';

/* ------ Helpers ------ */
import api from 'helpers/api';
import useWatcher from 'helpers/hooks/use-watcher';

function ConversationContainer() {
  const { id } = useParams();

  const {
    onConversationStatusChanged,
    onConversationRead,
  } = useContext(InboxContext);
  const { onPollUnreadConversations } = useContext(PlatformContext);

  const [conversation, setConversation] = useState(null);
  const [timelineItems, setTimelineItems] = useState(null);
  const [view, setView] = useState('loading');

  const expansions = ['message.attachment'];

  async function fetchTimelineItems() {
    const url = `/timeline_item?conversation=${id}&expand[]=${expansions.join('&expand[]=')}`;

    const { data } = (await api.get(url)).data;
    return data;
  }

  async function fetchConversation() {
    const url = `/conversation/${id}?expand[]=page&expand[]=chat_plugin&expand[]=users&expand[]=categories`;

    const { data } = (await api.get(url)).data;
    return data;
  }

  async function updateWaterline(timestamp) {
    try {
      await api.put('/conversation_waterline', { conversation: id, waterline: timestamp });
    } catch (e) {
      // Ignore.
    }

    onPollUnreadConversations();
  }

  useWatcher('conv_event', message => {
    if (!conversation || !timelineItems) {
      return;
    }

    if (conversation.id === message.conversation) {
      const updatedTimelineItems = timelineItems.concat(message.timeline_item);
      setTimelineItems(updatedTimelineItems);

      if (message.timeline_item.type === 'conv_activated') {
        setConversation({ ...conversation, status: 'active' });
      }
      if (message.timeline_item.type === 'conv_archived') {
        setConversation({ ...conversation, status: 'archived' });
      }

      updateWaterline(message.timeline_item.timestamp);
    }
  });

  async function fetchData() {
    let loadedTimelineItems = null;
    let loadedConversation = null;
    try {
      [loadedTimelineItems, loadedConversation] = await Promise.all([
        fetchTimelineItems(),
        fetchConversation(),
      ]);
    } catch (e) {
      // Silently ignore - loadedTimelineItems will still be null so view will be set to `error`
    }

    if (loadedTimelineItems && loadedConversation) {
      if (loadedTimelineItems.length) {
        try {
          await updateWaterline(loadedTimelineItems[loadedTimelineItems.length - 1].timestamp);
        } catch (e) {
          // Ignore.
        }
      }

      onConversationRead(loadedConversation);

      setTimelineItems(loadedTimelineItems);
      setConversation(loadedConversation);

      setView('main');
    } else {
      setView('error');
    }
  }

  async function onFetchCategories() {
    let url = '/category?expand[]=users';
    if (conversation.page) {
      url += `&category_set=${conversation.page.category_set}`;
    } else {
      url += `&category_set=${conversation.chat_plugin.category_set}`;
    }

    const { data } = (await api.get(url)).data;
    return data;
  }

  async function onFetchUsers() {
    const { data } = (await api.get('/user')).data;
    return data;
  }

  async function onSendMessage(text, attachment) {
    const requests = [];
    if (text) {
      requests.push(api.post('/timeline_item', { conversation: conversation.id, type: 'message', message: { text } }));
    }
    if (attachment) {
      requests.push(api.post('/timeline_item', { conversation: conversation.id, type: 'message', message: { attachment: attachment.id } }));
    }

    return Promise.all(requests);
  }

  async function onUpdateConversation(field, value) {
    const postData = {};
    postData[field] = value;

    const { data } = (await api.patch(`/conversation/${conversation.id}?expand[]=page&expand[]=users&expand[]=categories`, postData)).data;
    if (data) {
      if (conversation.status !== data.status) {
        onConversationStatusChanged(data);
      }

      setConversation(data);
    } else {
      throw new Error();
    }
  }

  async function onUploadFile(file) {
    if (!file) {
      return null;
    }

    const postData = new FormData();
    postData.append('file', file);

    const { data } = (await api.post('/file', postData)).data;
    return data;
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (view === 'loading') {
    return <Loading />;
  }

  if (view === 'error') {
    return (
      <div className="p-10 mt-10 bg-white shadow-lg rounded-lg">
        <div className="bg-orange-300 text-orange-500 flex justify-center items-center rounded-full w-10 h-10">
          <Icon icon="warning" />
        </div>
        <p className="mt-6 font-medium text-gray-800 mb-2 text-xl">Could not load your conversation</p>
        <p className="text-gray-800 text-sm">Something went wrong loading your conversation. Please try again or contact us if you keep having this problem.</p>
      </div>
    );
  }

  return (
    <Conversation
      baseUrl="/inbox"
      conversation={conversation}
      onFetchCategories={onFetchCategories}
      onFetchUsers={onFetchUsers}
      onSendMessage={onSendMessage}
      onUpdateConversation={onUpdateConversation}
      onUploadFile={onUploadFile}
      timelineItems={timelineItems}
    />
  );
}

export default ConversationContainer;
