/* ------ Module imports ------ */
import React, { useContext, useState } from 'react';

/* ------ Context ------ */
import ConversationContext from 'common/conversation/context';

/* ------ Common imports ------ */
import Icon from 'common/icon';

/* ------ Local components ------ */
import AddUser from './add-user';
import Users from './users';

function Dropdown() {
  const { conversation } = useContext(ConversationContext);
  const { users } = conversation;

  const [addingUser, setAddingUser] = useState(false);

  return (
    <div className={`transform -translate-y-full md:translate-y-0 z-10 -mt-3 md:mt-3 popover-bottom md:popover popover-left md:popover-right absolute top-0 left-0 md:left-auto md:right-0 bg-white rounded-lg shadow-lg border border-gray-300 ${addingUser ? 'w-128' : 'w-64'}`}>
      <div className="p-4 max-h-md overflow-y-auto">
        <p className="mb-3 text-gray-600 text-xs uppercase font-medium">Users</p>
        {users.length > 0 && <Users />}
        {users.length === 0 && (
          <span className="flex items-center text-xs text-orange-500 font-semibold mr-2">
            <Icon className="mr-2" icon="warning" />
            No users
          </span>
        )}
      </div>
      <div className="border-t border-gray-300">
        {!addingUser && (
          <button
            className="focus:outline-none w-full px-4 py-2 text-xs font-semibold text-blue-500 uppercase"
            onClick={() => setAddingUser(true)}
            type="button"
          >
            + Add more users
          </button>
        )}
        {addingUser && (
          <div className="px-4 pb-4">
            <AddUser
              existingUsers={users}
              onCancel={() => setAddingUser(false)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Dropdown;
