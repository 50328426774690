/* ------ Module imports ------ */
import React from 'react';

/* ------ Local components ------ */
import Reauthenticate from './reauthenticate';

function FacebookDetails(props) {
  const {
    onReauthenticated,
    page,
  } = props;

  return (
    <div className="bg-white shadow-lg rounded-lg">
      <p className="font-medium text-gray-800 text px-6 py-4">Facebook</p>

      <div className="border-t border-gray-300 p-6 flex items-center">
        <img
          alt=""
          className="h-8 w-8 rounded"
          src={page.added_by.profile_picture}
        />
        <p className="text-gray-800 ml-4 text-sm font-medium">{`This page was set up by ${page.added_by.first_name} ${page.added_by.last_name}`}</p>
      </div>

      <Reauthenticate
        onReauthenticated={onReauthenticated}
        page={page}
      />
    </div>
  );
}

export default FacebookDetails;
