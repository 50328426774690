/* ------ Module imports ------ */
import React from 'react';
import { useHistory } from 'react-router-dom';

/* ------ Helpers ------ */
import usePathname from 'helpers/hooks/use-pathname';

/* ------ Common imports ------ */
import PlainButton from 'common/plain-button';

function TrialExpired() {
  const history = useHistory();
  const pathname = usePathname();

  return (
    <div className="bg-orange-300 px-6 py-4">
      <div className="flex items-center justify-between">
        <div>
          <p className="font-semibold text-sm text-orange-900">Your trial has expired</p>
          <p className="font-medium text-orange-600 text-xs">You will need to set up a subscription to continue using Ralli.</p>
        </div>

        {(pathname !== '/settings' && pathname !== '/subscribe') && (
          <div>
            <PlainButton
              color="orange"
              onClick={() => history.push('/subscribe')}
            >
              Set up a subscription
            </PlainButton>
          </div>
        )}
      </div>
    </div>
  );
}

export default TrialExpired;
