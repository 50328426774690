/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ View import ------ */
import Businesses from './businesses.view';

function BusinessesContainer(props) {
  const {
    email,
    onBack,
    onSubmit,
  } = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [businesses, setBusinesses] = useState(null);

  async function fetchBusinesses() {
    let loadedBusinesses = null;
    try {
      const { data } = (await api.get(`/business?email=${email}`, false)).data;
      loadedBusinesses = data;
    } catch (e) {
      // Silently ignore - `loadedBusinesses` will be null so will show an error
    }

    if (loadedBusinesses) {
      setBusinesses(loadedBusinesses);
    } else {
      setError(true);
    }

    setLoading(false);
  }

  useDidMount(() => {
    fetchBusinesses();
  });

  return (
    <Businesses
      businesses={businesses}
      error={error}
      loading={loading}
      onBack={onBack}
      onSelectBusiness={onSubmit}
    />
  );
}

export default BusinessesContainer;
