/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import LoadingSpinner from 'common/loading-spinner';

function Button(props) {
  const {
    className,
    children,
    destructive,
    disabled,
    loading,
    onClick,
    type,
    white,
  } = props;

  function renderLoading() {
    if (!loading) {
      return null;
    }

    return (
      <div className="absolute inset-0 flex items-center justify-center">
        <LoadingSpinner
          small
          white
        />
      </div>
    );
  }

  function buildClassName() {
    let baseClassName = 'relative uppercase focus:outline-none disabled:pointer-events-none px-5 py-2 rounded-lg text-white font-semibold shadow-md text-sm';

    if (destructive) {
      baseClassName += ' bg-red-600 focus:bg-red-700 hover:bg-red-700 disabled:bg-red-300';
    } else if (white) {
      baseClassName += ' bg-white focus:bg-gray-100 hover:bg-gray-100 text-green-500';
    } else {
      baseClassName += ' bg-blue-brand focus:bg-blue-600 hover:bg-blue-600 disabled:bg-blue-300';
    }

    if (className) {
      baseClassName += ` ${className}`;
    }

    return baseClassName;
  }

  if (type === 'submit') {
    return (
      <button
        className={buildClassName()}
        disabled={loading || disabled}
        type="submit"
      >
        <span className={`${loading && 'invisible'}`}>{children}</span>
        {renderLoading()}
      </button>
    );
  }

  return (
    <button
      className={buildClassName()}
      disabled={loading || disabled}
      onClick={onClick}
      type="button"
    >
      <span className={`${loading && 'invisible'}`}>{children}</span>
      {renderLoading()}
    </button>
  );
}

export default Button;
