/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import IconButton from 'common/icon-button';
import Input from 'common/input';

function SingleFieldEdit(props) {
  const {
    className,
    error,
    onCancel,
    onChange,
    onSubmit,
    submitting,
    value,
  } = props;

  function onSubmitForm(e) {
    e.preventDefault();
    onSubmit();
  }

  return (
    <form
      className={`flex ${className || ''}`}
      disabled={submitting}
      onSubmit={onSubmitForm}
    >
      <Input
        className="flex-1"
        error={error}
        onChange={onChange}
        value={value}
      />
      <IconButton
        color="green"
        icon="check"
        loading={submitting}
        type="submit"
      />
      <IconButton
        color="gray"
        disabled={submitting}
        icon="cross"
        onClick={onCancel}
        type="button"
      />
    </form>
  );
}

export default SingleFieldEdit;
