/* ------ Module imports ------ */
import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

/* ------ Helpers ------ */
import api from 'helpers/api';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ Context ------ */
import PlatformContext from 'components/platform/context';

/* ------ View import ------ */
import Users from './users.view';

function UsersContainer() {
  const [activeUsers, setActiveUsers] = useState([]);
  const [invitedUsers, setInvitedUsers] = useState([]);

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  let expandInvite = false;
  if (searchParams.has('expand')) {
    expandInvite = searchParams.get('expand') === 'invite';
  }

  const [showInviteCard, setShowInviteCard] = useState(expandInvite);

  const [tab, setTab] = useState('active');
  const [view, setView] = useState('loading');

  const context = useContext(PlatformContext);
  const { user } = context;

  async function fetchInvites() {
    const { data } = (await api.get('/invite')).data;
    return data;
  }

  async function fetchUsers() {
    const { data } = (await api.get('/user')).data;
    return data;
  }

  async function fetchData() {
    let loadedInvites = null;
    let loadedUsers = null;
    try {
      [loadedInvites, loadedUsers] = await Promise.all([
        fetchInvites(),
        fetchUsers(),
      ]);
    } catch (e) {
      // Silently ignore - users or invites will be null so will show an error
    }

    if (loadedUsers && loadedInvites) {
      setActiveUsers(loadedUsers.filter(u => u.type === 'user' || u.type === 'admin'));
      setInvitedUsers(loadedInvites);

      setView('main');
      setTab('active');
    } else {
      setView('error');
    }
  }

  useDidMount(() => {
    fetchData();
  });

  function onInviteAdded(addedInvite) {
    const updatedInvitedUsers = invitedUsers.concat(addedInvite);
    setInvitedUsers(updatedInvitedUsers);
  }

  function onUserDeleted(deletedUser) {
    if (deletedUser.object === 'user') {
      const updatedUsers = activeUsers.filter(u => u.id !== deletedUser.id);
      setActiveUsers(updatedUsers);
    }
    if (deletedUser.object === 'invite') {
      const updatedInvites = invitedUsers.filter(u => u.id !== deletedUser.id);
      setInvitedUsers(updatedInvites);

      if (updatedInvites.length === 0) {
        setTab('active');
      }
    }
  }

  function onUserUpdated(updatedUser) {
    const updatedUsers = activeUsers.map(u => {
      if (u.id === updatedUser.id) {
        return updatedUser;
      }

      return u;
    });

    setActiveUsers(updatedUsers);
  }

  return (
    <Users
      activeUsers={activeUsers}
      currentUser={user}
      invitedUsers={invitedUsers}
      onChangeTab={setTab}
      onCloseInviteCard={() => setShowInviteCard(false)}
      onShowInviteCard={() => setShowInviteCard(true)}
      onInviteAdded={onInviteAdded}
      onUserDeleted={onUserDeleted}
      onUserUpdated={onUserUpdated}
      showInviteCard={showInviteCard}
      tab={tab}
      view={view}
    />
  );
}

export default UsersContainer;
