/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import PlainButton from 'common/plain-button';

function PastDue(props) {
  const {
    onTryAgain,
    onUpdatePaymentDetails,
    tryingAgain,
  } = props;

  return (
    <div className="border-t border-gray-300 p-6">
      <p className="mb-6 text-sm text-orange-500 font-semibold">
        Your latest payment was declined. Please update your payment details,
        or try the payment again with your existing card.
      </p>

      <div className="flex items-center">
        <Button
          loading={tryingAgain}
          onClick={onTryAgain}
        >
          Try the payment again
        </Button>

        <PlainButton
          className="ml-4"
          disabled={tryingAgain}
          onClick={onUpdatePaymentDetails}
        >
          Update payment details
        </PlainButton>
      </div>
    </div>
  );
}

export default PastDue;
