/* ------ Module imports ------ */
import React, { useContext, useState } from 'react';

/* ------ Context ------ */
import ConversationContext from 'common/conversation/context';

/* ------ Helpers ------ */
import toast from 'helpers/toast';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ View import ------ */
import SelectCategory from './select-category.view';

function SelectCategoryContainer(props) {
  const {
    conversation,
    onCategoryChanged,
  } = props;

  const {
    onFetchCategories,
    onUpdateConversation,
  } = useContext(ConversationContext);

  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState(null);
  const [categoryStack, setCategoryStack] = useState([]);

  async function fetchCategories() {
    let loadedCategories = null;
    try {
      loadedCategories = await onFetchCategories();
    } catch (e) {
      // TODO.
    }

    if (loadedCategories) {
      setCategories(loadedCategories);
    } else {
      // TODO - handle error.
    }

    setLoading(false);
  }

  async function onSubmit(category) {
    setLoading(true);

    let success = false;
    try {
      await onUpdateConversation('category', category ? category.id : null);
      success = true;
    } catch (e) {
      // Silently ignore - success will be false so will show an error
    }

    setLoading(false);

    if (success) {
      onCategoryChanged(category);
    } else {
      toast('Could not update category. Something went wrong.');
    }
  }

  useDidMount(() => {
    fetchCategories();
  });

  function onBack() {
    setCategoryStack(categoryStack.slice(0, categoryStack.length - 1));
  }

  function onSelectCategory(category) {
    if (category && category.children) {
      setCategoryStack(categoryStack.concat(category));
    } else {
      onSubmit(category);
    }
  }

  return (
    <SelectCategory
      categories={categories}
      categoryStack={categoryStack}
      conversation={conversation}
      loading={loading}
      onBack={onBack}
      onSelectCategory={onSelectCategory}
    />
  );
}

export default SelectCategoryContainer;
