/* ------ Module imports ------ */
import React, { useEffect, useRef, useState } from 'react';

/* ------ Helpers ------ */
import useOnClickOutside from 'helpers/hooks/use-on-click-outside';

/* ------ Common imports ------ */
import Icon from 'common/icon';

function ActionSheet(props) {
  const {
    children,
    onClose,
  } = props;

  const [closing, setClosing] = useState(false);

  function onTriggerClose() {
    if (closing) {
      return;
    }

    setClosing(true);
  }

  useEffect(() => {
    if (closing) {
      const timeout = setTimeout(() => {
        onClose();
      }, 400);

      return () => {
        clearTimeout(timeout);
      };
    }

    return () => {};

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closing]);

  const ref = useRef(null);

  useOnClickOutside(ref, onTriggerClose);

  return (
    <div className="bg-gray-900 bg-opacity-50 w-full h-screen z-50 fixed bottom-0 left-0">
      <div className={`${closing ? 'action-sheet-closing' : 'action-sheet'} pt-24 absolute bottom-0 w-full max-h-full overflow-y-auto`}>
        <div ref={ref} className="rounded-t-lg bg-white shadow-md relative">
          <button
            className="absolute top-0 right-0 mt-2 mr-2 w-6 h-6 bg-gray-200 hover:bg-gray-300 rounded-full flex items-center justify-center"
            onClick={onTriggerClose}
            type="button"
          >
            <Icon fontSize="8px" icon="cross" />
          </button>
          {children}
        </div>
      </div>
    </div>
  );
}

export default ActionSheet;
