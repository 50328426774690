/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helper imports ------ */
import api from 'helpers/api';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ View imports ------ */
import CategorySets from './category-sets.view';

function CategorySetsContainer() {
  const [categorySets, setCategorySets] = useState(null);
  const [view, setView] = useState('loading');

  async function fetchCategorySets() {
    let loadedCategorySets = null;

    try {
      const { data } = (await api.get('/category_set?expand[]=in_use_by')).data;
      loadedCategorySets = data;
    } catch (e) {
      // Silently ignore - category sets will still be null so view will be set to `error`
    }

    if (loadedCategorySets) {
      setCategorySets(loadedCategorySets);
      setView('main');
    } else {
      setView('error');
    }
  }

  useDidMount(() => {
    fetchCategorySets();
  });

  function onCategorySetDeleted(categorySet) {
    const updatedCategorySets = categorySets.filter(c => c.id !== categorySet.id);
    setCategorySets(updatedCategorySets);
  }

  function onCategorySetUpdated(categorySet) {
    const updatedCategorySets = categorySets.map(c => {
      if (c.id === categorySet.id) {
        return categorySet;
      }
      return c;
    });

    setCategorySets(updatedCategorySets);
  }

  return (
    <CategorySets
      categorySets={categorySets}
      onCategorySetDeleted={onCategorySetDeleted}
      onCategorySetUpdated={onCategorySetUpdated}
      view={view}
    />
  );
}

export default CategorySetsContainer;
