/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Local imports ------ */
import Expanded from './expanded';
import Header from './header';

function Category(props) {
  const {
    category,
    categorySetId,
    depth,
    onCategoryDeleted,
    onCategoryUpdated,
    parent,
  } = props;

  const [expanded, setExpanded] = useState(false);
  const [subview, setSubview] = useState('main');

  function onDelete() {
    setExpanded(true);
    setSubview('delete');
  }

  function onAddSubcategory() {
    setExpanded(true);
    setSubview('add');
  }

  function onSubcategoryAdded(subcategory) {
    const updatedCategory = category;
    if (category.children) {
      category.children = category.children.concat(subcategory);
    } else {
      category.children = [subcategory];
    }

    onCategoryUpdated(updatedCategory);
  }

  function onSubcategoryUpdated(subcategory) {
    const updatedCategory = category;
    if (category.children && category.children.length > 0) {
      updatedCategory.children = category.children.map(child => {
        if (child.id === subcategory.id) {
          return subcategory;
        }

        return child;
      });

      onCategoryUpdated(updatedCategory);
    }
  }

  return (
    <div className="flex items-start">
      <div className={`w-full bg-white ${parent ? '' : 'shadow-lg rounded-lg mb-6'}`}>
        <Header
          category={category}
          depth={depth}
          expanded={expanded}
          hasParent={!!parent}
          onAddSubcategory={onAddSubcategory}
          onCategoryUpdated={onCategoryUpdated}
          onDelete={onDelete}
          onToggleExpanded={() => setExpanded(!expanded)}
        />

        {expanded && (
          <div className={parent ? 'ml-6' : 'ml-12'}>
            <Expanded
              category={category}
              categorySetId={categorySetId}
              depth={depth}
              onCancel={() => setSubview('main')}
              onCategoryDeleted={onCategoryDeleted}
              onCategoryUpdated={onCategoryUpdated}
              onSubcategoryAdded={onSubcategoryAdded}
              onSubcategoryUpdated={onSubcategoryUpdated}
              subview={subview}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Category;
