/* ------ Module imports ------ */
import React from 'react';
import anchorme from 'anchorme';
import moment from 'moment';

/* ------ Local components ------ */
import Attachment from './attachment';

function Message(props) {
  const {
    conversation,
    message,
    page,
    showDetails,
    timestamp,
    user,
  } = props;

  function formatText() {
    let text = null;

    if (message.text) {
      try {
        text = anchorme(message.text, {
          attributes: [
            {
              name: 'target',
              value: '_blank',
            },
            {
              name: 'rel',
              value: 'noopener noreferrer',
            },
            {
              name: 'class',
              value: 'underline',
            },
          ],
        });
      } catch (e) {
        text = message.text;
      }
    }

    return text;
  }

  const text = formatText();

  function renderMessengerUser() {
    let className = 'max-w-3/5 inline-block';
    if (showDetails) {
      className += ' mt-3';
    }

    return (
      <div className="block">
        <div className={className}>
          <div className="flex items-end">
            <div>
              <div
                className="w-6 h-6 mr-2 rounded-full bg-cover bg-center"
                style={{ backgroundImage: `url(${conversation.profile_picture})` }}
              />
            </div>
            <div className="w-full min-w-0">
              {showDetails && (
                <div className="flex text-xs justify-between">
                  <p className="mr-2">{conversation.name}</p>
                  <p className="text-gray-600">{moment(timestamp).fromNow()}</p>
                </div>
              )}

              {text && (
                <div className="bg-gray-200 rounded-lg text-xs p-3 mt-1 inline-block">
                  {/* eslint-disable-next-line react/no-danger */}
                  <p className="whitespace-pre-wrap break-words" dangerouslySetInnerHTML={{ __html: text }} />
                </div>
              )}

              {message.attachment && (
                <Attachment attachment={message.attachment} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderUser() {
    let className = 'max-w-3/5 inline-block';
    if (showDetails) {
      className += ' mt-3';
    }

    return (
      <div className="text-right">
        <div className={className}>
          {showDetails && (
            <div className="flex text-xs justify-between">
              <p className="mr-1">
                {!message.is_echo && user.first_name}
                {(message.is_echo && page) ? page.name : null}
              </p>
              <p className="text-gray-600">{moment(timestamp).fromNow()}</p>
            </div>
          )}

          {text && (
            <div className="bg-blue-500 rounded-lg text-xs text-white p-3 mt-1 inline-block text-left">
              {/* eslint-disable-next-line react/no-danger */}
              <p className="whitespace-pre-wrap break-words" dangerouslySetInnerHTML={{ __html: text }} />
            </div>
          )}

          {message.attachment && (
            <Attachment attachment={message.attachment} />
          )}
        </div>
      </div>
    );
  }

  return (user || message.is_echo)
    ? renderUser()
    : renderMessengerUser();
}

export default Message;
