/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import Expired from './expired.view';

function ExpiredContainer(props) {
  const {
    existingSubscription,
    onReactivated,
    onUpdatePaymentDetails,
  } = props;

  const [tryingAgain, setTryingAgain] = useState(false);

  async function onCreateSubscription() {
    setTryingAgain(true);

    let subscription = null;
    try {
      const { data } = (await api.post('/subscription', { plan: existingSubscription.plan.stripe_id, stripe_payment_method: existingSubscription.card.stripe_id })).data;
      subscription = data;
    } catch (e) {
      // Silently ignore - `success` will be false so will show an error
    }

    setTryingAgain(false);

    if (subscription) {
      onReactivated(subscription);
    } else {
      toast('Something went wrong. Please try again.');
    }
  }

  return (
    <Expired
      onTryAgain={onCreateSubscription}
      onUpdatePaymentDetails={onUpdatePaymentDetails}
      tryingAgain={tryingAgain}
    />
  );
}

export default ExpiredContainer;
