/* ------ Module imports ------ */
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import '@stripe/stripe-js';

/* ------ Config ------ */
import config from 'config';

/* ------ Styles ------ */
import './styles';

/* ------ App import ------ */
import App from './app';

/* ------ Init sentry on deployed envs ------ */
if (config.env !== 'local') {
  Sentry.init({
    dsn: config.sentry.dsn,
    environment: config.env,
  });
}

/* ------ Render the app! ------ */
ReactDOM.render(<App />, document.getElementById('root'));
