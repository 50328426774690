/* ------ Module imports ------ */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

/* ------ Common imports ------ */
import Icon from './icon';

function Link(props) {
  const {
    label,
    icon,
    href,
    notifications,
    onClick,
    selected,
  } = props;

  function buildClassName() {
    let className = 'px-10 w-full flex items-center mb-1 py-2 focus:outline-none';
    if (selected) {
      className += ' bg-gray-700';
    } else {
      className += ' bg-gray-800 hover:bg-gray-700';
    }

    return className;
  }

  function renderInner() {
    return (
      <>
        <Icon icon={icon} />
        <span className="ml-3 text-sm text-gray-100 font-medium">{label}</span>

        {notifications && (
          <div className="h-2 w-2 ml-2 bg-red-600 rounded-full" />
        )}
      </>
    );
  }

  if (!href) {
    return (
      <button
        className={buildClassName()}
        onClick={onClick}
        type="button"
      >
        {renderInner()}
      </button>
    );
  }

  return (
    <RouterLink
      className={buildClassName()}
      onClick={onClick}
      style={{ transition: 'background-color 0.3s' }}
      to={href}
    >
      {renderInner()}
    </RouterLink>
  );
}

export default Link;
