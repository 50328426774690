/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ Common imports ------ */
import UserSelector from 'common/user-selector';

function Selector(props) {
  const {
    onCancel,
    onManagerAdded,
  } = props;

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const [submitting, setSubmitting] = useState(false);

  async function fetchUsers() {
    let loadedUsers = null;
    try {
      const { data } = (await api.get('/user')).data;
      loadedUsers = data;
    } catch (e) {
      // Silently ignore.
    }

    if (!loadedUsers) {
      toast('Could not load users. Something went wrong.');
      onCancel();
      return;
    }

    setUsers(loadedUsers);
    setLoading(false);
  }

  useDidMount(() => {
    fetchUsers();
  });

  async function onConfirm() {
    if (!selectedUser) {
      return;
    }

    setSubmitting(true);

    let addedUser = null;
    try {
      const { data } = (await api.patch(`/user/${selectedUser.value}`, { is_manager: true })).data;
      addedUser = data;
    } catch (e) {
      // Silently ignore.
    }

    setSubmitting(false);

    if (addedUser) {
      onManagerAdded(addedUser);
      onCancel();
    } else {
      toast('Something went wrong. Please try again.');
    }
  }

  /* ------ Need to filter out users who are already managers ------ */
  const filteredUsers = users.filter(u => !u.is_manager);

  return (
    <div className="mt-4">
      <UserSelector
        data={filteredUsers}
        loading={loading}
        onCancel={onCancel}
        onChange={setSelectedUser}
        onConfirm={onConfirm}
        submitting={submitting}
        value={selectedUser}
      />
    </div>
  );
}

export default Selector;
