/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import auth from 'helpers/auth';
import history from 'helpers/history';
import toast from 'helpers/toast';
import user from 'helpers/user';

/* ------ View import ------ */
import User from './user.view';

function UserContainer(props) {
  const {
    business,
    email,
    onBack,
    onForgotPassword,
  } = props;

  const [password, setPassword] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  function validateData() {
    const fieldErrors = {};

    if (!password) {
      fieldErrors.password = true;
    }

    return fieldErrors;
  }

  async function onSubmit(ev) {
    ev.preventDefault();

    const fieldErrors = validateData();
    setValidationErrors(fieldErrors);

    if (Object.keys(fieldErrors).length > 0) {
      return;
    }

    setSubmitting(true);

    let loginSuccess = false;
    try {
      await auth.login(email, password, business.auth_tenant_id);

      // Need to trigger a user load so the account is added to the accounts cache
      await user.refreshUser();

      loginSuccess = true;
    } catch (e) {
      toast('Incorrect password. Please try again.');
    }

    setSubmitting(false);

    if (loginSuccess) {
      history.push('/');
    }
  }

  return (
    <User
      business={business}
      email={email}
      onBack={onBack}
      onForgotPassword={onForgotPassword}
      onPasswordChanged={setPassword}
      onSubmit={onSubmit}
      password={password}
      submitting={submitting}
      validationErrors={validationErrors}
    />
  );
}

export default UserContainer;
