/* ------ Module imports ------ */
import React from 'react';

function Toggle(props) {
  const {
    checked,
    onToggle,
    toggleId,
  } = props;

  return (
    <label htmlFor={toggleId}>
      <input
        checked={checked}
        className="toggle-checkbox"
        id={toggleId}
        onChange={onToggle}
        type="checkbox"
      />
      <div className="toggle-inner">
        <i className="toggle-inner-icon" />
      </div>
    </label>
  );
}

export default Toggle;
