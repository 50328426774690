export default {
  env: 'staging',
  urls: {
    api: 'https://staging-api.ralli.app',
    chatPlugin: 'https://staging-plugin.ralli.app',
    sockets: 'https://staging-sockets.ralli.app',
  },
  firebase: {
    apiKey: 'AIzaSyBNYZBTJick456sb9LEuyKZa3Bel8juqLM',
    authDomain: 'ralli-ab1e1.firebaseapp.com',
  },
  facebook: {
    appId: '745789565950307',
    permissions: 'pages_show_list,pages_messaging',
    apiUrl: 'https://graph.facebook.com/v5.0',
  },
  sentry: {
    dsn: 'https://2bddd9e3355f48a0bc461caeae721de8@sentry.io/1991166',
  },
  stripe: {
    apiKey: 'pk_test_2DSi27NrZOX4GgKyPaUEk77H00zIx9oWdM',
  },
};
