/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ View imports ------ */
import PastPayments from './past-payments.view';

function PastPaymentsContainer() {
  const [view, setView] = useState('loading');
  const [invoices, setInvoices] = useState(null);

  const [loadingMore, setLoadingMore] = useState(false);
  const [canLoadMore, setCanLoadMore] = useState(true);

  async function fetchData() {
    let loadedInvoices = null;
    try {
      const { data } = (await api.get('/invoice?limit=20')).data;
      loadedInvoices = data;
    } catch (e) {
      // Silently ignore.
    }

    if (loadedInvoices) {
      setInvoices(loadedInvoices);
      if (!loadedInvoices.length) {
        setCanLoadMore(false);
      }

      setView('main');
    } else {
      setView('error');
    }
  }

  async function loadMoreInvoices() {
    if (loadingMore) {
      return;
    }

    const lastInvoice = invoices[invoices.length - 1];
    if (!lastInvoice) {
      setCanLoadMore(false);
      return;
    }

    setLoadingMore(true);

    let loadedInvoices = null;
    try {
      const { data } = (await api.get(`/invoice?limit=20&before=${lastInvoice.stripe_id}`)).data;
      loadedInvoices = data;
    } catch (e) {
      // Silently ignore
    }

    if (loadedInvoices) {
      if (loadedInvoices.length) {
        setInvoices(invoices.concat(loadedInvoices));
      } else {
        setCanLoadMore(false);
      }
    } else {
      toast('Something went wrong. Please try again.');
      setCanLoadMore(false);
    }

    setLoadingMore(false);
  }

  useDidMount(() => {
    fetchData();
  });

  return (
    <PastPayments
      canLoadMore={canLoadMore}
      invoices={invoices}
      loadingMore={loadingMore}
      onLoadMore={loadMoreInvoices}
      view={view}
    />
  );
}

export default PastPaymentsContainer;
