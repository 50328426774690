/* ------ Module imports ------ */
import React from 'react';
import { Link } from 'react-router-dom';

/* ------ Common imports ------ */
import Card from 'common/card';
import Icon from 'common/icon';
import Loading from 'common/loading';

function Channels(props) {
  const {
    chatPlugins,
    pages,
    view,
  } = props;

  if (view === 'loading') {
    return <Loading />;
  }

  function renderPage(page) {
    return (
      <Link
        className="flex items-center justify-between w-full px-6 py-4 border-b border-gray-300 last:border-b-0 hover:bg-gray-100"
        key={page.id}
        to={`/pages/${page.id}`}
      >
        <div className="flex items-center">
          <div className="rounded-lg rounded-lg w-10 h-10 bg-center bg-cover" style={{ backgroundImage: `url(${page.profile_picture})` }} />
          <div className="ml-4">
            <p className="text-sm text-gray-800 font-medium">{page.name}</p>
            <p className="mt-1 text-xs text-gray-600">{`Added by ${page.added_by.first_name} ${page.added_by.last_name}`}</p>
          </div>
        </div>

        <div>
          <Icon
            className="text-gray-600"
            icon="right-chevron"
          />
        </div>
      </Link>
    );
  }

  function renderPages() {
    return (
      <Card className="mt-10 overflow-hidden">
        <Card.Title
          action={{ label: 'Setup new page', href: '/pages-add' }}
        >
          Facebook Pages
        </Card.Title>

        {pages.map(renderPage)}
        {pages.length === 0 && (
          <div className="px-6 py-6 text-center">
            <p className="text-gray-600 font-medium text-sm">No Facebook Pages have been added</p>
            <p className="text-gray-600 mt-1 text-sm">Adding a Facebook Page will allow you to handle messages you receive there via Ralli</p>
          </div>
        )}
      </Card>
    );
  }

  function renderChatPlugin(chatPlugin) {
    return (
      <Link
        className="flex items-center justify-between block px-6 py-4 border-b border-gray-300 last:border-b-0 hover:bg-gray-100"
        key={chatPlugin.id}
        to={`/chat-plugin/${chatPlugin.id}`}
      >
        <div className="flex items-center">
          <div className="rounded-lg rounded-lg w-10 h-10 bg-center bg-cover" style={{ backgroundImage: `url(${chatPlugin.profile_picture})` }} />
          <p className="ml-4 text-gray-800 text-sm font-medium">{chatPlugin.name}</p>
        </div>
        <div>
          <Icon
            className="text-gray-600"
            icon="right-chevron"
          />
        </div>
      </Link>
    );
  }

  function renderChatPlugins() {
    return (
      <Card className="mt-10 overflow-hidden">
        <Card.Title
          action={{ label: 'Add chat plugin', href: '/chat-plugin/add' }}
        >
          Website Chat Plugins
        </Card.Title>

        {chatPlugins.map(renderChatPlugin)}
        {chatPlugins.length === 0 && (
          <div className="px-6 py-6 text-center">
            <p className="text-gray-600 font-medium text-sm">No website plugins have been set up</p>
            <p className="text-gray-600 mt-1 text-sm">You can add the Ralli chat plugin to your website so customers can chat to you from there</p>
          </div>
        )}
      </Card>
    );
  }

  return (
    <div>
      <h1 className="font-heading text-2xl font-medium text-gray-800">Messaging Channels</h1>

      {renderPages()}
      {renderChatPlugins()}
    </div>
  );
}

export default Channels;
