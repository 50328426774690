/* ------ Module imports ------ */
import io from 'socket.io-client';

/* ------ Config ------ */
import config from 'config';

/* ------ Helpers ------ */
import auth from 'helpers/auth';

const handlers = {};

async function init() {
  const token = await auth.getToken();

  /* ------ Setup the websocket connection ------ */
  const socket = io(`${config.urls.sockets}/standard`);
  socket.emit('auth', `${token}`);

  /* ------ If the socket is disconnects and reconnects we need to re-send the auth event ------ */
  socket.on('reconnect', () => {
    socket.emit('auth', `${token}`);
  });

  async function handle(eventName, message) {
    if (eventName === 'conv_new') {
      const id = message.conversation;

      if (handlers.conv_new) {
        handlers.conv_new.forEach(fn => fn(id));
      }
    }

    if (eventName === 'conv_event') {
      if (handlers.conv_event) {
        handlers.conv_event.forEach(fn => fn(message));
      }
    }

    if (eventName === 'conv_assigned') {
      const id = message.conversation;

      if (handlers.conv_assigned) {
        handlers.conv_assigned.forEach(fn => fn(id, message.is_unassigned));
      }
    }
  }

  const eventTypes = ['conv_assigned', 'conv_new', 'conv_event'];
  eventTypes.forEach(eventName => {
    socket.on(eventName, data => handle(eventName, data));
  });
}

function on(eventType, fn) {
  if (!handlers[eventType]) {
    handlers[eventType] = [];
  }

  handlers[eventType].push(fn);

  /* ------ Returns a function to unsubscribe from the event ------ */
  return () => {
    handlers[eventType] = handlers[eventType].filter(f => f !== fn);
  };
}

export default {
  init,
  on,
};
