/* ------ Module imports ------ */
import React, { useContext, useRef, useState } from 'react';

/* ------ Context ------ */
import ConversationContext from 'common/conversation/context';

/* ------ Helpers ------ */
import useOnClickOutside from 'helpers/hooks/use-on-click-outside';

/* ------ Local components ------ */
import AddUsers from './add-users';
import SelectCategory from './select-category';

function Dropdown(props) {
  const { onClose } = props;

  const { conversation } = useContext(ConversationContext);

  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, onClose);

  const [view, setView] = useState('category');
  const [users, setUsers] = useState([]);

  function onCategoryChanged(category) {
    if (!category) {
      onClose();
      return;
    }

    const convUsers = {};
    conversation.users.forEach(mu => {
      convUsers[mu.id] = true;
    });

    const categoryUsers = category.users.filter(u => !convUsers[u.id]);
    if (categoryUsers.length > 0) {
      setUsers(categoryUsers);
      setView('users');
    } else {
      onClose();
    }
  }

  function renderAddUsers() {
    return (
      <AddUsers
        onClose={onClose}
        users={users}
      />
    );
  }

  return (
    <div
      className="z-10 mt-3 popover popover-left w-64 absolute top-0 left-0 bg-white rounded-lg shadow-lg border border-gray-300 pt-4 pb-3"
      ref={dropdownRef}
    >
      {view === 'category' && <SelectCategory conversation={conversation} onCategoryChanged={onCategoryChanged} />}
      {view === 'users' && renderAddUsers()}
    </div>
  );
}

export default Dropdown;
