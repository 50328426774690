/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import CategorySelector from 'common/category-selector';
import Input from 'common/input';

function Edit(props) {
  const {
    category,
    errors,
    fbId,
    name,
    onCancel,
    onCategoryChanged,
    onFbIdChanged,
    onNameChanged,
    onSubmit,
    page,
    submitting,
  } = props;

  return (
    <form
      disabled={submitting}
      onSubmit={onSubmit}
    >
      <Input
        className="mt-4 mb-4"
        disabled={submitting}
        error={errors.name}
        label="Name"
        onChange={onNameChanged}
        value={name}
      />
      <Input
        className="mb-4"
        disabled={submitting}
        error={errors.id}
        label="Facebook Ad ID"
        onChange={onFbIdChanged}
        value={fbId}
      />
      <div className="mb-6">
        <span className="inline-block text-sm text-gray-800 font-medium mb-1">Choose which category to assign messages from this ad to</span>
        <CategorySelector
          disabled={submitting}
          onChange={onCategoryChanged}
          page={page}
          value={category}
        />
      </div>
      <Button
        loading={submitting}
        type="submit"
      >
        Confirm
      </Button>
      <button
        className="text-sm uppercase font-semibold text-gray-600 ml-6"
        disabled={submitting}
        onClick={onCancel}
        type="button"
      >
        Cancel
      </button>
    </form>
  );
}

export default Edit;
