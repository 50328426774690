/* ------ Module imports ------ */
import React from 'react';
import FacebookLoginButton from 'react-facebook-login/dist/facebook-login-render-props';

/* ------ Config ------ */
import config from 'config';

/* ------ Common imports ------ */
import Icon from 'common/icon';
import LoadingSpinner from 'common/loading-spinner';

function FacebookButton(props) {
  const {
    loading,
    onComplete,
  } = props;

  return (
    <FacebookLoginButton
      appId={config.facebook.appId}
      callback={onComplete}
      disableMobileRedirect
      scope={config.facebook.permissions}
      render={renderProps => {
        const isLoading = renderProps.isProcessing || loading;

        return (
          <button
            className="text-white rounded-lg p-2 pr-4 flex items-center focus:outline-none"
            disabled={isLoading}
            onClick={renderProps.onClick}
            style={{ backgroundColor: '#4267B2' }}
            type="button"
          >
            <Icon className="text-xl align-middle" icon="facebook" />

            <span className="relative flex items-center">
              <span className={`align-middle font-medium ml-4 text-sm ${isLoading ? 'invisible' : ''}`}>Continue with Facebook</span>
              {isLoading && (
                <span className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                  <LoadingSpinner small white />
                </span>
              )}
            </span>
          </button>
        );
      }}
    />
  );
}

export default FacebookButton;
