/* ------ Module imports ------ */
import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

function SidebarConversation(props) {
  const {
    conversation,
    selected,
  } = props;

  function getPreviewText() {
    if (conversation.last_timeline_item) {
      if (conversation.last_timeline_item.type === 'message') {
        const { message } = conversation.last_timeline_item;

        if (message.attachment) {
          const messageSender = conversation.last_timeline_item.user
            ? conversation.last_timeline_item.user.first_name
            : conversation.name;

          return `${messageSender} sent an attachment`;
        }

        return message.text;
      }

      if (conversation.last_timeline_item.type === 'category_change') {
        return 'The category was changed';
      }

      if (conversation.last_timeline_item.type === 'user_added') {
        return 'A user was added to this conversation';
      }

      if (conversation.last_timeline_item.type === 'user_removed') {
        return 'A user was removed from this conversation';
      }

      if (conversation.last_timeline_item.type === 'user_left') {
        return 'A user left this conversation';
      }

      if (conversation.last_timeline_item.type === 'user_left') {
        return 'A user left this conversation';
      }

      if (conversation.last_timeline_item.type === 'conv_archived') {
        return 'This conversation was archived';
      }

      if (conversation.last_timeline_item.type === 'conv_activated') {
        return 'This conversation was made active';
      }

      if (conversation.last_timeline_item.type === 'url_referral') {
        return 'Conversation started from website';
      }
    }

    return 'Send them a message';
  }

  return (
    <Link
      to={`/inbox/${conversation.id}`}
      className={`mb-2 block rounded-lg w-full p-4 ${selected ? 'bg-gray-200' : 'bg-white hover:bg-gray-200'}`}
    >
      <div className="flex items-start w-full">
        <div>
          <div
            className="h-8 w-8 rounded-full bg-cover bg-center relative"
            style={{ backgroundImage: `url(${conversation.profile_picture})` }}
          >
            <img
              alt={conversation.type}
              className="absolute right-0 bottom-0 -mr-2 -mb-2 w-5 rounded-full border-2 border-white"
              src={`/assets/img/${conversation.type}.svg`}
            />
          </div>
        </div>

        <div className="ml-4 flex-1 overflow-hidden">
          <div className="flex items-center justify-between w-full">
            <div className="overflow-hidden">
              <p className="text-sm font-medium text-gray-800">{conversation.name}</p>
              {conversation.last_timeline_item && <p className="text-xs text-gray-600 truncate mt-1">{moment(conversation.last_timeline_item.timestamp).fromNow()}</p>}
            </div>

            {conversation.unread_notifications && (
              <div className="bg-red-600 w-2 h-2 rounded-full inline-block flex-shrink-0" />
            )}
          </div>

          {conversation.last_timeline_item && (
            <div className="mt-3">
              <p className="text-xs text-gray-800 truncate">{getPreviewText()}</p>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
}

export default SidebarConversation;
