/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Icon from 'common/icon';
import Loading from 'common/loading';
import PlainButton from 'common/plain-button';
import Tab from 'common/tab';

/* ------ Local components ------ */
import InviteCard from './invite-card';
import User from './user';

function UserList(props) {
  const {
    activeUsers,
    currentUser,
    invitedUsers,
    onChangeTab,
    onCloseInviteCard,
    onInviteAdded,
    onShowInviteCard,
    onUserDeleted,
    onUserUpdated,
    showInviteCard,
    tab,
    view,
  } = props;

  function renderErrorView() {
    return (
      <div className="p-10 mt-10 bg-white shadow-lg rounded-lg">
        <div className="bg-orange-300 text-orange-500 flex justify-center items-center rounded-full w-10 h-10">
          <Icon icon="warning" />
        </div>
        <p className="mt-6 font-medium text-gray-800 mb-2 text-xl">Could not load users</p>
        <p className="text-gray-800 text-sm">Something went wrong loading the users for your business. Please try again or contact us if you keep having this problem.</p>
      </div>
    );
  }

  function renderLoadingView() {
    return <Loading />;
  }

  function renderUsers() {
    const users = tab === 'active' || invitedUsers.length === 0 ? activeUsers : invitedUsers;

    return users.map((user, i) => (
      <User
        isCurrentUser={currentUser.id === user.id}
        isLastChild={i === users.length - 1}
        key={user.id}
        onUserDeleted={() => onUserDeleted(user)}
        onUserUpdated={onUserUpdated}
        totalUsers={activeUsers.length}
        user={user}
      />
    ));
  }

  function renderMainView() {
    return (
      <div className="mt-10 bg-white shadow-lg rounded-lg">
        { invitedUsers.length > 0 && (
          <div className="flex items-center px-6 border-b border-gray-300">
            <Tab
              onClick={() => onChangeTab('active')}
              selected={tab === 'active'}
            >
              Active Users
            </Tab>

            <Tab
              onClick={() => onChangeTab('invited')}
              selected={tab === 'invited'}
            >
              Invited Users
            </Tab>
          </div>
        )}

        {renderUsers()}
      </div>
    );
  }

  return (
    <div>
      <h1 className="font-heading text-2xl font-medium text-gray-800">Users</h1>
      {showInviteCard ? (
        <InviteCard
          onCloseInviteCard={onCloseInviteCard}
          onInviteAdded={onInviteAdded}
        />
      ) : (
        <PlainButton onClick={onShowInviteCard}>+ Invite New User</PlainButton>
      )}

      {view === 'error' && renderErrorView()}
      {view === 'loading' && renderLoadingView()}
      {view === 'main' && renderMainView()}
    </div>
  );
}

export default UserList;
