/* ----- Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ----- View import ------ */
import EditCard from './edit-card.view';

function EditCardContainer(props) {
  const {
    onClose,
    onUserUpdated,
    user,
  } = props;

  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({});

  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);

  function validate() {
    const validationErrors = {};

    if (!firstName) {
      validationErrors.firstName = true;
    }
    if (!lastName) {
      validationErrors.lastName = true;
    }

    return validationErrors;
  }

  async function onSubmit() {
    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    setSaving(true);

    let updatedUser = null;
    try {
      const { data } = (await api.patch(`/user/${user.id}?expand[]=business`, { first_name: firstName, last_name: lastName })).data;
      updatedUser = data;
    } catch (e) {
      toast('Something went wrong. Please try again.');
    }

    setSaving(false);

    if (updatedUser) {
      onUserUpdated(updatedUser);
      onClose();
    }
  }

  return (
    <EditCard
      errors={errors}
      firstName={firstName}
      lastName={lastName}
      onClose={onClose}
      onFirstNameChanged={setFirstName}
      onLastNameChanged={setLastName}
      onSubmit={onSubmit}
      saving={saving}
    />
  );
}

export default EditCardContainer;
