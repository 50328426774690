/* ------ Module imports ------ */
import React, { useState } from 'react';
import Select, { components } from 'react-select';

/* ------ Data import ------ */
import timezones from './timezones';

function SingleValue({ children, ...props }) {
  const { data } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.SingleValue {...props}>
      <span className="text-sm text-gray-800 font-medium">{data.label}</span>
    </components.SingleValue>
  );
}

function Option({ children, ...props }) {
  const { data } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.Option {...props}>
      <span className="text-sm text-gray-800 font-medium">{data.label}</span>
    </components.Option>
  );
}

function TimezoneSelector(props) {
  const {
    error,
    onChange,
    value,
  } = props;

  const [menuOpen, setMenuOpen] = useState(false);

  const options = timezones.map(timezone => ({
    value: timezone,
    label: timezone.replace('_', ' '),
  }));

  let currentValue = null;
  options.forEach(opt => {
    if (opt.value === value) {
      currentValue = opt;
    }
  });

  return (
    <div className="flex-1 mb-4 relative">
      <Select
        autoFocus
        classNamePrefix="select"
        components={{ Option, SingleValue }}
        menuIsOpen={menuOpen}
        options={options}
        onChange={nv => onChange(nv.value)}
        onMenuOpen={() => setMenuOpen(true)}
        onMenuClose={() => setMenuOpen(false)}
        value={currentValue}
      />
      {error && <div className="w-2 h-2 absolute mr-2 mt-2 top-0 right-0 bg-red-600 rounded-full" />}
    </div>
  );
}

export default TimezoneSelector;
