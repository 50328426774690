/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import Delete from './delete.view';

function DeleteContainer(props) {
  const { page } = props;

  const [view, setView] = useState('main');
  const [submitting, setSubmitting] = useState(false);

  async function onDelete() {
    setSubmitting(true);

    let success = false;
    try {
      await api.remove(`/page/${page.id}`);
      success = true;
    } catch (e) {
      toast('Could not delete page. Something went wrong.');
    }

    setSubmitting(false);
    if (success) {
      window.location = '/channels';
    }
  }

  return (
    <Delete
      onConfirmDelete={onDelete}
      onStartDelete={() => setView('deleting')}
      submitting={submitting}
      view={view}
    />
  );
}

export default DeleteContainer;
