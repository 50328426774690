/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import PastDue from './past-due.view';

function PastDueContainer(props) {
  const {
    onPaid,
    onUpdatePaymentDetails,
  } = props;

  const [tryingAgain, setTryingAgain] = useState(false);

  async function onTryAgain() {
    setTryingAgain(true);

    let success = false;
    try {
      await api.post('/subscription/pay');
      success = true;
    } catch (e) {
      if (e && e.response && e.response.data) {
        const { code } = e.response.data;
        if (code === 'declined') {
          toast('Your payment method was declined');
          setTryingAgain(false);
          return;
        }
      }

      // Silently ignore - `success` will be false so will show an error
    }

    setTryingAgain(false);

    if (success) {
      onPaid();
    } else {
      toast('Something went wrong. Please try again.');
    }
  }

  return (
    <PastDue
      onTryAgain={onTryAgain}
      onUpdatePaymentDetails={onUpdatePaymentDetails}
      tryingAgain={tryingAgain}
    />
  );
}

export default PastDueContainer;
