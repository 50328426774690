/* ------ Module imports ------ */
import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';

/* ------ Helpers ------ */
import money from 'helpers/money';

/* ------ Common imports ------ */
import Button from 'common/button';
import CountrySelector from 'common/country-selector';

function Form(props) {
  const {
    country,
    onCountryChanged,
    onSubmit,
    pricingPlan,
    submitting,
  } = props;

  return (
    <div>
      <h1 className="font-heading text-2xl font-medium text-gray-800">Set up subscription</h1>

      <div className="mt-10 bg-white shadow-lg rounded-lg p-6">
        <span className="inline-block text-sm text-gray-800 font-medium mb-1">Which country are you subscribing from?</span>
        <CountrySelector
          onChange={onCountryChanged}
          value={country}
        />

        {pricingPlan && (
          <>
            <div className="mt-6">
              <p className="inline-block text-sm text-gray-800 font-medium mb-1">Your Ralli plan</p>
              <p>
                <span className="align-top">$</span>
                <span className="text-2xl">{money(pricingPlan.price)}</span>
                <span className="ml-2 text-sm font-semibold text-gray-600">{`${pricingPlan.currency.toUpperCase()} /month`}</span>
              </p>
            </div>

            <div className="mt-6">
              <p className="inline-block text-sm text-gray-800 font-medium mb-1">Enter your card details</p>
              <div className="px-3 py-2 border border-gray-300 rounded-lg mb-10">
                <CardElement options={{ hidePostalCode: true }} />
              </div>

              <Button
                loading={submitting}
                onClick={onSubmit}
              >
                Confirm subscription
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Form;
