/* ------ Module imports ------ */
import { useState } from 'react';

/* ------ Helpers ------ */
import useInterval from './use-interval';

function useRerender(delay) {
  const [timer, setTime] = useState(0);

  useInterval(() => {
    setTime(timer + 1);
  }, delay);
}

export default useRerender;
