/* ------ Module imports ------ */
import React from 'react';

function AdReferral(props) {
  const {
    conversation,
    data,
  } = props;

  const text = `${conversation.name} started this conversation by engaging with your "${data.ad.name}" ad`;

  return (
    <div className="py-2">
      <p className="text-xs text-center text-gray-600 font-medium">{text}</p>
    </div>
  );
}

export default AdReferral;
