/* ------ Module imports ------ */
import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

/* ------ Helpers ------ */
import useRerender from 'helpers/hooks/use-rerender';

function RecentConversations(props) {
  const { conversations } = props;

  useRerender(60000);

  return conversations.map(conversation => (
    <Link
      className="flex justify-between items-center p-4 mt-4 bg-white shadow-lg rounded-lg"
      key={conversation.id}
      to={`/inbox/${conversation.id}`}
    >
      <div className="flex items-center">
        <img
          alt=""
          className="h-10 w-10 mr-3 rounded-lg"
          src={conversation.profile_picture}
        />
        <div className="overflow-hidden">
          <p className="text-xs text-green-500">{conversation.name}</p>
        </div>
      </div>
      <p className="text-xs text-gray-600 mr-4">{moment(conversation.last_timeline_item.timestamp).fromNow()}</p>
    </Link>
  ));
}

export default RecentConversations;
