/* ------ Module imports ------ */
import React from 'react';
import { Route, Switch } from 'react-router-dom';

/* ------ Routes ------ */
import AddPage from './add-page';
import ViewPage from './view-page';

function Pages() {
  return (
    <Switch>
      <Route path="/pages-add" component={AddPage} />
      <Route path="/pages/:id" component={ViewPage} />
    </Switch>
  );
}

export default Pages;
