/* ------ Module imports ------ */
import React from 'react';

/* ------ Local(ish) components ------ */
import ArchiveAction from 'common/conversation/options/archive-action';

function Dropdown() {
  return (
    <div className="hidden md:block z-10 mt-3 popover popover-right w-48 absolute top-0 right-0 bg-white rounded-lg shadow-lg border border-gray-300">
      <ArchiveAction />
    </div>
  );
}

export default Dropdown;
