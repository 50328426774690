/* ------- Module imports ------ */
import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import moment from 'moment';

/* ------ Helpers ------ */
import accounts from 'helpers/accounts';
import api from 'helpers/api';
import auth from 'helpers/auth';
import history from 'helpers/history';
import socket from 'helpers/socket';
import useDidMount from 'helpers/hooks/use-did-mount';
import usePathname from 'helpers/hooks/use-pathname';
import useWatcher from 'helpers/hooks/use-watcher';
import user from 'helpers/user';

/* ------ Routes ------ */
import AddChatPlugin from 'components/add-chat-plugin';
import CategorySet from 'components/category-set';
import CategorySets from 'components/category-sets';
import Channels from 'components/channels';
import ChatPlugin from 'components/chat-plugin';
import Home from 'components/home';
import Inbox from 'components/inbox';
import Onboarding from 'components/onboarding';
import Pages from 'components/pages';
import Profile from 'components/profile';
import Settings from 'components/settings';
import Subscribe from 'components/subscribe';
import Unverified from 'components/unverified';
import Users from 'components/users';

/* ------ Context ------ */
import PlatformContext from './context';

/* ------ Local components ------ */
import BillingError from './billing-error';
import Sidebar from './sidebar';
import Nav from './nav';

function PlatformContainer() {
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(null);
  const [isUnreadConversations, setIsUnreadConversations] = useState(false);

  const currentPath = usePathname();

  async function checkNotifications() {
    let hasUnreads = null;
    try {
      const { data } = (await api.get('/conversation/unread')).data;
      hasUnreads = data.is_unread;
    } catch (e) {
      // Silently ignore
    }

    if (hasUnreads !== null) {
      setIsUnreadConversations(hasUnreads);
    }
  }

  async function checkUser() {
    const currentAccount = accounts.getCurrentAccount();
    if (!currentAccount) {
      history.push('/login');
      return;
    }

    await auth.init(currentAccount.business.auth_tenant_id);

    const loadedUser = await user.getCurrentUser();

    if (loadedUser) {
      if (!loadedUser.email_verified) {
        history.push('/unverified');
      }

      await socket.init();
      await checkNotifications();

      setCurrentUser(loadedUser);
      setLoading(false);
    }
  }

  useDidMount(() => {
    checkUser();
    return user.registerHandler(setCurrentUser);
  });

  useWatcher('conv_new', () => {
    checkNotifications();
  });

  useWatcher('conv_event', message => {
    if (currentPath !== `/inbox/${message.conversation}`) {
      checkNotifications();
    }
  });

  useWatcher('conv_assigned', id => {
    if (currentPath !== `/inbox/${id}`) {
      checkNotifications();
    }
  });

  if (loading) {
    return null;
  }

  function isTrialExpired() {
    if (currentUser.business.billing_status === 'trial') {
      return moment(currentUser.business.trial_end).isBefore(moment());
    }

    return false;
  }

  function checkBillingError() {
    if (isTrialExpired()) {
      return 'trial_expired';
    }

    if (currentUser.business.billing_status !== 'active' && currentUser.business.billing_status !== 'trial' && currentUser.business.billing_status !== 'free') {
      return 'cancelled';
    }

    return null;
  }

  const billingError = checkBillingError();

  if (billingError && currentUser.type !== 'admin') {
    return <BillingError error={billingError} />;
  }

  function renderRoutes() {
    const routes = [
      <Route key="/profile" path="/profile" component={Profile} />,
      <Route key="/inbox" path="/inbox" component={Inbox} />,
    ];

    if (currentUser.type === 'admin') {
      routes.push(<Route key="/category-sets/:id" path="/category-sets/:id" component={CategorySet} />);
      routes.push(<Route key="/category-sets" path="/category-sets" component={CategorySets} />);
      routes.push(<Route key="/chat-plugin/add" path="/chat-plugin/add" component={AddChatPlugin} />);
      routes.push(<Route key="/channels" path="/channels" component={Channels} />);
      routes.push(<Route key="/chat-plugin/:id" path="/chat-plugin/:id" component={ChatPlugin} />);
      routes.push(<Route key="/pages*" path="/pages*" component={Pages} />);
      routes.push(<Route key="/users" path="/users" component={Users} />);
      routes.push(<Route key="/settings" path="/settings" component={Settings} />);
      routes.push(<Route key="/subscribe" path="/subscribe" component={Subscribe} />);
      routes.push(<Route exact key="/" path="/" component={Home} />);
      routes.push(<Route key="*" path="*" render={() => <Redirect to="/" />} />);
    } else {
      routes.push(<Route key="*" path="*" render={() => <Redirect to="/inbox" />} />);
    }

    return routes;
  }

  const context = {
    user: currentUser,
    billingError,
    isUnreadConversations,
    onPollUnreadConversations: checkNotifications,
  };

  const onboardingNotStarted = currentUser.business.onboarding_status === 'not_started' && currentUser.type === 'admin';

  function renderOnboarding() {
    return (
      <Switch>
        <Route path="/onboarding" component={Onboarding} />
        <Redirect to="/onboarding" />
      </Switch>
    );
  }

  function renderUnverified() {
    return (
      <Switch>
        <Route path="/unverified" component={Unverified} />
      </Switch>
    );
  }

  function renderVerified() {
    return (
      <div>
        {currentUser.type === 'admin' && (
          <div className={`z-50 transition-transform duration-500 transform xl:transform-none ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'} fixed top-0 left-0`}>
            <Sidebar onClose={() => setSidebarOpen(false)} />
          </div>
        )}

        <div className={currentUser.type === 'admin' ? 'xl:pl-80' : ''}>
          <Nav onToggleSidebar={() => setSidebarOpen(!sidebarOpen)} />

          <div className="relative">
            <div className={`max-w-2xl mx-auto flex-1 px-4 ${(billingError && currentPath !== '') ? 'pt-48' : 'pt-32'} pb-16`}>
              <Switch>
                {renderRoutes()}
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <PlatformContext.Provider value={context}>
      {currentUser.email_verified && onboardingNotStarted && renderOnboarding()}
      {currentUser.email_verified && !onboardingNotStarted && renderVerified()}
      {!currentUser.email_verified && renderUnverified()}
    </PlatformContext.Provider>
  );
}

export default PlatformContainer;
