/* ------ Module imports ------ */
import React, { useContext, useRef, useState } from 'react';

/* ------ Context ------ */
import ConversationContext from 'common/conversation/context';

/* ------ Helpers ------ */
import useOnClickOutside from 'helpers/hooks/use-on-click-outside';

/* ------ Common imports ------ */
import Icon from 'common/icon';

/* ------ Local components ------ */
import Dropdown from './dropdown';

function Users() {
  const { conversation } = useContext(ConversationContext);
  const { users } = conversation;

  const [expanded, setExpanded] = useState(false);

  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setExpanded(false));

  const usersToRender = users.slice(0, 3);

  return (
    <div
      className="flex flex-col-reverse md:flex-col mt-2 md:mt-0"
      ref={dropdownRef}
    >
      <div>
        <button
          className="h-full focus:outline-none flex items-center border border-gray-300 hover:shadow rounded-lg pl-3 pr-2 py-1"
          onClick={() => setExpanded(!expanded)}
          type="button"
        >
          <div>
            {usersToRender.map(user => (
              <div
                className="align-middle inline-block border-2 -ml-2 border-white bg-cover bg-center rounded-full w-8 h-8"
                key={user.id}
                style={{ backgroundImage: `url(${user.profile_picture})` }}
              />
            ))}
            {users.length > 3 && (
              <div
                className="bg-gray-300 align-middle inline-block border-2 -ml-2 border-white bg-cover bg-center rounded-full w-8 h-8"
              >
                <div className="text-xs text-gray-800 font-semibold w-full h-full flex items-center justify-center">
                  {`+${users.length - 3}`}
                </div>
              </div>
            )}
            {users.length === 0 && (
              <span className="flex items-center text-xs text-orange-500 font-semibold mr-2">
                <Icon className="mr-2" icon="warning" />
                No users
              </span>
            )}
          </div>

          <Icon className="text-xs text-gray-600 ml-2" icon={expanded ? 'up-chevron' : 'down-chevron'} />
        </button>
      </div>

      {expanded && (
        <div className="relative">
          <Dropdown />
        </div>
      )}
    </div>
  );
}

export default Users;
