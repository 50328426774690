/* ------ Module imports ------ */
import React from 'react';
import moment from 'moment';

/* ------ Local components ------ */
import Cancelled from './cancelled';
import PastDue from './past-due';
import TrialExpired from './trial-expired';

function BillingBanner(props) {
  const { business } = props;

  if (business.billing_status === 'trial' && moment(business.trial_end).isBefore(moment())) {
    return <TrialExpired />;
  }

  if (business.billing_status === 'cancelled') {
    return <Cancelled />;
  }

  if (business.billing_status === 'past_due') {
    return <PastDue />;
  }

  return null;
}

export default BillingBanner;
