/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import UserSelector from 'common/user-selector';

function AddUser(props) {
  const {
    error,
    loading,
    onCancel,
    onChange,
    onSubmit,
    selectedUsers,
    submitting,
    users,
  } = props;

  return (
    <div className="mt-4">
      <UserSelector
        data={users}
        isMulti
        error={error}
        loading={loading}
        onCancel={onCancel}
        onChange={onChange}
        onConfirm={onSubmit}
        submitting={submitting}
        value={selectedUsers}
      />
    </div>
  );
}

export default AddUser;
