/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import CategorySet from './category-set.view';

function CategorySetContainer(props) {
  const {
    categorySet,
    chatPluginId,
    onCategorySetUpdated,
  } = props;

  const [adding, setAdding] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  async function onRemove() {
    setSubmitting(true);

    let success = false;
    try {
      await api.patch(`/chat_plugin/${chatPluginId}`, { category_set: null });
      success = true;
    } catch (e) {
      // Silently ignore.
    }

    setSubmitting(false);

    if (success) {
      setRemoving(false);
      onCategorySetUpdated(null);
    } else {
      toast('Something went wrong. Please try again.');
    }
  }

  return (
    <CategorySet
      adding={adding}
      categorySet={categorySet}
      chatPluginId={chatPluginId}
      onAdd={() => setAdding(true)}
      onCancelAdding={() => setAdding(false)}
      onCategorySetUpdated={onCategorySetUpdated}
      onRemove={onRemove}
      onToggleRemoving={() => setRemoving(!removing)}
      removing={removing}
      submitting={submitting}
    />
  );
}

export default CategorySetContainer;
