/* ------ Module imports ------ */
import React from 'react';

function SimpleLayout(props) {
  const { children } = props;

  return (
    <div className="px-6">
      <div className="mx-auto w-full max-w-lg py-6 sm:py-16">
        <img
          alt="Ralli logo"
          className="w-16 sm:w-24 sm:mx-auto mb-16"
          src="/assets/img/logo-round.svg"
        />

        <div className="w-full sm:bg-white sm:p-10 rounded-lg sm:shadow-xl">
          {children}
        </div>
      </div>
    </div>
  );
}

export default SimpleLayout;
