/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import Input from 'common/input';
import PlainButton from 'common/plain-button';

function InviteCard(props) {
  const {
    email,
    onChange,
    onCloseInviteCard,
    onSubmit,
    submitting,
  } = props;

  return (
    <div className="mt-10 bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="px-6 py-4 bg-white border-b border-gray-300">
        <p className="font-medium text-gray-800">Invite new user</p>
      </div>
      <form
        className="p-6 pt-4"
        disabled={submitting}
        onSubmit={onSubmit}
      >
        <Input
          className="mb-6"
          disabled={submitting}
          label="Enter their email address"
          onChange={onChange}
          value={email}
        />
        <div className="flex items-center">
          <Button
            loading={submitting}
            type="submit"
          >
            SEND INVITE
          </Button>
          <PlainButton
            className="ml-6"
            color="gray"
            disabled={submitting}
            onClick={onCloseInviteCard}
            type="button"
          >
            CANCEL
          </PlainButton>
        </div>
      </form>
    </div>
  );
}

export default InviteCard;
