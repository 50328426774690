/* ------ Module imports ------ */
import React from 'react';
import moment from 'moment';

/* ------ Helpers ------ */
import money from 'helpers/money';

/* ------ Common imports ------ */
import Button from 'common/button';
import Loading from 'common/loading';

/* ------ Local components ------ */
import Actions from './actions';
import BillingEmail from './billing-email';
import Cancel from './cancel';
import Card from './card';
import ChangeCard from './change-card';
import ConnectAccount from './connect-account';
import Expired from './expired';
import PastDue from './past-due';
import PendingCancellation from './pending-cancellation';

function Billing(props) {
  const {
    business,
    error,
    loading,
    onBillingEmailChanged,
    onCancel,
    onCancelled,
    onCardChanged,
    onChangeCard,
    onPaid,
    onReactivated,
    onRemoveSubview,
    onResumed,
    onSetupSubscription,
    subscription,
    subview,
  } = props;

  function renderError() {
    // TODO.
    return null;
  }

  function renderMain() {
    if (subview === 'cancel') {
      return (
        <Cancel
          onCancel={onRemoveSubview}
          onCancelled={onCancelled}
        />
      );
    }

    if (subview === 'change_card') {
      return (
        <ChangeCard
          billingStatus={business.billing_status}
          onCancel={onRemoveSubview}
          onSuccess={onCardChanged}
        />
      );
    }

    if (business.connect_account) {
      return (
        <ConnectAccount id={business.id} />
      );
    }

    const trialExpired = moment(business.trial_end).isBefore(moment());

    return (
      <>
        {subscription && (
          <>
            {!subscription.cancel_at_period_end && (
              <div className="px-6 py-4">
                <p className="font-semibold text-sm text-gray-800 mb-2">Your Ralli subscription</p>
                <p>
                  <span className="align-top">$</span>
                  <span className="text-2xl">{money(subscription.plan.price)}</span>
                  <span className="ml-2 text-sm font-semibold text-gray-600">{`${subscription.plan.currency.toUpperCase()} /month`}</span>
                </p>

                {business.billing_status === 'active' && (
                  <>
                    <p className="mt-6 font-semibold text-sm text-gray-800">Next payment date</p>
                    <p>
                      <span className="text-sm text-gray-800">{moment(subscription.current_period_end).format('DD MMMM')}</span>
                    </p>
                  </>
                )}

                <p className="mt-6 font-semibold text-sm text-gray-800 mb-2">Card details</p>
                <Card card={subscription.card} />

                <BillingEmail
                  email={subscription.billing_email}
                  onBillingEmailChanged={onBillingEmailChanged}
                />
              </div>
            )}

            {business.billing_status === 'past_due' && (
              <PastDue
                onPaid={onPaid}
                onUpdatePaymentDetails={onChangeCard}
              />
            )}

            {business.billing_status === 'active' && subscription.cancel_at_period_end && (
              <PendingCancellation
                onResumed={onResumed}
                periodEnd={subscription.current_period_end}
              />
            )}

            {business.billing_status === 'cancelled' && (
              <Expired
                existingSubscription={subscription}
                onReactivated={onReactivated}
                onUpdatePaymentDetails={onChangeCard}
              />
            )}
          </>
        )}

        {!subscription && (
          <div className="px-6 py-4 border-b border-gray-300">
            {business.billing_status === 'trial' && (
              <p className="font-semibold text-sm text-orange-500">
                {!trialExpired && `Free trial expires ${moment(business.trial_end).fromNow()} (on ${moment(business.trial_end).format('DD MMMM')})`}
                {trialExpired && `Free trial expired on ${moment(business.trial_end).format('DD MMMM')}`}
              </p>
            )}
            {business.billing_status !== 'trial' && business.billing_status !== 'free' && (
              <p className="font-semibold text-sm text-orange-500">
                You do not currently have an active Ralli subscription.
              </p>
            )}

            {business.billing_status === 'free' && (
              <p className="text-sm text-gray-800">
                You must be pretty special, because you&apos;re on our free plan!
              </p>
            )}

            {business.billing_status !== 'free' && (
              <>
                <p className="text-sm mb-4 text-gray-800">{`You will need to set up a subscription to continue using Ralli${!trialExpired ? ' after your trial expires' : ''}.`}</p>
                <Button onClick={onSetupSubscription}>Set Up Subscription</Button>
              </>
            )}
          </div>
        )}
      </>
    );
  }

  function renderActions() {
    if (loading || !subscription || (business.billing_status !== 'active' && business.billing_status !== 'past_due') || subscription.cancel_at_period_end) {
      return null;
    }

    return (
      <Actions
        onCancel={onCancel}
        onChangeCard={onChangeCard}
      />
    );
  }

  return (
    <div className="mt-10 bg-white shadow-lg rounded-lg">
      <div className="flex items-center justify-between px-6 py-4 bg-white border-b border-gray-300 rounded-t-lg">
        <p className="font-medium text-gray-800 text">Billing details</p>
        {renderActions()}
      </div>
      {loading && (
        <div className="px-6 py-4 border-b border-gray-300">
          <Loading />
        </div>
      )}
      {!loading && (
        <>
          {error && renderError()}
          {!error && renderMain()}
        </>
      )}
    </div>
  );
}

export default Billing;
