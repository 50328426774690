/* ------ Module imports ------ */
import React, { useContext } from 'react';
import moment from 'moment';

/* ------ Context ------ */
import PlatformContext from 'components/platform/context';

/* ------ Local components ------ */
import BillingError from './billing-error';
import Dashboard from './dashboard';

function Home() {
  const { user } = useContext(PlatformContext);

  function getBillingError() {
    if (user.business.billing_status === 'cancelled') {
      return 'cancelled';
    }

    if (user.business.billing_status === 'trial') {
      const trialExpired = moment(user.business.trial_end).isBefore(moment());
      if (trialExpired) {
        return 'trial_expired';
      }
    }

    return null;
  }

  const billingError = getBillingError();

  return (
    <>
      <h1 className="font-heading text-2xl font-medium text-gray-800 mb-10">{user.business.name}</h1>

      {billingError && <BillingError error={billingError} />}
      {!billingError && <Dashboard />}
    </>
  );
}

export default Home;
