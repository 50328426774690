/* ------ Module imports ------ */
import React from 'react';
import { Link } from 'react-router-dom';

/* ------ Common imports ------ */
import Button from 'common/button';
import Card from 'common/card';
import Icon from 'common/icon';

function PagesToSetup(props) {
  const {
    onSetupPage,
    pages,
    submitting,
  } = props;

  function renderNotConnected(page) {
    const isAdmin = page.tasks.indexOf('MANAGE' >= 0);
    if (!isAdmin) {
      return (
        <p className="text-gray-600 font-medium text-xs">You need to be an admin of this page to connect it</p>
      );
    }

    return (
      <Button
        disabled={submitting && submitting !== page.id}
        loading={submitting === page.id}
        onClick={() => onSetupPage(page)}
      >
        Connect
      </Button>
    );
  }

  function renderPage(page) {
    return (
      <div
        className="flex items-center justify-between w-full px-6 py-4 border-b border-gray-300 last:border-b-0"
        key={page.id}
      >
        <div className="flex items-center">
          <div className="rounded-lg rounded-lg w-10 h-10 bg-center bg-cover" style={{ backgroundImage: `url(${page.picture.data.url})` }} />
          <div className="ml-4">
            <p className="text-sm text-gray-800">{page.name}</p>
          </div>
        </div>
        <div>
          {!page.connected && renderNotConnected(page)}

          {page.connected && (
            <div className="text-right">
              <div className="flex items-center">
                <Icon className="text-xs text-green-500" icon="check" />
                <span className="ml-2 text-sm font-semibold text-green-500">Connected</span>
              </div>
              <Link
                className="text-blue-500 font-semibold text-xs"
                target="_blank"
                to={`/pages/${page.ralli_id}`}
              >
                Go to this page
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <Card>
      <Card.Title>Select pages to connect</Card.Title>

      {pages && pages.map(renderPage)}
      {!pages.length && (
        <p className="px-6 py-10 text-gray-800 text-sm text-center">There are no new Facebook Pages to connect</p>
      )}
    </Card>
  );
}

export default PagesToSetup;
