/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Icon from 'common/icon';
import SingleFieldEdit from 'common/single-field-edit';

function BillingEmail(props) {
  const {
    editing,
    email,
    error,
    onCancel,
    onEdit,
    onEmailChanged,
    onSubmit,
    submitting,
  } = props;

  return (
    <>
      <div className="flex items-center mt-6">
        <p className="font-semibold text-sm text-gray-800">Billing email</p>
        <button
          className={`ml-4 flex items-center ${editing ? 'invisible' : ''}`}
          onClick={onEdit}
          type="button"
        >
          <Icon
            className="text-xs text-gray-700"
            icon="edit"
          />
        </button>
      </div>

      {!editing && <p className="text-sm text-gray-800">{email}</p>}
      {editing && (
        <SingleFieldEdit
          className="mt-2"
          error={error}
          onCancel={onCancel}
          onChange={onEmailChanged}
          onSubmit={onSubmit}
          submitting={submitting}
          value={email}
        />
      )}
    </>
  );
}

export default BillingEmail;
