/* ------ Module imports ------ */
import { useLayoutEffect, useRef } from 'react';

/**
 * This hook returns a ref which you can pass to a dom element,
 * and that dom element will have it's height set to be the window
 * height (including when the window is resized).
 */
function useWindowHeight() {
  const ref = useRef();

  useLayoutEffect(() => {
    function onResize() {
      if (ref && ref.current) {
        ref.current.style.height = `${window.innerHeight}px`;
      }
    }

    onResize();

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return ref;
}

export default useWindowHeight;
