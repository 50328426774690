/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Local components ------ */
import Picker from './picker';
import Preview from './preview';

function Colors(props) {
  const {
    backgroundColor,
    disabled,
    foregroundColor,
    greeting,
    name,
    onBackgroundColorChanged,
    onForegroundColorChanged,
  } = props;

  const [picker, setPicker] = useState(null);

  return (
    <div>
      <div className="mb-6">
        <Picker
          color={backgroundColor}
          disabled={disabled}
          label="Background color"
          onChange={onBackgroundColorChanged}
          onClose={() => setPicker(null)}
          onOpen={() => setPicker('background')}
          pickerVisible={picker === 'background'}
        />

        <div className="mt-2">
          <Picker
            color={foregroundColor}
            disabled={disabled}
            label="Foreground color"
            onChange={onForegroundColorChanged}
            onClose={() => setPicker(null)}
            onOpen={() => setPicker('foreground')}
            pickerVisible={picker === 'foreground'}
          />
        </div>
      </div>

      <div className="p-6 mt-4 rounded-lg border border-gray-300 bg-gray-200">
        <p className="inline-block text-sm text-gray-800 font-medium mb-1">Preview</p>
        <Preview
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
          greeting={greeting}
          name={name}
        />
      </div>
    </div>
  );
}

export default Colors;
