/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import PlainButton from 'common/plain-button';

function Cancel(props) {
  const {
    onCancel,
    onSubmit,
    submitting,
  } = props;

  return (
    <div className="p-6">
      <p className="text-sm mb-6 font-medium text-gray-800">
        If you cancel your subscription, once your current month ends you will no
        longer be able to use Ralli to manage your Facebook Messenger conversations.
      </p>

      <Button
        destructive
        loading={submitting}
        onClick={onSubmit}
      >
        Confirm cancellation
      </Button>
      <PlainButton
        className="ml-6"
        disabled={submitting}
        onClick={onCancel}
      >
        Go back
      </PlainButton>
    </div>
  );
}

export default Cancel;
