/* ------ Module imports ------ */
import React, { useContext, useState } from 'react';

/* ------ Context ------ */
import PlatformContext from 'components/platform/context';

/* ------ Helpers ------ */
import api from 'helpers/api';
import useDidMount from 'helpers/hooks/use-did-mount';
import userUtil from 'helpers/user';

/* ------ Common imports ------ */
import BillingError from 'common/billing-error';

/* ------ View import ------ */
import AddPage from './add-page.view';

function AddPageContainer() {
  const {
    billingError,
    user,
  } = useContext(PlatformContext);

  const [fbAccessToken, setFbAccessToken] = useState(null);
  const [fbPages, setFbPages] = useState(null);

  const [existingPages, setExistingPages] = useState(null);
  const [view, setView] = useState('loading');

  async function fetchExistingPages() {
    let loadedPages = null;
    try {
      const { data } = (await api.get('/page')).data;
      loadedPages = data;
    } catch (e) {
      // Silently ignore
    }

    if (loadedPages) {
      setExistingPages(loadedPages);
      setView('main');
    } else {
      setView('error');
    }
  }

  useDidMount(() => {
    fetchExistingPages();
  });

  function onPageConnected(page) {
    setFbPages(fbPages.map(pg => {
      const fbPage = pg;

      if (fbPage.id === page.fb_page_id) {
        fbPage.connected = true;
        fbPage.ralli_id = page.id;
      }

      return fbPage;
    }));

    if (user.business.onboarding_status !== 'finished') {
      try {
        userUtil.refreshUser();
      } catch (err) {
        // Ignore.
      }
    }
  }

  function onPagesLoaded(accessToken, pages) {
    /* ------ Filter out existing pages ------ */
    const existingPageMap = {};
    existingPages.forEach(pg => {
      existingPageMap[pg.fb_page_id] = true;
    });

    setFbAccessToken(accessToken);
    setFbPages(pages.filter(pg => !existingPageMap[pg.id]));
  }

  if (billingError) {
    return <BillingError error={billingError} />;
  }

  return (
    <AddPage
      existingPages={existingPages}
      fbAccessToken={fbAccessToken}
      fbPages={fbPages}
      onPageConnected={onPageConnected}
      onPagesLoaded={onPagesLoaded}
      view={view}
    />
  );
}

export default AddPageContainer;
