/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Hours from 'common/hours';

/* ------ Local components ------ */
import CategorySet from './category-set';
import DangerZone from './danger-zone';
import FacebookDetails from './facebook-details';

function Settings(props) {
  const {
    onCategorySetUpdated,
    onHoursUpdated,
    onReauthenticated,
    page,
  } = props;

  return (
    <div>
      <CategorySet
        pageId={page.id}
        categorySet={page.category_set}
        onCategorySetUpdated={onCategorySetUpdated}
      />

      <FacebookDetails
        onReauthenticated={onReauthenticated}
        page={page}
      />

      <Hours
        channel={page}
        onHoursUpdated={onHoursUpdated}
      />

      <DangerZone page={page} />
    </div>
  );
}

export default Settings;
