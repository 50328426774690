/* ------ Module imports ------ */
import React from 'react';
import TimePicker from 'react-time-picker';

/* ------ Common imports ------ */
import Checkbox from 'common/checkbox';

function Day(props) {
  const {
    day,
    error,
    end,
    open,
    start,
    onEndChanged,
    onStartChanged,
    onToggleOpen,
  } = props;

  return (
    <div className="flex items-center mb-3 last:mb-0">
      <div className="flex items-center w-32">
        <Checkbox
          checked={open}
          onSelect={onToggleOpen}
        />
        <span className="flex-1 text-sm text-gray-800 ml-3">{day}</span>
      </div>

      <div className={`flex items-center ${open ? '' : 'invisible'}`}>
        <TimePicker
          className="text-sm text-gray-800 w-24"
          disableClock
          clearIcon={null}
          format="h:mm a"
          onChange={onStartChanged}
          value={start}
        />

        <span className="text-sm text-gray-800 ml-3 mr-3">to</span>

        <TimePicker
          className="text-sm text-gray-800 w-24"
          disableClock
          clearIcon={null}
          format="h:mm a"
          onChange={onEndChanged}
          value={end}
        />

        {error && <div className="w-2 h-2 ml-2 mt-2 bg-red-600 rounded-full" />}
      </div>
    </div>
  );
}

export default Day;
