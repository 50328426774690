/* ------ Module import ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';

function Archive(props) {
  const {
    onCancel,
    onSubmit,
    submitting,
  } = props;

  return (
    <>
      <p className="mt-2 text-sm text-gray-800 mb-6">Are you sure you want to archive this ad?</p>
      <Button
        destructive
        loading={submitting}
        onClick={onSubmit}
      >
        Archive
      </Button>
      <button
        className="text-sm uppercase font-semibold text-gray-600 ml-6"
        disabled={submitting}
        onClick={onCancel}
        type="button"
      >
        Cancel
      </button>
    </>
  );
}

export default Archive;
