/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ View import ------ */
import CategorySetSelector from './category-set-selector.view';

function CategorySetSelectorContainer(props) {
  const {
    onCancel,
    onChange,
    onConfirm,
    onFocus,
    submitting,
    value,
  } = props;

  const [view, setView] = useState('loading');
  const [categorySets, setCategorySets] = useState(null);

  async function fetchCategorySets() {
    let loadedCategorySets = null;
    try {
      const { data } = (await api.get('/category_set?expand[]=in_use_by')).data;
      loadedCategorySets = data;
    } catch (e) {
      // Silently ignore.
    }

    if (loadedCategorySets) {
      setCategorySets(loadedCategorySets);
      setView('main');
    } else {
      setView('error');
    }
  }

  useDidMount(() => {
    fetchCategorySets();
  });

  return (
    <CategorySetSelector
      data={categorySets}
      error={view === 'error'}
      loading={view === 'loading'}
      onCancel={onCancel}
      onChange={onChange}
      onConfirm={onConfirm}
      onFocus={onFocus}
      submitting={submitting}
      value={value}
    />
  );
}

export default CategorySetSelectorContainer;
