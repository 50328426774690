/* ------ Module imports ------ */
import React, { useContext } from 'react';

/* ------ Context ------ */
import PlatformContext from 'components/platform/context';

/* ------ View import ------ */
import User from './user.view';

function UserContainer(props) {
  const {
    data,
    onRemove,
    removing,
  } = props;

  const { user } = useContext(PlatformContext);

  return (
    <User
      data={data}
      isAdmin={user.type === 'admin'}
      isMe={user.id === data.id}
      onRemove={onRemove}
      removing={removing}
    />
  );
}

export default UserContainer;
