/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ View import ------ */
import Ad from './ad.view';

function AdContainer(props) {
  const {
    ad,
    onArchived,
    onUpdated,
    page,
  } = props;

  const [expanded, setExpanded] = useState(false);

  return (
    <Ad
      ad={ad}
      expanded={expanded}
      onArchived={onArchived}
      onToggleExpand={() => setExpanded(!expanded)}
      onUpdated={onUpdated}
      page={page}
    />
  );
}

export default AdContainer;
