/* ------ Module imports ------ */
import React from 'react';
import { Link } from 'react-router-dom';

function PlainButton(props) {
  const {
    children,
    className,
    color,
    disabled,
    href,
    lowercase,
    onClick,
  } = props;

  let buttonClass = 'relative focus:outline-none p-0 font-semibold text-sm disabled:cursor-not-allowed';
  switch (color) {
    case 'gray':
      buttonClass += ' text-gray-600 focus:text-blue-600 hover:text-blue-600 disabled:text-gray-600';
      break;
    case 'red':
      buttonClass += ' text-red-500 focus:text-red-600 hover:text-red-600 disabled:text-red-500';
      break;
    case 'white':
      buttonClass += ' text-white focus:text-gray-200 hover:text-gray-200 disabled:text-gray-200';
      break;
    case 'orange':
      buttonClass += ' text-orange-600 focus:text-orange-700 hover:text-orange-700 disabled:text-orange-700';
      break;
    default:
      buttonClass += ' text-blue-500 focus:text-blue-600 hover:text-blue-600 disabled:text-blue-500';
  }

  if (!lowercase) {
    buttonClass += ' uppercase';
  }

  if (className) {
    buttonClass += ` ${className}`;
  }

  if (href) {
    return (
      <Link
        className={buttonClass}
        disabled={disabled}
        to={href}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      className={buttonClass}
      disabled={disabled}
      onClick={onClick}
      type="button"
    >
      {children}
    </button>
  );
}

export default PlainButton;
