/* ----- Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import Input from 'common/input';
import PlainButton from 'common/plain-button';

function EditCard(props) {
  const {
    errors,
    firstName,
    lastName,
    onClose,
    onFirstNameChanged,
    onLastNameChanged,
    onSubmit,
    saving,
  } = props;

  return (
    <div className="mt-10 bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="px-6 py-4 border-b border-gray-300">
        <p className="font-medium text-gray-800">Edit details</p>
      </div>
      <div className="p-6 pt-4">
        <Input
          className="mb-6"
          disabled={saving}
          error={errors.firstName}
          label="First name"
          onChange={onFirstNameChanged}
          value={firstName}
        />
        <Input
          className="mb-6"
          disabled={saving}
          error={errors.lastName}
          label="Last name"
          onChange={onLastNameChanged}
          value={lastName}
        />

        <div className="flex items-center">
          <Button
            loading={saving}
            onClick={onSubmit}
          >
            UPDATE
          </Button>

          <PlainButton
            className="ml-6"
            disabled={saving}
            color="gray"
            onClick={onClose}
          >
            CANCEL
          </PlainButton>
        </div>
      </div>
    </div>
  );
}

export default EditCard;
