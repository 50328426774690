/* ------ Module imports ------ */
import { useEffect, useRef } from 'react';

/* ------ Helpers ------ */
import socket from 'helpers/socket';

function useWatcher(ev, callback) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function handleEvent(...data) {
      savedCallback.current(...data);
    }

    const unsubscribe = socket.on(ev, handleEvent);

    return unsubscribe;
  }, [ev]);
}

export default useWatcher;
