/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Loading from 'common/loading';

/* ------ Local components ------ */
import ExistingPages from './existing-pages';
import FacebookLogin from './facebook-login';
import PagesToSetup from './pages-to-setup';

function AddPage(props) {
  const {
    existingPages,
    fbAccessToken,
    fbPages,
    onPageConnected,
    onPagesLoaded,
    view,
  } = props;

  if (view === 'loading') {
    return <Loading />;
  }

  if (view === 'error') {
    return null; // TODO
  }

  return (
    <div>
      <h1 className="font-heading text-2xl font-medium text-gray-800 mb-10">Setup a Facebook Page</h1>

      {!fbPages && (
        <FacebookLogin
          onPagesLoaded={onPagesLoaded}
        />
      )}
      {fbPages && (
        <PagesToSetup
          fbAccessToken={fbAccessToken}
          onPageConnected={onPageConnected}
          pages={fbPages}
        />
      )}
      <ExistingPages pages={existingPages} />
    </div>
  );
}

export default AddPage;
