/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';

function Delete(props) {
  const {
    onConfirmDelete,
    onStartDelete,
    submitting,
    view,
  } = props;

  function renderMainView() {
    return (
      <>
        <p className="text-sm text-gray-800 mt-4 mb-6">
          If you delete this page, Ralli will no longer handle messages received via Facebook
          Messenger for the page, and all current conversations will be deleted.
        </p>

        <Button
          destructive
          onClick={onStartDelete}
        >
          Delete this page
        </Button>
      </>
    );
  }

  function renderDeletingView() {
    return (
      <>
        <p className="text-sm text-gray-800 mt-4 mb-6">
          Are you sure you want to delete this page?
        </p>

        <Button
          destructive
          loading={submitting}
          onClick={onConfirmDelete}
        >
          Yes, delete this page
        </Button>
      </>
    );
  }

  return (
    <div className="border-t border-gray-300 p-6">
      <p className="uppercase text-sm font-semibold text-gray-800">Delete page</p>

      {view === 'main' && renderMainView()}
      {view === 'deleting' && renderDeletingView()}
    </div>
  );
}

export default Delete;
