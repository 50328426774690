/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import PlainButton from 'common/plain-button';
import Tab from 'common/tab';

/* ------ Local components ------ */
import List from './list';
import NewAd from './new-ad';

function AdList(props) {
  const {
    adding,
    onAdCreated,
    onAddAd,
    onCancelAdding,
    onStatusChanged,
    page,
    status,
  } = props;

  return (
    <div className="bg-white shadow-lg rounded-lg">
      <div className="px-6 py-4 bg-white border-b border-gray-300 flex justify-between rounded-t-lg">
        <p className="font-medium text-gray-800 text">Ads</p>
        {!adding && <PlainButton onClick={onAddAd}>+ Setup New Ad</PlainButton>}
      </div>

      {adding && (
        <NewAd
          onAdCreated={onAdCreated}
          onCancel={onCancelAdding}
          page={page}
        />
      )}

      {!adding && (
        <>
          <div className="flex px-6 border-b border-gray-300">
            <Tab
              onClick={() => onStatusChanged('active')}
              selected={status === 'active'}
            >
              Active
            </Tab>
            <Tab
              onClick={() => onStatusChanged('archived')}
              selected={status === 'archived'}
            >
              Archived
            </Tab>
          </div>

          <List
            page={page}
            status={status}
          />
        </>
      )}
    </div>
  );
}

export default AdList;
