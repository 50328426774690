/* ------ Module imports ------ */
import React from 'react';

function Icon(props) {
  const { icon } = props;

  return (
    <img
      alt=""
      className="w-5"
      src={`/assets/icons/${icon}.svg`}
    />
  );
}

export default Icon;
