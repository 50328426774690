/* ------ Module imports ------ */
import React from 'react';

function Attachment(props) {
  const {
    attachment,
    canRemove,
    onRemoveAttachment,
  } = props;

  return (
    <div className="flex p-4 pt-0">
      <div>
        <div className="w-1 h-full bg-gray-400 rounded-full" />
      </div>
      <div className="pl-2 relative min-w-0">
        {attachment.type === 'image' && (
          <img
            alt=""
            className="h-24"
            src={attachment.url}
          />
        )}
        {attachment.type !== 'image' && (
          <div className="p-2 rounded border border-gray-300 flex items-center">
            <img className="h-6 mr-2" src="/assets/icons/file.svg" alt="" />
            <p className="text-sm truncate text-gray-800">{attachment.filename}</p>
          </div>
        )}

        {canRemove && (
          <button
            className="absolute top-0 right-0 h-4 w-4 bg-white shadow rounded-full focus:outline-none border-0 -mr-2 -mt-2"
            onClick={onRemoveAttachment}
            type="button"
          >
            <i className="ralli-icon-cross block" style={{ fontSize: '6px' }} />
          </button>
        )}
      </div>
    </div>
  );
}

export default Attachment;
