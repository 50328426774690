/* ------ Module imports ------ */
import React from 'react';

function StatusChange(props) {
  const { data } = props;

  let text = null;
  if (data.type === 'conv_activated') {
    text = `${data.user.first_name} ${data.user.last_name} changed this conversation's status to be active`;
  } else if (data.type === 'conv_archived') {
    text = `${data.user.first_name} ${data.user.last_name} archived this conversation`;
  }

  if (!text) {
    return null;
  }

  return (
    <div className="py-2">
      <p className="text-xs text-center text-gray-600 font-medium">{text}</p>
    </div>
  );
}

export default StatusChange;
