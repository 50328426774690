/* ------ Module imports ------ */
import React, { useRef, useState } from 'react';

/* ------ Helpers ------ */
import useOnClickOutside from 'helpers/hooks/use-on-click-outside';

/* ------ Common imports ------ */
import Icon from 'common/icon';

/* ------ Local components ------ */
import Dropdown from './dropdown';
import MobileActionSheet from './mobile-action-sheet';

function Options() {
  const [expanded, setExpanded] = useState(false);

  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setExpanded(false));

  return (
    <div ref={dropdownRef}>
      <button
        className="ml-2 border border-gray-300 rounded-lg px-4 py-2 focus:outline-none"
        onClick={() => setExpanded(!expanded)}
        type="button"
      >
        <Icon className="text-gray-800" icon="more" />
      </button>

      {expanded && (
        <div className="relative">
          <Dropdown />
        </div>
      )}

      <MobileActionSheet
        onClose={() => setExpanded(false)}
        open={expanded}
      />
    </div>
  );
}

export default Options;
