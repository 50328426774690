/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ View imports ------ */
import AdList from './ad-list.view';

function AdListContainer(props) {
  const { page } = props;

  const [adding, setAdding] = useState(false);
  const [status, setStatus] = useState('active');

  return (
    <AdList
      adding={adding}
      onAdCreated={() => setAdding(false)}
      onAddAd={() => setAdding(true)}
      onCancelAdding={() => setAdding(false)}
      onStatusChanged={setStatus}
      page={page}
      status={status}
    />
  );
}

export default AdListContainer;
