/* ------ Module imports ------ */
import React, { useRef } from 'react';

/* ------ Helpers ------ */
import useOnClickOutside from 'helpers/hooks/use-on-click-outside';

function Popover(props) {
  const {
    onClose,
    onChangeView,
  } = props;

  const ref = useRef();
  useOnClickOutside(ref, onClose);

  return (
    <div
      className="z-50 w-48 mt-3 -mr-3 absolute top-0 right-0 popover popover-right bg-white shadow-xl border border-gray-300 rounded-lg"
      ref={ref}
    >
      <div className="py-1">
        <button
          className="text-left w-full px-4 py-1 block hover:bg-gray-200 text-gray-800 text-sm font-semibold"
          onClick={() => onChangeView('edit')}
          type="button"
        >
          Edit
        </button>
        <button
          className="text-left w-full px-4 py-1 block hover:bg-gray-200 text-red-500 text-sm font-semibold"
          onClick={() => onChangeView('delete')}
          type="button"
        >
          Delete opening hours
        </button>
      </div>
    </div>
  );
}

export default Popover;
