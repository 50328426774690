/* ------ Module imports ------ */
import React from 'react';
import moment from 'moment';

/* ------ Common imports ------ */
import PlainButton from 'common/plain-button';

function PendingCancellation(props) {
  const {
    onResume,
    periodEnd,
    resuming,
  } = props;

  return (
    <div className="p-6">
      <p className="mb-6 text-orange-500 font-semibold">
        {`Your subscription has been cancelled. You will still be able to use Ralli until your subscription expires on ${moment(periodEnd).format('DD MMMM')}.`}
      </p>
      <p className="mb-2 text-sm text-gray-800">
        If you don&apos;t want to cancel your subscription, and instead want to keep using Ralli,
        click the button below to resume your subscripion.
      </p>

      <PlainButton
        disabled={resuming}
        onClick={onResume}
      >
        Resume my subscription
      </PlainButton>
    </div>
  );
}

export default PendingCancellation;
