/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import PlainButton from 'common/plain-button';
import Toggle from 'common/toggle';

/* ------ Local components ------ */
import ChangeEmailCard from './change-email-card';
import ChangePasswordCard from './change-password-card';
import EditCard from './edit-card';

function Profile(props) {
  const {
    card,
    emailSent,
    onEmailSent,
    onNotificationsToggled,
    onSetCard,
    onUserUpdated,
    user,
  } = props;

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex items-center mb-2">
          <button
            type="button"
          >
            <img className="rounded-lg h-12" src={user.profile_picture} alt="" />
          </button>
          <div className="ml-2">
            <p>{`${user.first_name} ${user.last_name}`}</p>
            <p className="text-xs text-gray-700">{user.business.name}</p>
          </div>
        </div>
        <PlainButton onClick={() => onSetCard('edit')}>Edit</PlainButton>
      </div>

      {card === 'edit' && (
        <EditCard
          onClose={() => onSetCard(null)}
          onUserUpdated={onUserUpdated}
          user={user}
        />
      )}

      <div className="mt-10 bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="flex p-4 align-center border-b">
          <p className="text-sm font-semibold w-40 text-gray-800">Enable notifications</p>
          <Toggle
            checked={user.push_notifications_enabled}
            onToggle={onNotificationsToggled}
            toggleId="toggle"
          />
        </div>

        <div className="flex p-4 border-b">
          <p className="text-sm font-semibold w-40 text-gray-800">Email</p>
          {emailSent && (
            <p className="text-sm">
              A verification email has been sent to
              <span className="font-medium">{` ${emailSent}`}</span>
            </p>
          )}
          {!emailSent && (
            <div>
              <p className="text-sm text-gray-800">{user.email}</p>
              <PlainButton onClick={() => onSetCard('email')}>CHANGE</PlainButton>
            </div>
          )}
        </div>

        <div className="flex p-4">
          <p className="text-sm font-semibold w-40 text-gray-800">Password</p>
          <PlainButton onClick={() => onSetCard('password')}>CHANGE</PlainButton>
        </div>
      </div>

      {card === 'email' && (
        <ChangeEmailCard
          onClose={() => onSetCard(null)}
          onEmailSent={onEmailSent}
          user={user}
        />
      )}

      {card === 'password' && (
        <ChangePasswordCard
          onClose={() => onSetCard(null)}
          onUpdateUser={onUserUpdated}
          user={user}
        />
      )}
    </>
  );
}

export default Profile;
