/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import BillingEmail from './billing-email.view';

function BillingEmailContainer(props) {
  const {
    email,
    onBillingEmailChanged,
  } = props;

  const [billingEmail, setBillingEmail] = useState(email);
  const [editing, setEditing] = useState(false);

  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  function onCancel() {
    setEditing(false);
    setError(false);
    setBillingEmail(email);
  }

  async function onSubmit() {
    if (!billingEmail || !billingEmail.trim()) {
      setError(true);
      return;
    }

    setError(false);
    setSubmitting(true);

    let success = false;
    try {
      await api.patch('/subscription', { billing_email: billingEmail.trim() });
      success = true;
    } catch (e) {
      // Silently ignore.
    }

    if (success) {
      onBillingEmailChanged(billingEmail.trim());
      setEditing(false);
    } else {
      toast('Could not update billing email. Something went wrong.');
    }

    setSubmitting(false);
  }

  return (
    <BillingEmail
      editing={editing}
      email={billingEmail}
      error={error}
      onCancel={onCancel}
      onEdit={() => setEditing(true)}
      onEmailChanged={setBillingEmail}
      onSubmit={onSubmit}
      submitting={submitting}
    />
  );
}

export default BillingEmailContainer;
