/* ------ Module imports ------ */
import React from 'react';
import { useHistory } from 'react-router-dom';

/* ------ Common imports ------ */
import Button from 'common/button';
import Icon from 'common/icon';

function BillingError(props) {
  const { error } = props;

  const history = useHistory();

  let title = 'Your subscription has expired';
  if (error === 'trial_expired') {
    title = 'Your free trial has expired';
  }

  return (
    <div className="p-10 mt-10 bg-white shadow-lg rounded-lg">
      <div className="bg-orange-300 text-orange-500 flex justify-center items-center rounded-full w-10 h-10">
        <Icon icon="warning" />
      </div>
      <p className="mt-6 font-medium text-gray-800 mb-2 text-xl">{title}</p>
      <p className="text-gray-800 text-sm mb-10">To continue using all the great features of Ralli, please set up a subscription.</p>

      <Button onClick={() => history.push('/subscribe')}>Set up subscription</Button>
    </div>
  );
}

export default BillingError;
