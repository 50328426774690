/* ------ Module imports ------- */
import React from 'react';

/* ------ Common imports ------ */
import Loading from 'common/loading';
import PlainButton from 'common/plain-button';

/* ------ Local components ------ */
import AddUser from './add-user';
import HelpSection from './help-section';
import User from './user';

function ConversationManagers(props) {
  const {
    adding,
    managers,
    onManagerAdded,
    onManagerDeleted,
    onToggleAdding,
    view,
  } = props;

  if (view === 'loading') {
    return <Loading />;
  }

  function renderUser(manager) {
    return (
      <User
        key={manager.id}
        onManagerDeleted={onManagerDeleted}
        user={manager}
      />
    );
  }

  return (
    <div className="mt-10 bg-white shadow-lg rounded-lg">
      <div className="flex items-center justify-between px-6 py-4 bg-white border-b border-gray-300 rounded-t-lg">
        <p className="font-medium text-gray-800 text">Conversation managers</p>
      </div>

      {managers.length === 0 && (
        <div className="p-6">
          <p className="text-gray-600 text-sm text-center">You haven&apos;t set up any conversation managers</p>
          {!adding && (
            <div className="text-center">
              <PlainButton onClick={onToggleAdding}>Add a conversation manager</PlainButton>
            </div>
          )}
          {adding && (
            <AddUser
              noButton
              onCancel={onToggleAdding}
              onManagerAdded={onManagerAdded}
            />
          )}
        </div>
      )}

      {managers.length > 0 && (
        <div className="px-6 pb-4">
          {managers.map(renderUser)}
          <AddUser onManagerAdded={onManagerAdded} />
        </div>
      )}

      <HelpSection />
    </div>
  );
}

export default ConversationManagers;
